export const scrollIntoView = (
  scrollEl?: Element | null,
  itemEl?: Element | null,
  spacing?: { top: number; bottom: number }
): void => {
  if (!scrollEl || !itemEl) {
    return
  }
  const listRect = scrollEl?.getBoundingClientRect()
  const itemRect = itemEl?.getBoundingClientRect()

  const scrollTop = scrollEl.scrollTop
  let newScrollTop = scrollEl.scrollTop

  const isItemCutFromTop = itemRect.top - (spacing?.top || 0) < listRect.top
  if (isItemCutFromTop) {
    newScrollTop = scrollTop - (listRect.top + (spacing?.top || 0) - itemRect.top)
  }

  const isItemCutFromBottom = itemRect.bottom + (spacing?.bottom || 0) > listRect.bottom
  if (isItemCutFromBottom) {
    newScrollTop = scrollTop + (itemRect.bottom + (spacing?.bottom || 0) - listRect.bottom)
  }
  if (newScrollTop && newScrollTop !== scrollEl.scrollTop) {
    scrollEl.scrollTo({
      top: newScrollTop,
    })
  }
}
