import { RootState } from 'root-state'
import { getReminderById, getRemindersByMessageListId } from 'selectors/reminders'
import { getUserById } from 'selectors/users'
import { Message } from 'services/messages'
import type { MessageListState } from 'slices/message-lists'
import { Reminder } from 'services/reminders'
import datetime from 'utils/datetime'

export type ChatItem = Message | Reminder
export const getItemsByListId = (state: RootState, messageListId: string): ChatItem[] => {
  const messageItems = (state.messageLists[messageListId]?.messages || []).filter(m => !m.isDiscussion)
  const reminderItems = getRemindersByMessageListId(state, messageListId)
  return [...messageItems, ...reminderItems].sort(sortItemsByDate)
}
export const chatItemIsMessage = (item?: ChatItem): item is Message => {
  return !!item && 'sentAt' in item
}
const sortItemsByDate = (a: ChatItem, b: ChatItem): number => {
  const aDate = chatItemIsMessage(a) ? a.sentAt : a.remindAt
  const bDate = chatItemIsMessage(b) ? b.sentAt : b.remindAt
  if (datetime.isBefore(aDate, bDate)) {
    return -1
  }
  if (datetime.isAfter(aDate, bDate)) {
    return 1
  }
  return 0
}

export const getMessageListById = (state: RootState, messageListId: string): MessageListState | undefined =>
  state.messageLists[messageListId]

export const getTotalMessagesByListId = (state: RootState, messageListId: string): number => {
  return (
    (state.messageLists[messageListId]?.totalMessages || state.messageLists[messageListId]?.messages.length) - 1 || 0
  )
}

export const getNextItem = (state: RootState, messageListId: string, jumpBy?: number) => {
  const items = getItemsByListId(state, messageListId)
  const focusedItem = getFocusedItem(state, messageListId)
  const currentIndex = items.findIndex(i => i.id === focusedItem?.id)
  const nextIndex = currentIndex + (jumpBy === undefined ? 1 : jumpBy)

  if (currentIndex === -1) {
    return items[items.length - 1]
  }

  if (nextIndex >= items.length || nextIndex < 0) {
    return
  }

  return items[nextIndex]
}

export const getLastItem = (state: RootState, messageListId: string): ChatItem | undefined => {
  const items = getItemsByListId(state, messageListId)
  return items[items.length - 1]
}

export const isItemEditable = (state: RootState, item: ChatItem): boolean => {
  return chatItemIsMessage(item) && item.userId === state.session.id
}

const QUOTE_BODY_LIMIT = 50
export const getQuoteForItem = (state: RootState, item: ChatItem): { userId: string; message: string } | undefined => {
  if (!chatItemIsMessage(item)) {
    return
  }
  const message = item
  if (!message.threadId) {
    return
  }

  const firstMessageInThread =
    state.messageLists[message.threadId]?.messages[0] ||
    state.messageLists[message.channelId]?.messages.find(m => m.id === message.threadId)
  if (!firstMessageInThread) {
    return
  }

  const user = getUserById(state, firstMessageInThread.userId)
  if (!user) {
    return
  }

  const body = []
  const parts = firstMessageInThread.body.split(/[.!:()\s]+/)

  for (const part of parts) {
    if (body.length < QUOTE_BODY_LIMIT) {
      body.push(part)
    }
  }

  return { userId: firstMessageInThread.userId, message: body.join(' ') }
}

export const getFocusedItem = (state: RootState, messageListId: string): ChatItem | undefined => {
  const messageListState = state.messageLists[messageListId || '']
  if (!messageListState) {
    return
  }
  const focusedId = messageListState.focus.itemId
  if (!focusedId) {
    return
  }
  const messages: Message[] = messageListState?.messages || []
  const message = messages.find(i => i.id === focusedId)
  if (message) {
    return message
  }
  const reminder = getReminderById(state, focusedId)
  if (reminder) {
    return reminder
  }
}
