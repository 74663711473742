import { AppDispatch, RootState } from 'root-state'
import { getOrganizationId } from 'selectors/session'
import * as discussionsApi from 'services/discussions'
import * as messageListsApi from 'services/message-lists'
import * as messagesApi from 'services/messages'
import { Message } from 'services/messages'
import { appendThreadsByChannel, setThreadMessageList, setTotalThreadCountByChannel } from 'slices/discussion-list'
import { setDiscussionMessages } from 'slices/discussion-messages'
import {
  appendMessagesToList,
  initializeMessageLists,
  LoadingStatus,
  prependMessagesToList,
  removeMessagesFromList,
  setLoadingStatus,
  setTotalMessages,
} from 'slices/message-lists'
import { MessageListType } from 'services/message-lists'
import { syncUserProfiles } from 'slices/users'
import { Unsubscribe } from 'services/firebase'
import { Profile } from 'services/users'

const subscriptions: Record<string, Unsubscribe> = {}
export const subscribeToChanges = ({
  messageListId,
  type,
  newestMessageId,
}: {
  messageListId: string
  type: MessageListType
  newestMessageId: string
}) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const orgId = getOrganizationId(getState())
    const unsubscribe = await messageListsApi.listenForUpdates(
      { orgId, messageListId, type, newestMessageId },
      (error: Error | null, updates) => {
        const { added, updated, removed } = updates
        const changes = [...added, ...updated]

        dispatch(appendMessagesToList({ messageListId, messages: added }))
        dispatch(removeMessagesFromList({ messageListId, messages: removed }))
        dispatch(syncUserProfiles(changes.map(m => m.userId)))
        dispatch(
          setLoadingStatus({
            messageListId,
            status: LoadingStatus.Succeeded,
            type,
          })
        )
        if (added.length > 0) {
          dispatch(notifyUser({ message: added[added.length - 1] }))
        }
      }
    )
    subscriptions[messageListId]?.()
    subscriptions[messageListId] = unsubscribe
  }
}

const notifyUser = ({ message }: { message: Message }) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const user = state.users[message.userId]
    const me = state.session.id
    if (user.id === me) {
      return
    }
    if (window.Notification && Notification.permission !== 'denied') {
      Notification.requestPermission(() => {
        // new Notification(user.name || message.body, {
        //   body: message.body,
        //   icon: user.picture,
        // })
      })
    }
  }
}

export const loadMessagesForList =
  ({ messageListId, type }: { messageListId: string; type: MessageListType }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState() as RootState
    if (state.messageLists[messageListId]?.loading === LoadingStatus.Pending) {
      return
    }
    if (state.messageLists[messageListId]?.loading === LoadingStatus.Idle) {
      dispatch(setLoadingStatus({ messageListId, status: LoadingStatus.Pending, type }))
    }
    try {
      const oldestMessageId = state.messageLists[messageListId]?.messages[0]?.id
      const orgId = getOrganizationId(state)
      const { messages, totalMessages } = await messagesApi.fetchMessagesForList({
        orgId,
        messageListId,
        type,
        after: oldestMessageId,
        limit: 10,
      })
      dispatch(prependMessagesToList({ messageListId, messages, type }))
      if ((totalMessages || 0) > 11) {
        dispatch(setTotalMessages({ messageListId, totalMessages }))
      }
      dispatch(syncUserProfiles(messages.map(m => m.userId)))
      dispatch(
        setLoadingStatus({
          messageListId,
          status: LoadingStatus.Succeeded,
          type,
        })
      )
      if (type === 'channel') {
        messages.forEach(m => {
          if (m.threadId) {
            dispatch(loadMessagesForList({ messageListId: m.threadId, type: MessageListType.Thread }))
          }
        })
      }
    } catch (err) {
      dispatch(setLoadingStatus({ messageListId, status: LoadingStatus.Failed, type }))
      console.error(err)
      return
    }
  }

export const loadChannels = (profile: Profile) => async (dispatch: AppDispatch) => {
  const workspaceId = profile.workspaceId
  try {
    const channels = await messageListsApi.getAllChannels({ workspaceId })
    dispatch(initializeMessageLists({ channels }))
  } catch (err) {
    // todo: handle this error
    console.error(err)
  }
}

export const loadInitialData = (channelId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState()
  const orgId = getOrganizationId(state)
  const discussions = await discussionsApi.fetchDiscussions({ orgId, channelId })

  const threadMessages: Record<string, string[]> = {}
  const discussionIds: string[] = []
  const userIds: string[] = []
  discussions.forEach(d => {
    if (d.channelId === channelId && discussionIds.indexOf(d.id) < 0) {
      discussionIds.push(d.id)
    }
    if (userIds.indexOf(d.userId) < 0) {
      userIds.push(d.userId)
    }
    if (d.threadId) {
      threadMessages[d.threadId] = threadMessages[d.threadId] || []
      threadMessages[d.threadId].push(d.id)
    }
  })

  dispatch(appendThreadsByChannel({ channelId, threadIds: discussionIds }))
  dispatch(setDiscussionMessages(discussions))
  dispatch(setThreadMessageList(threadMessages))
  dispatch(syncUserProfiles(userIds))
  dispatch(
    setTotalThreadCountByChannel({
      channelId,
      total: discussionIds.length,
    })
  )
}
