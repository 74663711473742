import { AppDispatch, RootState } from 'root-state'
import { getOrganizationId } from 'selectors/session'
import * as messagesApi from 'services/messages'
import { Message } from 'services/messages'
import { MessageListType } from 'services/message-lists'
import { Draft, removeMessagesFromList, setFocusOnItem, updateMessage } from 'slices/message-lists'
import { createReminder } from 'actions/reminders'
import datetime from 'utils/datetime'
import { loadMessagesForList } from './message-lists'
import { measure } from 'services/metrics'

export const sendMessage =
  ({
    draft,
    threadId,
    messageListId,
    type,
  }: {
    draft: Draft
    messageListId: string
    type: MessageListType
    threadId?: string
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState() as RootState
    if (!state.session.id) {
      return
    }
    const newMessage: Pick<Message, 'channelId' | 'userId' | 'body' | 'sentAt' | 'threadId'> = {
      channelId: messageListId,
      userId: state.session.id,
      body: draft.body,
      sentAt: datetime.now(),
    }
    if (threadId) {
      newMessage.threadId = threadId
    }

    const orgId = getOrganizationId(state)
    measure(orgId, 'ui.chat.message_send')
    const messageId = await messagesApi.sendMessage({ orgId, newMessage })

    if (draft.remindAt) {
      dispatch(
        createReminder({
          messageId,
          messageListId,
          remindAt: draft.remindAt,
          type,
        })
      )
    }
  }

export const updateMessageBody =
  ({ message, messageListId, body }: { message: Message; messageListId: string; body: string }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const orgId = getOrganizationId(state)
    const updates: Message = {
      ...message,
      body: body,
      edited: true,
      updatedAt: datetime.now(),
    }
    const { sentAt, ...updated } = updates
    await messagesApi.updateMessage({ orgId, message: updated })
    dispatch(updateMessage({ messageListId, messageId: message.id, updates }))
  }

export const jumpToMessage =
  ({ messageListId, messageId, type }: { messageListId: string; messageId: string; type: MessageListType }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (!messageId) {
      return
    }
    const state = getState()
    if (!state.messageLists[messageListId]) {
      await dispatch(loadMessagesForList({ type, messageListId }))
    }
    if (!state.messageLists[messageListId]) {
      return
    }
    dispatch(setFocusOnItem({ itemId: messageId, messageListId, editing: false }))
  }

export const deleteMessage =
  ({ messageListId, message }: { messageListId: string; message: Message }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(removeMessagesFromList({ messageListId, messages: [message] }))
    const state = getState()
    const orgId = getOrganizationId(state)
    await messagesApi.deleteMessage({ orgId, message })
  }
