import * as React from 'react'

type Props = React.SVGProps<SVGSVGElement>
export const InboxIcon: React.FC<Props> = (props: Props) => (
  <svg width='1em' height='1em' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g stroke='inherit' strokeWidth={0.705} strokeLinecap='round' strokeLinejoin='round'>
      <path d='M10.082 6h-2.75l-.917 1.374H4.582L3.665 6H.915' />
      <path d='M2.497 2.843.915 6.001v2.75a.917.917 0 0 0 .917.917h7.333a.917.917 0 0 0 .917-.917v-2.75L8.5 2.843a.917.917 0 0 0-.82-.509H3.316a.917.917 0 0 0-.82.51v0Z' />
    </g>
  </svg>
)
