import React, { useState } from 'react'
import { styled } from 'themes'

const normalizationMap: Record<string, string> = {
  cmd: '⌘',
}

const Wrapper = styled('div', {
  position: 'relative',
  cursor: 'default',
  display: 'flex',
  justifyContent: 'center',
})

const Content = styled('div', {
  display: 'flex',
  position: 'absolute',
  background: '$invertedBackground',
  color: '$invertedText',
  boxShadow: '$light',
  borderRadius: '$m',
  fontFamily: '$sans',
  fontSize: '$small',
  lineHeight: '$base',
  fontWeight: '$semibold',
  padding: '$space2 $space2',
  transform: 'translate(0, -100%)',
})

const Label = styled('div', {
  padding: '$space1',
})

const Shortcut = styled('div', {
  display: 'flex',
  gap: '3px',
  marginLeft: '$space4',
})

const Key = styled('div', {
  background: '$invertedAccentBackground',
  coor: '$invertedAccentText',
  borderRadius: '$s',
  padding: '$space1',
})

type Props = {
  text: string
  shortcut?: string[]
  visible?: boolean
}

export const Hint: React.FC<Props> = props => {
  const [visible, setVisible] = useState(false)
  const onMouseEnter = React.useCallback(() => setVisible(true), [])
  const onMouseLeave = React.useCallback(() => setVisible(false), [])

  return (
    <Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {visible || props.visible ? (
        <Content data-hint>
          <Label>{props.text}</Label>
          <Shortcut>
            {props.shortcut &&
              props.shortcut.map(k => (
                <Key key={k}>{normalizationMap[k.toLowerCase()] || (k.length === 1 ? k.toUpperCase() : k)}</Key>
              ))}
          </Shortcut>
        </Content>
      ) : null}
      {props.children}
    </Wrapper>
  )
}
