import datetime, { DateTime } from 'utils/datetime'
import * as firebase from 'services/firebase'

export type Message = {
  body: string
  channelId: string
  id: string
  sentAt: DateTime
  userId: string

  edited?: boolean
  isDiscussion?: boolean
  threadId?: string
  unread?: boolean
  updatedAt?: DateTime
}
export type DiscussionMessage = {
  id: string
  channelId?: string
  userId: string
  subject?: string
  body: string
  threadId?: string
  sentAt: DateTime
  updatedAt?: DateTime
}

export const fetchMessagesForList = async ({
  orgId,
  messageListId,
  type,
  after,
  before,
  limit,
}: {
  orgId: string
  messageListId: string
  type: string
  after?: string
  before?: string
  limit?: number
}) => {
  let key: string
  if (type === 'channel') {
    key = 'channelId'
  } else {
    key = 'threadId'
  }
  const opts: firebase.QueryOptions = {
    path: `orgs/${orgId}/chatMessages`,
    where: [{ field: key, op: '==', value: messageListId }],
  }

  // todo: (PB): temporary - only support pagination on channels.
  // need to add indexes on firebase.firestore threads and discussions
  if (type === 'channel') {
    opts.orderBy = { field: 'sentAt', desc: true }
    opts.limit = limit
  }

  if (after) {
    opts.startAfter = await firebase.getSnapshot(`orgs/${orgId}/chatMessages/${after}`)
  }
  if (before) {
    opts.endAt = await firebase.getSnapshot(`orgs/${orgId}/chatMessages/${before}`)
  }

  const self = await firebase.fetch({
    path: `orgs/${orgId}/chatMessages`,
    where: [{ field: firebase.documentId(), op: '==', value: messageListId }],
  })
  const results = await firebase.fetch(opts)
  const messages = results.docs.map(firebaseDocumentToMessage)
  if (self.docs[0]) {
    messages.unshift(firebaseDocumentToMessage(self.docs[0]))
  }

  return {
    messages,
    totalMessages: opts.limit ? undefined : results.size,
  }
}

export const fetchSpecificMessages = async ({ orgId, messageIds }: { orgId: string; messageIds: string[] }) => {
  const results = await firebase.fetch({
    path: `orgs/${orgId}/chatMessages`,
    where: [{ field: firebase.documentId(), op: 'in', value: messageIds }],
  })
  return results.docs.map(firebaseDocumentToMessage)
}

export type NewMessage = Pick<Message, 'channelId' | 'userId' | 'body' | 'sentAt' | 'threadId'>
export const sendMessage = async ({ orgId, newMessage }: { orgId: string; newMessage: NewMessage }) => {
  const result = await firebase.add(`orgs/${orgId}/chatMessages`, {
    ...newMessage,
    sentAt: datetime.toFirebaseTime(newMessage.sentAt),
  })
  return result.id
}

export const updateMessage = async ({ orgId, message }: { orgId: string; message: Omit<Message, 'sentAt'> }) => {
  await firebase.set(`orgs/${orgId}/chatMessages/${message.id}`, {
    ...message,
    edited: true,
    updatedAt: datetime.toFirebaseTime(datetime.now()),
  })
}

export const deleteMessage = async ({ orgId, message }: { orgId: string; message: Message }) => {
  await firebase.remove(`orgs/${orgId}/chatMessages/${message.id}`)
}

export const firebaseDocumentToMessage = (row: firebase.QueryDocumentSnapshot<firebase.DocumentData>): Message => {
  return {
    id: row.id,
    ...row.data(),
    sentAt: datetime.fromFirebaseTime(row.data().sentAt),
    updatedAt: row.data().updatedAt ? datetime.fromFirebaseTime(row.data().updatedAt) : undefined,
  } as Message
}
