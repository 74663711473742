import { MessageInner, MessageProfilePhoto, MessageWrapper } from 'components/Message/atoms'
import { MessageButtons } from 'components/MessageButtons'
import { MessageContent } from 'components/MessageContent'
import { MessageFooter } from 'components/MessageFooter'
import { MessageHeader } from 'components/MessageHeader'
import { MessagePlaceholder } from 'components/MessagePlaceholder'
import { useMessageListContext } from 'contexts/MessageList'
import { useMessageContext } from 'contexts/Message'
import React from 'react'

export const Message: React.FC = () => {
  const { type } = useMessageListContext()
  const { message, user, reminder, isSelected, focusMessage, isEditing, isThreadedReply } = useMessageContext()
  const onClick = React.useCallback(() => {
    focusMessage()
  }, [focusMessage])

  if (!message) {
    return <MessagePlaceholder />
  }

  const isReply = isThreadedReply && !(type === 'thread')
  let innerBorder: 'reply' | 'reminder' | 'active' | undefined
  if (isSelected && isReply) {
    innerBorder = 'active'
  } else if (isReply) {
    innerBorder = 'reply'
  } else if (reminder?.remindAt && !isSelected) {
    innerBorder = 'reminder'
  }

  return (
    <MessageWrapper
      data-id={message.id}
      onClick={onClick}
      active={isSelected}
      border={!isEditing && !isReply && isSelected}
    >
      {isSelected && <MessageButtons />}
      <MessageHeader />
      <MessageInner border={innerBorder}>
        <MessageProfilePhoto src={user?.picture || 'placeholder'} referrerPolicy='no-referrer' />
        <div>
          <MessageContent />
          <MessageFooter />
        </div>
      </MessageInner>
    </MessageWrapper>
  )
}
