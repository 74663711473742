import { ChatCompose } from 'components/ChatCompose'
import { Icon } from 'components/Icons'
import { MessageComposeHint } from 'components/MessageComposeHint'
import { useCommandPalette } from 'contexts/CommandPalette'
import { useMessageListContext } from 'contexts/MessageList'
import { useDispatch } from 'hooks/use-dispatch'
import { useFocus } from 'hooks/use-focus'
import { useSelector } from 'hooks/use-selector'
import React, { useEffect, useRef } from 'react'
import { Options as HotKeyOptions, useHotkeys } from 'react-hotkeys-hook'
import { getCurrentFocusRegion, isFocusOnMessageComposeField } from 'selectors/focus'
import { shouldSuggestCreatingReminder } from 'selectors/reminders'
import { PageFocusRegion, setFocusRegion, setFocusRegionToThread } from 'slices/focus'
import { cleanDraft, updateDraft as updateDraftAction } from 'slices/message-lists'
import { sendMessage } from 'actions/messages'
import { styled } from 'themes'

const Wrapper = styled('div', {
  padding: '$space2 $space5 $space4 $space5',
  background: '$primaryBackground',
  variants: {
    threadView: {
      true: {
        position: 'sticky',
        bottom: 0,
      },
    },
    separator: {
      true: {
        boxShadow: '$light',
        paddingTop: '$space1',
      },
    },
  },
})
const Inner = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$space3',
})

const CmdKButton = styled('button', {
  display: 'none',
  borderRadius: '$m',
  aspectRatio: 1,
  width: '$space12',
  borderColor: '$borderColor',
  borderWidth: '$thin',
  borderStyle: 'solid',
  background: '$primaryBackground',
  boxShadow: '$light',
  '&:focus, &:active': {
    borderColor: '$focusOrActive',
  },
  '@narrow': {
    display: 'block',
  },
})

type Props = { placeholder: string }
export const MessageComposeField: React.FC<Props> = ({ placeholder }) => {
  const {
    type,
    messages,
    id: messageListId,
    draft,
    isFocused,
    focusLastMessage,
    focusMessageComposeField,
  } = useMessageListContext()
  const focused = useSelector(s => isFocused && isFocusOnMessageComposeField(s, messageListId))
  const dispatch = useDispatch()

  const [inputRef, setTextareaFocused] = useFocus<HTMLTextAreaElement>()
  const { openCommandPalette } = useCommandPalette()

  const focusShouldBeRestored = useRef(false)
  const focusTimer = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    if (focused && inputRef) {
      setTextareaFocused()
    } else {
      setTextareaFocused(false)
    }

    focusShouldBeRestored.current = focused
    if (!focused && focusTimer.current !== null) {
      clearTimeout(focusTimer.current)
      focusTimer.current = null
    }
  }, [setTextareaFocused, inputRef, focused])

  const hotKeyOpts: HotKeyOptions = {
    enableOnTags: ['TEXTAREA'],
    keydown: true,
    enabled: focused,
  }
  const onPressEnter = React.useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault()

      if (draft.body.trim().length === 0) return

      if (type === 'channel') {
        dispatch(sendMessage({ draft, messageListId, type }))
      } else {
        const channelId = messages[0].channelId
        dispatch(sendMessage({ draft, messageListId: channelId, type, threadId: messageListId }))
      }
      dispatch(cleanDraft({ messageListId }))
    },
    [dispatch, draft, messageListId, messages, type]
  )
  useHotkeys('enter', onPressEnter, hotKeyOpts)

  const onPressEsc = React.useCallback((e: KeyboardEvent) => {
    e.preventDefault()
    // todo: (PB): redo this
    // removeDraftReminder()
    // if (draft.remindAt) {
    //   if (threadId) {
    //     dispatch(cleanupThreadDraftReminder({ threadId }))
    //   } else {
    //     dispatch(cleanupDraftReminder({ channelId }))
    //   }
    // }
  }, [])
  useHotkeys('esc', onPressEsc, hotKeyOpts)

  const onPressUp = React.useCallback(() => {
    if (inputRef.current?.selectionStart === 0) {
      focusLastMessage()
    }
  }, [focusLastMessage, inputRef])
  useHotkeys('up', onPressUp, hotKeyOpts)

  const currentFocusRegion = useSelector(getCurrentFocusRegion)
  const isMainPaneFocused = currentFocusRegion === PageFocusRegion.Channel
  const onRight = React.useCallback(() => {
    if (inputRef.current?.selectionStart === inputRef.current?.textContent?.length && isMainPaneFocused) {
      dispatch(setFocusRegionToThread())
    }
  }, [dispatch, isMainPaneFocused, inputRef])
  useHotkeys('right', onRight, hotKeyOpts)

  const onLeft = React.useCallback(() => {
    if (!isMainPaneFocused && inputRef.current?.selectionStart === 0) {
      dispatch(setFocusRegion(PageFocusRegion.Channel))
    }
  }, [dispatch, isMainPaneFocused, inputRef])
  useHotkeys('left', onLeft, hotKeyOpts)

  const onFocus = React.useCallback(() => {
    if (!focused) {
      focusShouldBeRestored.current = true
      focusMessageComposeField()
    }
  }, [focusMessageComposeField, focused])

  const onBlur = React.useCallback(() => {
    if (!focusShouldBeRestored.current) {
      return
    }

    focusTimer.current = setTimeout(() => {
      setTextareaFocused()
    }, 500)
  }, [setTextareaFocused])

  useHotkeys('cmd+up', focusLastMessage, hotKeyOpts)

  const updateDraft = React.useCallback(
    (body: string) => {
      dispatch(updateDraftAction({ messageListId, body }))
    },
    [dispatch, messageListId]
  )

  const showComposeHint = !!draft.remindAt || shouldSuggestCreatingReminder(draft)
  const onClick = React.useCallback(() => {
    openCommandPalette()
  }, [openCommandPalette])

  return (
    <Wrapper threadView={type === 'thread'} separator={showComposeHint}>
      {showComposeHint ? <MessageComposeHint draft={draft} /> : null}
      <Inner>
        <ChatCompose
          focused={focused}
          body={draft.body}
          placeholder={placeholder}
          inputRef={inputRef}
          onInput={updateDraft}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        <CmdKButton onClick={onClick}>
          <Icon name='command' />
        </CmdKButton>
      </Inner>
    </Wrapper>
  )
}
