import { LogInButton } from 'components/LoginButton'
import React from 'react'
import { LOCAL_STORAGE_FIREBASE_KEY, signInWithFirebase } from 'services/users'
import { styled } from 'themes'

const Wrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vw',
  width: '100vw',
  // https://www.figma.com/file/7yocORydRl6FxnwTO8qf7A/Brand-Attributes-Moodboard?node-id=412%3A240
  background: `
    radial-gradient(circle at 20vw 85vh, rgba(39, 179, 255, 0.35), transparent 50vw),
    radial-gradient(circle at 80vw 80vh, rgba(149, 110, 212, 0.35), transparent 50vw),
    radial-gradient(circle at 45vw 15vh, rgba(241, 149, 240, 0.35), transparent 50vw),
    $primaryAccentBackground
  `,
})
const Content = styled('section', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  minWidth: '$commandPaletteWidth',
  maxWidth: '100%',
  background: '$primaryBackground',
  height: 'calc($space1 * 75)',
  borderRadius: '$xl',
  boxShadow: '$command',
  color: '$primaryText',
})

const Logo = styled('h1', {
  fontFamily: '$gotham',
  textTransform: 'uppercase',
  letterSpacing: '.277em',
})

const Subtitle = styled('h2', {
  fontFamily: '$sans',
  baselineFontSize: ['xlarge'],
  fontWeight: '$normal',
  marginBottom: '$space12',
  color: '$primaryAccentText',
})

const Caution = styled('span', {
  fontFamily: '$sans',
  baselineFontSize: ['base'],
  fontWeight: '$normal',
  marginBottom: '$space12',
  color: '$primaryAccentText',
})

export const SignIn: React.FC = () => {
  React.useEffect(() => {
    setTimeout(() => {
      signInWithFirebase()
    }, 0)
  }, [])

  const showCaution = !localStorage.getItem(LOCAL_STORAGE_FIREBASE_KEY)

  return (
    <Wrapper>
      <Content>
        <Logo>Superhuman act2</Logo>
        <Subtitle>The fastest communication experience ever made.</Subtitle>
        {showCaution && <Caution>⚠️ Temporarily, you will sign in twice - once to firebase, once to gmail.</Caution>}
        <LogInButton />
      </Content>
    </Wrapper>
  )
}
