import React, { useRef } from 'react'

export const useFocus = <T extends HTMLElement>(): [React.RefObject<T>, (b?: boolean) => void] => {
  const el = useRef<T>(null)
  return [el, setFocus]

  function setFocus(focus?: boolean) {
    if (!el.current) return

    setTimeout(() => {
      if (focus === false) {
        el.current?.blur()
      } else {
        el.current?.focus()
      }
    }, 50)
  }
}
