import { Timestamp } from 'components/Timestamp'
import { useMessageContext } from 'contexts/Message'
import React from 'react'
import { styled } from 'themes'
import { BaselineElement } from 'themes/typesafe-stitches'
import datetime from 'utils/datetime'

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'flex-end',
  color: '$primaryAccentText',
  cursor: 'default',
  padding: `$space1 $space10 $space2 $space5`,
  '& strong': {
    fontWeight: '$semibold',
  },
  '& strong::after': {
    content: '·',
    margin: '0 $space1',
    fontWeight: '$semibold',
  },
})
const Ellipsis = styled('div', {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  height: '$space5',
})
const Text = styled('span', {
  display: 'inline-block',
  baselineFontSize: ['small'],
  variants: {
    reminder: {
      true: {
        color: '$reminder',
      },
    },
    strong: {
      true: {
        fontWeight: '$semibold',
      },
    },
  },
})

const ReminderDate = styled(Text, {
  marginLeft: '$space2',
})

export const MessageHeader: React.FC = () => {
  const { reminder, quoteUser, quote, isPrevMessageFromSameThread, isNextMessageFromSameThread } = useMessageContext()
  const showQuoteHeader = quoteUser && (isPrevMessageFromSameThread || isNextMessageFromSameThread)
  return (
    <>
      {showQuoteHeader && (
        <Wrapper>
          <Ellipsis>
            <Text strong>Replies to {quoteUser.name}</Text>
            <Text>&nbsp;</Text>
            <Text>{quote}</Text>
          </Ellipsis>
        </Wrapper>
      )}
      {reminder?.remindAt && (
        <Wrapper>
          <Ellipsis>
            <Text reminder>Reminder visible only to you</Text>{' '}
            <ReminderDate>
              {(<Timestamp epoch={datetime.toEpoch(reminder?.remindAt)} relative />) as BaselineElement}
            </ReminderDate>
          </Ellipsis>
        </Wrapper>
      )}
    </>
  )
}
