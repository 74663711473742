import { User } from 'services/users'
import { RootState } from 'root-state'
import uniq from 'lodash/uniq'

export const getUserById = (state: RootState, id?: string): User | undefined => {
  if (!id) {
    return undefined
  }
  return state.users[id]
}

export const getUsersForMessageList = (state: RootState, messageListId: string): User[] => {
  const userIds = uniq((state.messageLists[messageListId]?.messages || []).map(m => m.userId))
  return userIds.map(u => getUserById(state, u)).filter((u): u is User => !!u)
}
