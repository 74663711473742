import { Icon } from 'components/Icons'
import { useAuthContext } from 'contexts/Auth'
import { Command, slug, useCommandPalette } from 'contexts/CommandPalette'
import { useDispatch } from 'hooks/use-dispatch'
import { useSelector } from 'hooks/use-selector'
import React, { useEffect, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { isOpen } from 'selectors/command-palette'
import { getFocusedItemContent } from 'selectors/focus'
import { setAsOpen } from 'slices/command-palette'
import { styled } from 'themes'
import { BaselineElement } from 'themes/typesafe-stitches'
import { scrollIntoView } from 'utils/scroll-into-view'

const Commands = styled('section', {
  maxHeight: '33vh',
  overflowY: 'scroll',
  '@narrow': {
    maxHeight: 'calc(75vh - $space1*36)',
  },
})

const Overlay = styled('div', {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  zIndex: '$overlay',
  '@narrow': {
    background: 'rgba(0,0,0,.25)',
  },
})

const Outer = styled('div', {
  position: 'relative',
  '@narrow': {
    position: 'absolute',
    bottom: 0,
  },
})

const Wrapper = styled('div', {
  width: '$commandPaletteWidth',
  maxWidth: '100vw',
  background: '$invertedBackground',
  borderRadius: '$xxl',
  boxShadow: '$command',
  overflow: 'hidden',
  '@narrow': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
})

const Header = styled('header', {
  padding: '$space5 $space9',
  display: 'flex',
  alignItems: 'center',
  borderBottomStyle: 'solid',
  // @ts-expect-error intentional non-theme value
  borderBottomWidth: '0.5px',
  borderBottomColor: '$invertedAccentText',
  fontSize: '$base',
  letterSpacing: '$tight',
  color: '$invertedText',
  gap: '$space3',

  '& svg': {
    height: 18, // intentionally not baseline
    width: 18, // intentionally not baseline
    fill: '$invertedText',
  },
})

const Input = styled('input', {
  width: '100%',
  border: 0,
  display: 'block',
  background: 'transparent',
  color: '$invertedText',
  fontFamily: '$sans',
  fontWeight: '$normal',
  fontSize: '$xlarge',
  letterSpacing: '$wide',
  padding: '$space5 $space9 $space6 $space9',
  caretColor: '$focusOrActive',
  outline: 'none',
})

const FocusedItem = styled('div', {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: '$invertedAccentText',
  baselineFontSize: ['small'],
  padding: '$space2 $space9',
  fontSize: '$small',
  cursor: 'default',
})

const User = styled('span', {
  display: 'inline',
  fontWeight: '$semibold',
})

// todo: #accessibility: use a real button, not a div.
const CommandRow = styled('div', {
  display: 'grid',
  alignItems: 'center',
  gridTemplateColumns: 'minmax(0, 70%) auto',
  padding: '$space5 $space8',
  color: '$invertedAccentText',
  borderLeftWidth: '$thick',
  borderLeftColor: 'transparent',
  borderLeftStyle: 'solid',
  fontSize: '$base',
  fontWeight: '$normal',
  variants: {
    selected: {
      true: {
        color: '$invertedText',
        borderColor: '$focusOrActive',
        background: '$invertedAccentBackground',
      },
    },
  },
  '&::last-child': {
    background: 'red',
  },
})

const Name = styled('div', {
  baselineFontSize: ['base'],
})
const Hint = styled('div', {
  baselineFontSize: ['base'],
  textAlign: 'right',
})

function sortCommandsByRelevance(a: Command, b: Command): number {
  return b.score - a.score
}

const Modal = () => {
  const dispatch = useDispatch()
  const { user } = useAuthContext()
  const { title, icon, placeholder, commands: allCommands } = useCommandPalette()
  const [input, setInput] = useState('')
  const [selectedIndex, setSelectedIndex] = useState(0)
  const focusedItemContent = useSelector(s => getFocusedItemContent(s, user))

  const commands = allCommands
    .filter(cmd => cmd.enabled || cmd.enabled === undefined)
    .filter(cmd => slug(cmd.title).includes(slug(input)))
    .sort(sortCommandsByRelevance)

  function selectCommand(index: number) {
    const selected = commands[index]

    dispatch(setAsOpen(false))
    selected.handler()
  }

  function select(e: KeyboardEvent) {
    selectCommand(selectedIndex)
    e.preventDefault()
  }

  function close(e: KeyboardEvent | React.MouseEvent<HTMLDivElement>) {
    e.preventDefault()
    dispatch(setAsOpen(false))
  }

  function previous(e: KeyboardEvent) {
    if (selectedIndex === 0) {
      return setSelectedIndex(commands.length - 1)
    }

    setSelectedIndex(selectedIndex - 1)
  }

  function next() {
    if (selectedIndex === commands.length - 1) {
      return setSelectedIndex(0)
    }

    setSelectedIndex(selectedIndex + 1)
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setInput(e.currentTarget.value)
    setSelectedIndex(-1)
  }

  useEffect(() => {
    if (selectedIndex === -1 && commands.length > 0) {
      setSelectedIndex(0)
    }
  }, [commands.length, selectedIndex])

  useHotkeys('up', previous, { enableOnTags: ['INPUT'] })
  useHotkeys('down', next, { enableOnTags: ['INPUT'] })
  useHotkeys('enter', select, {
    enableOnTags: ['INPUT'],
    keydown: true,
  })

  useHotkeys('esc', close, {
    enableOnTags: ['INPUT'],
    keydown: true,
  })

  const inputEl = useRef<HTMLInputElement>(null)
  const selectedEl = useRef<HTMLDivElement>(null)
  const scrollEl = useRef<HTMLDivElement>(null)

  useEffect(() => {
    scrollIntoView(scrollEl.current, selectedEl.current)
  }, [selectedIndex, selectedEl, scrollEl])

  const bringBackFocus = React.useCallback(() => {
    inputEl.current?.focus()
  }, [])

  return (
    <Overlay onClick={close}>
      <Outer>
        <Wrapper>
          <Header>
            <Icon name={icon} />
            {title}
          </Header>
          {focusedItemContent ? (
            <FocusedItem>
              {(<User>{focusedItemContent.username || ''}</User>) as BaselineElement} ·{' '}
              {focusedItemContent.body.replace(/\n/g, ' ')}
            </FocusedItem>
          ) : null}
          <Input
            ref={inputEl}
            value={input}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={bringBackFocus}
            autoFocus
          />
          <Commands ref={scrollEl}>
            {commands.map((cmd, ind) => (
              <CommandRow
                key={cmd.title}
                onClick={() => selectCommand(ind)}
                selected={selectedIndex === ind}
                ref={selectedIndex === ind ? selectedEl : undefined}
              >
                <Name>{cmd.title}</Name>
                {cmd.hint ? <Hint>{cmd.hint}</Hint> : null}
              </CommandRow>
            ))}
          </Commands>
        </Wrapper>
      </Outer>
    </Overlay>
  )
}

export const CommandPaletteModal = () => {
  const isModalOpen = useSelector(isOpen)
  if (isModalOpen) {
    return <Modal />
  }
  return null
}
