import { useCommand } from 'contexts/CommandPalette'
import React from 'react'
import { styled, ThemeName, themes } from 'themes'

const Wrapper = styled('div', {
  background: '$primaryBackground',
  height: '100vh',
  fontFamily: '$sans',
  fontSize: '$base',
  color: '$primaryText',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
})

const BaselineGrid = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  zIndex: 'calc($window + 1)',
  backgroundImage: `
    linear-gradient(
      transparent,
      transparent calc(var(--space-space1) * 0.85),
      rgba(255, 76, 41, 0.7) var(--space-space1)
    )
  `,
  backgroundSize: '100% var(--space-space1)',
  pointerEvents: 'none',
})

const LOCAL_STORAGE_BASELINE_KEY = 'developerBaselineGrid'
const LOCAL_STORAGE_THEME_KEY = 'theme'

const context = React.createContext((localStorage.getItem(LOCAL_STORAGE_THEME_KEY) as ThemeName) || 'snow')
export const ThemeWrapper: React.FC = ({ children }) => {
  const [themeName, setThemeName] = React.useState<ThemeName>(
    localStorage.getItem(LOCAL_STORAGE_THEME_KEY) as ThemeName
  )
  const setTheme = React.useCallback((themeName: ThemeName) => {
    setThemeName(themeName)
    localStorage.setItem(LOCAL_STORAGE_THEME_KEY, themeName)
  }, [])
  React.useEffect(() => {
    Object.values(themes).forEach(themeName => document.body.classList.remove(themeName))
    if (themes[themeName] && !document.body.classList.contains(themes[themeName])) {
      document.body.classList.add(themes[themeName])
    }
  }, [themeName])

  useCommand({
    title: 'Set Theme: snow',
    handler: React.useCallback(() => setTheme('snow'), [setTheme]),
    score: 2,
    enabled: themeName !== 'snow',
  })
  useCommand({
    title: 'Set Theme: carbon',
    handler: React.useCallback(() => setTheme('carbon'), [setTheme]),
    score: 2,
    enabled: themeName !== 'carbon',
  })

  const [baselineGridVisible, setBaselineGridVisible] = React.useState(
    Boolean(localStorage.getItem(LOCAL_STORAGE_BASELINE_KEY))
  )
  const toggleBaseline = React.useCallback(() => {
    setBaselineGridVisible(!baselineGridVisible)
    if (baselineGridVisible) {
      localStorage.removeItem(LOCAL_STORAGE_BASELINE_KEY)
    } else {
      localStorage.setItem(LOCAL_STORAGE_BASELINE_KEY, 'true')
    }
  }, [baselineGridVisible])

  useCommand({
    title: `Developer: ${baselineGridVisible ? 'Hide' : 'Display'} Baseline Grid`,
    handler: toggleBaseline,
    score: 0,
  })

  const toggleBaselineOutlines = React.useCallback(() => {
    const outlined = document.querySelectorAll('[style*="outline"][style*="solid"]')
    if (outlined.length > 0) {
      outlined.forEach(a => {
        const el = a as HTMLElement
        el.style.removeProperty('outline')
      })
      return
    }
    document.querySelectorAll('*').forEach(a => {
      const el = a as HTMLElement
      const rect = el.getBoundingClientRect()
      const parentRect = el.offsetParent?.getBoundingClientRect()
      const height = rect.height
      const top = parentRect?.top || 0 + rect.top
      const bottom = document.body.clientHeight - (parentRect?.bottom || 0 + rect.bottom)
      if (height > 600 || el.nodeName === 'svg' || !!a.closest('svg')) {
        return
      } else if (top % 4 !== 0 && bottom % 4 === 0) {
        el.style.outline = '1px solid teal'
      } else if (top % 4 !== 0) {
        el.style.outline = '1px solid orange'
      } else if (height % 4 !== 0) {
        el.style.outline = '1px solid orange'
      } else {
        el.style.outline = '1px solid green'
      }
    })
  }, [])
  useCommand({
    title: `Developer: Toggle Baseline Outlines`,
    handler: toggleBaselineOutlines,
    score: 0,
  })

  return (
    <context.Provider value={themeName}>
      <Wrapper>
        {baselineGridVisible ? <BaselineGrid /> : null}
        {children}
      </Wrapper>
    </context.Provider>
  )
}
export const useTheme = () => React.useContext(context)
