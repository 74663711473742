import { Input } from 'components/Input'
import React, { useEffect, useRef } from 'react'
import { styled } from 'themes'

const InputWrapper = styled('div', {
  display: 'grid',
  backgroundColor: 'transparent',
  fontFamily: '$sans',
  '&::after': {
    content: "attr(data-replicated-value) ' '",
    whiteSpace: 'pre-wrap',
    visibility: 'hidden',
    overflowX: 'hidden',
    font: 'inherit',
    gridArea: '1 / 1 / 2 / 2',
    padding: '$space2 $space5',
    maxHeight: '50vh', // Limit auto-growing chat compose to be max. half of the screen.
  },
})

type Props = {
  placeholder: string
  inputRef: React.RefObject<HTMLTextAreaElement>
  onInput: (value: string) => void
  value: string
  onFocus?: () => void
  onBlur?: () => void
}

export const TextField: React.FC<Props> = ({ value, onInput, ...props }: Props) => {
  const containerEl = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!containerEl.current) return
    containerEl.current.dataset.replicatedValue = value
  }, [containerEl, value])

  return (
    <InputWrapper ref={containerEl}>
      <Input
        value={value}
        rows={1}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onInput(e.currentTarget.value)}
        ref={props.inputRef}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        placeholder={props.placeholder}
      />
    </InputWrapper>
  )
}
