import * as React from 'react'

type Props = React.SVGProps<SVGSVGElement>
export const CommandCircledIcon: React.FC<Props> = (props: Props) => (
  <svg width='1em' height='1em' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 19' {...props}>
    <g>
      <circle cx='9' cy='9.5' r='8.5' stroke='white' fill='none' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.394 3.005a1 1 0 011.213 0l4.946 3.774a1 1 0 010 1.59L12.71 9.775l1.843 1.406a1 1 0 010 1.59l-4.946 3.774a1 1 0 01-1.213 0l-4.946-3.774a1 1 0 010-1.59l1.843-1.406-1.843-1.406a1 1 0 010-1.59l4.946-3.774zm2.086 8.471l1.405-1.072 1.406 1.072H10.48zm2.81 1H8.833l-.135-.102-2.581-1.97-2.06 1.572L9 15.75l4.29-3.274zM9 11.347L4.056 7.574 9.001 3.8l4.945 3.774-4.945 3.773z'
        fill='white'
      />
    </g>
  </svg>
)
