import { NavBlurEffect } from 'components/BlurEffects'
import { Icon } from 'components/Icons'
import { useAuthContext } from 'contexts/Auth'
import { useCommand } from 'contexts/CommandPalette'
import { useSelector } from 'hooks/use-selector'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getAllChannels, getSelectedChannelId } from 'selectors/channels'
import { measure } from 'services/metrics'
import { MessageListState } from 'slices/message-lists'
import { styled } from 'themes'
import { BaselineElement } from 'themes/typesafe-stitches'

const folders = [
  { name: 'Inbox', icon: 'inbox' },
  { name: 'Sent', icon: 'sent' },
  { name: 'Done', icon: 'done' },
] as const

const Wrapper = styled('nav', {
  position: 'relative',
  background: '$navigationBg',
  padding: '0 $space2',
  '@narrow': {
    position: 'sticky',
    top: '0',
    marginBottom: '$negativeThinBorder',
    borderBottomWidth: '$thin',
    borderBottomColor: '$borderColor',
    borderBottomStyle: 'solid',
  },
})
const Header = styled('h1', {
  display: 'flex',
  alignItems: 'center',
  fontWeight: '$fontNormal',
  padding: '$space5 0 $space7 0',
  margin: 0,
  '@narrow': {
    padding: 0,
  },
})

const OrganizationName = styled('span', {
  baselineFontSize: ['large'],
  marginRight: '$space2',
  padding: '0 $space3',
  '@narrow': {
    display: 'none',
  },
})

const Separator = styled('div', {
  borderBottomStyle: 'solid',
  borderBottomWidth: '$thin',
  borderBottomColor: '$borderColor',
  margin: '$negativeThinBorder $space4 $space2 $space4',
  '@narrow': {
    padding: '0',
    margin: '$negativeThinBorder 0 0 0',
  },
})

const Folders = styled('section', {
  marginBottom: '$space3',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  '@narrow': {
    display: 'flex',
    paddingBottom: '$space1',
    marginBottom: '$space1',
    overflowX: 'auto',
  },
})
const Channels = styled(Folders, {})
const Folder = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  fontWeight: '$normal',
  padding: '$space1 $space3',
  marginRight: '$space2',
  baselineFontSize: ['small', 'normal'],
  borderRadius: '$m',
  cursor: 'pointer',
  color: '$primaryText',
  flex: '0 0 auto',
  '&:hover': {
    fontWeight: '$semibold',
  },
  '& svg': {
    marginRight: '$space2',
    stroke: '$primaryAccentText',
  },
  '@narrow': {
    padding: '$space1 $space3 0 $space3',
  },
  variants: {
    focused: {
      true: {
        background: '$primaryAccentBackground',
        fontWeight: '$bold',
        letterSpacing: '-0.05px',
        color: '$primaryText',
        '& svg': {
          fill: '$primaryText',
        },
      },
    },
    unread: {
      true: {
        fontWeight: '$semibold',
        '& svg': {
          fill: '$navigationUnreadFg',
        },
      },
    },
    channel: {
      true: {
        '& svg': {
          fill: '$primaryAccentText',
          stroke: 'none',
        },
      },
    },
    more: {
      true: {
        color: '$primaryAccentText',
      },
    },
  },
})

const ChannelName: React.FC<{ active: boolean; channel: MessageListState }> = ({ active, channel }) => {
  const navigate = useNavigate()
  const { user } = useAuthContext()
  const openChannelHandler = React.useCallback(() => {
    navigate(`/channels/${channel.id}`)
    const workspaceId = user?.profiles[0]?.workspaceId || ''
    measure(workspaceId, 'ui.chat.channel_open')
  }, [channel.id, navigate, user])

  useCommand({
    title: `#${channel.name}`,
    enabled: !active,
    target: channel,
    handler: openChannelHandler,
    score: 10,
  })
  return (
    <Folder key={channel.name} focused={active} onClick={openChannelHandler}>
      {(<Icon name='hashtag' />) as BaselineElement}
      {channel.name}
    </Folder>
  )
}

type Props = {
  hidden?: boolean
}
export const Navigation: React.FC<Props> = ({ hidden }: Props) => {
  const channelId = useSelector(getSelectedChannelId)
  const channels = useSelector(getAllChannels)

  if (hidden) {
    return null
  }

  return (
    <Wrapper>
      <NavBlurEffect />
      <Folders>
        <Header>
          <OrganizationName>Superhuman</OrganizationName>
        </Header>
        {folders.map(f => (
          <Folder key={f.name}>
            {(<Icon name={f.icon} />) as BaselineElement}
            {f.name}
          </Folder>
        ))}
        <Folder more>
          {(<Icon name='more' />) as BaselineElement}
          More
        </Folder>
      </Folders>
      <Separator />
      <Channels>
        {channels.map(channel => (
          <ChannelName key={channel.id} active={channelId === channel.id} channel={channel} />
        ))}
      </Channels>
    </Wrapper>
  )
}
