import { Thread } from 'components/Thread'
import { useSelector } from 'hooks/use-selector'
import * as React from 'react'
import { styled } from 'themes'

const Wrapper = styled('aside', {
  borderLeftWidth: '$thin',
  borderLeftStyle: 'solid',
  borderColor: '$borderColor',
  '@narrow': {
    maxHeight: '50%',
    borderLeftWidth: '0',
    borderTopStyle: 'solid',
    borderTopWidth: '$thin',
    marginTop: '$negativeThinBorder',
  },
})

export const Sidebar: React.FC = () => {
  const threadId = useSelector(s => s.focus.sidebar.messageListId)
  if (!threadId) {
    return null
  }
  return (
    <Wrapper>
      <Thread threadId={threadId} />
    </Wrapper>
  )
}
