import { IconName } from 'components/Icons'
import { useDispatch } from 'hooks/use-dispatch'
import React, { createContext, DependencyList, useContext, useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { setAsOpen } from 'slices/command-palette'
import { CommandPaletteShortcuts } from './shortcuts'

export type Command = {
  title: string
  handler: () => void
  score: number
  target?: unknown
  enabled?: boolean
  hint?: string
  shortcut?: string
  disableShortcutFromInputs?: boolean
}
/**
 * @function useCommand
 *
 * uses a type brand added to the response type of React.useCallback
 * this type brand ensures that command handlers can appropriately update when dependencies change
 * while simultaneously preventing unnecessary rerenders or wild rerender loops
 *
 * valid calls:
 * - with `handler` memoized with `React.useCallback`
 * - with explicit dependencies passed
 *
 * invalid calls:
 * - with a plain old `handler` and no passed dependencies
 */
type MemoizedCallback = () => void & { __brand: 'Memoized via React.UseCallback()' }
declare module 'react' {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function useCallback<T extends (...args: any[]) => any>(callback: T, deps: DependencyList): T & MemoizedCallback
}
function useCommand(cmd: Command, additionalDeps: DependencyList): void
function useCommand(cmd: Command & { handler: MemoizedCallback }, additionalDeps?: undefined): void
function useCommand(cmd: Command, additionalDeps: DependencyList = []): void {
  const { globalCommands } = useCommandPalette()
  const deps = [globalCommands, cmd.title, cmd.enabled, cmd.target, cmd.hint, cmd.score, ...additionalDeps]
  useEffect(() => {
    if (cmd.enabled !== false) {
      globalCommands.push(cmd)
    }
    return () => {
      const idx = globalCommands.indexOf(cmd)
      if (idx > -1) {
        globalCommands.splice(idx, 1)
      }
    }
    // intentionally non-exhaustive hooks here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  useHotkeys(cmd.shortcut || '', cmd.handler, {
    enabled: !!cmd.shortcut && cmd.enabled,
    keydown: true,
    enableOnTags: cmd.disableShortcutFromInputs ? [] : ['TEXTAREA', 'SELECT', 'INPUT'],
  })
}
export type CommandPaletteOptions = {
  title: string
  placeholder: string
  icon: IconName
  commands: Command[]
}

const noop = () => {
  /**/
}

export function slug(str: string): string {
  return str.toLowerCase().replace(/[^a-z0-9]/g, '')
}

export type CommandPaletteContextValue = CommandPaletteOptions & {
  title: string
  placeholder: string
  icon: IconName
  commands: Command[]
  globalCommands: Command[]
  openCommandPalette: (options?: CommandPaletteOptions) => void
  openCommandPaletteForTarget: (target: unknown) => void
}
const initialState: CommandPaletteContextValue = {
  title: 'Superhuman Command',
  placeholder: '',
  icon: 'commandCircled',
  commands: [],
  globalCommands: [],
  openCommandPalette: noop,
  openCommandPaletteForTarget: noop,
}
export const CommandPaletteContext: React.FC = props => {
  const dispatch = useDispatch()
  const { current: globalCommands } = React.useRef<Command[]>(initialState.globalCommands)
  const [state, setState] = useState<CommandPaletteContextValue>(initialState)

  const openCommandPalette = React.useCallback(
    (opts: Partial<CommandPaletteOptions> = {}) => {
      // *before* opening the dialog, create a copy of current commands array,
      // after the dialog opens, app focus state will change
      // we don't want this to change the currently available commands
      const commands = [...(opts.commands || globalCommands)]
      setState({
        ...initialState,
        ...opts,
        commands,
      })
      dispatch(setAsOpen(true))
    },
    [dispatch, globalCommands]
  )

  const openCommandPaletteForTarget = React.useCallback(
    (target: unknown) => {
      const commands = globalCommands.filter(cmd => {
        return cmd.target === target
      })
      openCommandPalette({ commands })
    },
    [openCommandPalette, globalCommands]
  )

  state.openCommandPalette = openCommandPalette
  state.openCommandPaletteForTarget = openCommandPaletteForTarget
  state.globalCommands = globalCommands
  return (
    <Context.Provider value={state}>
      <CommandPaletteShortcuts />
      {props.children}
    </Context.Provider>
  )
}

export const Context = createContext<CommandPaletteContextValue>(initialState)
const useCommandPalette = () => useContext(Context)
export { useCommand, useCommandPalette }
