import { baselineFontSize, fontSizes, lineHeights, spaceAndSize } from 'themes/baseline'
import { createTypeSafeStitches } from 'themes/typesafe-stitches'

export const { styled, css, theme, createTheme } = createTypeSafeStitches({
  theme: {
    colors: {
      // https://www.figma.com/file/qQKDqFD5iR22hIVmnC6i3W/%E2%9C%A8-Act-II?node-id=2424%3A128397
      navigationBg: '#F9F9F9',
      primaryBackground: 'white',
      primaryText: '#0A0A0A',
      primaryAccentText: '#6A7080',
      primaryAccentBackground: '#E5E5E5',
      invertedBackground: '#27292d',
      invertedText: '#F4F4F4',
      invertedAccentText: '#7C7F81',
      invertedAccentBackground: '#3C3F42',
      focusOrActive: '#7178EF',
      focusOrActiveBackground: '#F8F8FF',
      linkColor: '#36A0DA',
      linkBackground: '#F3F9FD',
      inputBackground: '#F6F6F8',
      borderColor: '#E4E6EA',
      reminder: '#CD6DCD',
      reminderBackground: '#CE9ECE',
    },
    space: spaceAndSize,
    fontSizes,
    fonts: {
      sans: '"Adelle Sans", -apple-system, BlinkMacSystemFont, sans-serif',
      gotham: '"Superhuman Gotham Book", "Adelle Sans", -apple-system, BlinkMacSystemFont, sans-serif',
    },
    fontWeights: {
      light: 300,
      normal: 400,
      semibold: 600,
      bold: 700,
    },
    lineHeights,
    letterSpacings: {
      tight: '-0.2px',
      normal: '0',
      wide: '0.2px',
    },
    sizes: spaceAndSize,
    borderWidths: {
      thin: '1px',
      thick: '3px',
    },
    borderStyles: {},
    radii: {
      none: 0,
      xs: '2px',
      s: '3px',
      m: '4px',
      l: '6px',
      xl: '8px',
      xxl: '12px',
      xxxl: '20px',
      full: '100em',
    },
    shadows: {
      light: '0px 1px 8px rgba(0, 0, 0, 0.06)',
      medium: '0px 1px 12px rgba(0, 0, 0, 0.1)',
      strong: '3px 12px 30px rgba(102, 137, 156, 0.12)',
      command: '0px 15px 25px rgba(0, 0, 0, 0.1), 0px 19px 45px rgba(0, 0, 0, 0.2)',
    },
    zIndices: {
      base: 10,
      aboveBase: 20,
      content: 30,
      aboveContent: 35,
      navigation: 40,
      aboveNavigation: 45,
      modal: 60,
      aboveModel: 65,
      overlay: 90,
      aboveOverlay: 95,
      window: 100,
    },
    transitions: {},
  },
  utils: {
    panel: () => ({
      display: 'grid',
      gridTemplateColumns: 'minmax(220px, 17%) auto minmax(354px, 27%)',
      gridTemplateRows: 'max(100%)',
      height: '100%',
    }),
    // warning: only use this on leaf/text nodes,
    // otherwise you're gonna have a bad time with the negative bottom
    baselineFontSize,
  },
  media: {
    narrow: '(max-width: 800px)',
    wide: '(min-width: 800px)',
  },
})

const darkTheme = createTheme({
  colors: {
    navigationBg: '#18191A',
    primaryBackground: '#28292C',
    primaryText: 'white',
    primaryAccentText: '#aaa',
    primaryAccentBackground: '#464B53',
    invertedBackground: '#686D77',
    invertedText: '#ddd',
    invertedAccentText: '#fff',
    invertedAccentBackground: '#777C85',
    focusOrActive: '#54acdc',
    focusOrActiveBackground: '#0A2533',
    linkColor: '#36A0DA',
    linkBackground: '#061923',
    inputBackground: '#333',
    borderColor: '#444',
    reminder: '#CD6DCD',
    reminderBackground: '#CE9ECE',
  },
  shadows: {
    light: '0px 1px 8px rgba(255, 255, 255, 0.06)',
    medium: '0px 1px 12px rgba(255, 255, 255, 0.1)',
    strong: '3px 12px 30px rgba(153, 118, 99, 0.12)',
  },
})

export const themes = {
  snow: theme,
  carbon: darkTheme,
}
export type ThemeName = keyof typeof themes
