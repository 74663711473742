import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  orgId: 'superhuman'
  id?: string
}

export const initialState: State = {
  orgId: 'superhuman', // legacy, firebase
}

export const slice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<{ id: string }>) => {
      state.id = action.payload.id
    },
  },
})

export const { setSession } = slice.actions
export default slice.reducer
