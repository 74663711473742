import { useTheme } from 'components/ThemeWrapper'
import { useAuthContext } from 'contexts/Auth'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getCurrentUser } from 'services/users'
import { styled } from 'themes'

const Iframe = styled('iframe', {
  border: 'none',
  height: 'calc(40px)',
})
const url = new URL(process.env.REACT_APP_LOGIN_URL || '', document.baseURI)
export const LogInButton: React.FC = () => {
  const navigate = useNavigate()
  const { signIn } = useAuthContext()
  const theme = useTheme()
  React.useEffect(() => {
    const onMessage = (event: MessageEvent) => {
      if (event.origin !== url.origin) {
        return
      }
      try {
        switch (event.data?.name) {
          case 'sign_in_success':
            getCurrentUser()
              .then(user => signIn(user))
              .then(() => {
                navigate('/channels/welcome')
              })
            break
          case 'sign_in_error':
            break
        }
      } catch (e) {
        console.error(e)
      }
    }
    window.addEventListener('message', onMessage)
    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [navigate, signIn])
  return <Iframe src={`${process.env.REACT_APP_LOGIN_URL}#theme=${theme}`} title='login' />
}
