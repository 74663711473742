import React, { useEffect, useRef } from 'react'
import { styled } from 'themes'

const Wrapper = styled('div', {})

type Props = {
  loadMore: () => void
}

export const LoadMore: React.FC<Props> = ({ loadMore, children }) => {
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const container = wrapperRef.current
    if (container) {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          loadMore()
        }
      })
      observer.observe(container)
      return () => {
        observer.unobserve(container)
      }
    }
  }, [wrapperRef, loadMore])

  return <Wrapper ref={wrapperRef}>{children}</Wrapper>
}
