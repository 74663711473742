import { Step, StepAction } from 'slices/undo-tree'
import { RootState } from 'root-state'

export function getLastUndoStep(state: RootState): Step | undefined {
  return state.undotree.undo[state.undotree.undo.length - 1]
}

export function getFirstRedoStep(state: RootState): Step | undefined {
  return state.undotree.redo[0]
}

const stepActionDesc: Record<StepAction, string> = {
  [StepAction.DeleteReminderById]: 'New reminder',
  [StepAction.RevertArchivingReminderById]: 'Archived reminder',
}

export function getActionDescription(action: StepAction): string {
  return stepActionDesc[action]
}
