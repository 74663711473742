import { useCommandPalette } from 'contexts/CommandPalette'
import { useMessageListContext } from 'contexts/MessageList'
import { useDispatch } from 'hooks/use-dispatch'
import { useReminders } from 'hooks/use-reminders'
import { useSelector } from 'hooks/use-selector'
import React from 'react'
import {
  ChatItem,
  chatItemIsMessage,
  getQuoteForItem,
  getTotalMessagesByListId,
  isItemEditable,
} from 'selectors/messages'
import { getPendingRemindersByMessageId } from 'selectors/reminders'
import { getUserById } from 'selectors/users'
import { Message } from 'services/messages'
import { User } from 'services/users'
import { openThreadForMessage } from 'slices/focus'
import { jumpToMessage } from 'actions/messages'
import { Reminder } from 'services/reminders'
import { MessageShortcuts } from './shortcuts'
import { openChat } from 'slices/message-lists'
import { MessageListType } from 'services/message-lists'

type MessageContextValue = {
  message: Message | null
  reminder: Reminder | null
  pendingReminder: Reminder | null
  user: User | null
  replyCount: number
  quote: string | null
  quoteUser: User | null

  isSelected: boolean
  isEditable: boolean
  isEditing: boolean
  isThreadedReply: boolean
  isNextMessageFromSameThread: boolean
  isPrevMessageFromSameThread: boolean

  openInThread: () => void
  openReminders: () => void
  archiveReminder: () => void
  openCommandMenu: () => void
  focusMessage: (opts?: { editing?: boolean }) => void
  jumpToItem: () => void
}
const noop = () => {
  /* */
}
const initialValue: MessageContextValue = {
  message: null,
  user: null,
  reminder: null,
  pendingReminder: null,
  quoteUser: null,

  replyCount: 0,
  quote: null,
  isNextMessageFromSameThread: false,
  isPrevMessageFromSameThread: false,
  isThreadedReply: false,
  isSelected: false,
  isEditable: false,
  isEditing: false,

  openInThread: noop,
  openReminders: noop,
  archiveReminder: noop,
  openCommandMenu: noop,
  focusMessage: noop,
  jumpToItem: noop,
}
type Props = {
  item: ChatItem
  nextItem: ChatItem | undefined
  prevItem: ChatItem | undefined
}
export const context = React.createContext<MessageContextValue>(initialValue)
export const MessageContext: React.FC<Props> = ({ item, prevItem, nextItem, children }) => {
  const message = chatItemIsMessage(item) ? item : null
  const reminder = chatItemIsMessage(item) ? null : item

  const {
    focus,
    isFocused,
    focusedItem,
    focusMessage: focusMessageInChannel,
    id: messageListId,
    type,
  } = useMessageListContext()
  const { openReminderMenu, archiveReminder: archiveReminderForMessage } = useReminders({ messageListId, type })
  const { openCommandPaletteForTarget } = useCommandPalette()
  const dispatch = useDispatch()

  const replyCount = useSelector(s => getTotalMessagesByListId(s, item.id))
  const pendingReminder = useSelector(s => getPendingRemindersByMessageId(s, item.id)) || null
  const user = useSelector(s => getUserById(s, message?.userId)) || null

  const isNextMessageFromSameThread =
    !!message?.threadId && chatItemIsMessage(nextItem) && nextItem?.threadId === message?.threadId
  const isPrevMessageFromSameThread =
    !!message?.threadId && chatItemIsMessage(prevItem) && prevItem?.threadId === message?.threadId
  const isThreadedReply = isNextMessageFromSameThread || isPrevMessageFromSameThread

  const isSelected = isFocused && item.id === focusedItem?.id
  const isEditing = (isSelected && focus.editing) || false
  const isEditable = useSelector(s => isItemEditable(s, item))

  const quote = useSelector(s => !isPrevMessageFromSameThread && getQuoteForItem(s, item)) || null
  const quoteUser = useSelector(s => getUserById(s, getQuoteForItem(s, item)?.userId)) || null

  const openInThread = React.useCallback(
    (e: Event) => {
      e?.stopPropagation()
      e?.preventDefault()
      if (!message) {
        return
      }
      dispatch(openThreadForMessage(message))
      dispatch(openChat({ messageListId: message.threadId || message.id, type: MessageListType.Thread }))
    },
    [dispatch, message]
  )

  const jumpToItem = React.useCallback(
    (e: Event) => {
      e.preventDefault()
      e.stopPropagation()
      if (!reminder) {
        return
      }
      dispatch(
        jumpToMessage({
          messageId: reminder.chatMessageId,
          messageListId: reminder.messageListId,
          type: reminder.messageListType,
        })
      )
    },
    [dispatch, reminder]
  )

  const openReminders = React.useCallback(() => {
    if (item) {
      openReminderMenu(item)
    }
  }, [item, openReminderMenu])

  const archiveReminder = React.useCallback(() => {
    if (reminder?.id) {
      archiveReminderForMessage(reminder.id)
    }
  }, [reminder, archiveReminderForMessage])

  const openCommandMenu = React.useCallback(() => {
    openCommandPaletteForTarget(item)
  }, [item, openCommandPaletteForTarget])

  const focusMessage = React.useCallback(
    ({ editing }: { editing?: boolean } = {}) => {
      focusMessageInChannel({ item, editing })
    },
    [item, focusMessageInChannel]
  )

  const value = React.useMemo(() => {
    const value: MessageContextValue = {
      message,
      reminder,
      pendingReminder,
      user,
      quote: quote?.message || null,
      quoteUser,

      replyCount,
      isSelected,
      isEditable,
      isEditing,
      isThreadedReply,
      isNextMessageFromSameThread,
      isPrevMessageFromSameThread,

      openInThread,
      focusMessage,
      jumpToItem,
      openReminders,
      archiveReminder,
      openCommandMenu,
    }
    return value
  }, [
    isEditable,
    isEditing,
    isNextMessageFromSameThread,
    isPrevMessageFromSameThread,
    isSelected,
    isThreadedReply,
    message,
    pendingReminder,
    quote?.message,
    quoteUser,
    reminder,
    replyCount,
    user,
    archiveReminder,
    focusMessage,
    jumpToItem,
    openCommandMenu,
    openInThread,
    openReminders,
  ])
  return (
    <context.Provider value={value}>
      {isSelected && <MessageShortcuts />}
      {children}
    </context.Provider>
  )
}
export const useMessageContext = () => React.useContext(context)
