import { AppDispatch, RootState } from 'root-state'
import { getReminderById } from 'selectors/reminders'
import { getOrganizationId } from 'selectors/session'
import { MessageListType } from 'services/message-lists'
import * as remindersApi from 'services/reminders'
import { Reminder } from 'services/reminders'
import { removeReminders, setReminders } from 'slices/reminders'
import datetime, { DateTime } from 'utils/datetime'
import { Unsubscribe } from 'services/firebase'

export const createReminder =
  (newReminder: { messageId: string; messageListId: string; type: MessageListType; remindAt: DateTime }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const orgId = getOrganizationId(state)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const userId = state.session.id!
    const created = await remindersApi.createReminder({
      orgId,
      userId,
      reminder: newReminder,
    })

    const timeLeft = datetime.toEpoch(newReminder.remindAt) - datetime.toEpoch(datetime.now())
    if (timeLeft > 2500) {
      return created
    }

    setTimeout(() => {
      dispatch(triggerReminder(created.id))
    }, 2500)
    return created
  }

const updateReminder =
  (reminderId: string, updates: Partial<Reminder>) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const existing = getReminderById(state, reminderId)
    if (!existing) {
      return
    }
    const orgId = getOrganizationId(state)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const userId = state.session.id!
    await remindersApi.updateReminder({
      orgId,
      userId,
      reminderId,
      updates: {
        ...existing,
        ...updates,
        createdAt: datetime.toFirebaseTime(existing.createdAt),
        remindAt: datetime.toFirebaseTime(updates.remindAt || existing.remindAt),
      },
    })
  }
export const archiveReminder = (reminderId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  await updateReminder(reminderId, {
    archivedAt: datetime.now(),
  })(dispatch, getState)
}

export const unarchiveReminder = (reminderId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  await updateReminder(reminderId, {
    archivedAt: null,
  })(dispatch, getState)
}

export const triggerReminder = (reminderId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  await updateReminder(reminderId, {
    triggeredAt: datetime.now(),
  })(dispatch, getState)
}

const subscriptions: Record<string, Unsubscribe> = {}
export const subscribeToChanges = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState()
  const orgId = getOrganizationId(state)
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const userId = state.session.id!
  const unsubscribe = await remindersApi.listenForUpdates({ orgId, userId }, (error: Error | null, updates) => {
    const { added, updated, removed } = updates
    const changes = [...added, ...updated]
    dispatch(setReminders(changes))
    dispatch(removeReminders(removed))

    // todo: (PB): fix this
    // const state = getState()
    // const missingMessages = changes.filter(r => !state.messages[r.chatMessageId]).map(m => m.chatMessageId)
    // if (missingMessages.length > 0) {
    //   dispatch(loadMessagesByIds(missingMessages))
    // }
  })
  subscriptions[userId]?.()
  subscriptions[userId] = unsubscribe
}
