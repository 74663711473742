import { format, isThisWeek, isThisYear, isToday, isYesterday } from 'date-fns'
import React from 'react'

// date-fns docs:
// https://date-fns.org/v2.28.0/docs/format

// 12:00pm (Today)
// Yesterday
// Wednesday (this week)
// July 7th, 12pm (this year)
// April 29th, 2022
const relative = (date: Date): string => {
  if (isToday(date)) {
    return format(date, 'p')
  }

  if (isYesterday(date)) {
    return 'Yesterday'
  }

  if (isThisWeek(date)) {
    return format(date, 'EEEE')
  }

  if (isThisYear(date)) {
    return format(date, 'MMMM do, p')
  }

  return format(date, 'PPP')
}

// 12:00pm (Today)
// July 7th
// April 29th, 2022
const minimalist = (date: Date): string => {
  if (isToday(date)) {
    return format(date, 'p')
  }

  if (isThisYear(date)) {
    return format(date, 'MMM d')
  }

  return format(date, 'PPP')
}

type Props = {
  epoch: number
  relative?: boolean
  minimalist?: boolean
}
export const Timestamp: React.FC<Props> = (props: Props) => {
  if (props.relative) {
    return <>{relative(new Date(props.epoch))}</>
  }

  if (props.minimalist) {
    return <>{minimalist(new Date(props.epoch))}</>
  }

  return <>{format(new Date(props.epoch), 'LLLL do, p')}</>
}
