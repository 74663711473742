import { styled } from 'themes'

export const Photo = styled('img', {
  position: 'relative',
  borderRadius: '$m',
  // before and after hack for image not loading
  '&::before': {
    content: ' ',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'inherit',
  },
  '&::after': {
    content: ' ',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '$primaryAccentBackground',
    borderRadius: '$xl',
  },
})
