import { useSelector } from 'hooks/use-selector'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { getSelectedChannelId } from 'selectors/channels'

export const RedirectToChannel: React.FC = () => {
  const navigate = useNavigate()
  const channelId = useSelector(getSelectedChannelId)

  useEffect(() => {
    if (channelId) {
      navigate(`/channels/${channelId}`)
    } else {
      navigate(`/channels/welcome`)
    }
  }, [navigate, channelId])

  return null
}
