import { RootState } from 'root-state'
import { getUserById } from 'selectors/users'
import { User } from 'services/users'
import { PageFocusRegion } from 'slices/focus'
import { isOpen } from './command-palette'

export type FocusedItemContent = {
  username?: string
  body: string
}
/**
 * summarizes the currently focused item for use in the command palette
 */
export const getFocusedItemContent = (state: RootState, user: User | null): FocusedItemContent | undefined => {
  let messageListId: string | undefined
  if (state.focus.region === PageFocusRegion.Thread) {
    messageListId = state.focus.sidebar.messageListId
  } else if (state.focus.region === PageFocusRegion.Channel) {
    messageListId = state.focus.channel.messageListId
  }
  const messageListState = state.messageLists[messageListId || '']
  if (!messageListState) {
    return
  }
  if (messageListState.focus.composeField) {
    const draft = messageListState.draft
    return { username: user?.name, body: draft.body }
  }
  if (messageListState.focus.itemId) {
    const msg = messageListState.messages.find(i => i.id === messageListState.focus.itemId)
    const username = getUserById(state, msg?.userId || '')?.name
    return { username, body: msg?.body || '' }
  }
}

export const isFocusOnMessageComposeField = (state: RootState, messageListId: string): boolean => {
  if (isOpen(state)) {
    return false
  }
  const messageListState = state.messageLists[messageListId || '']
  if (!messageListState) {
    return false
  }
  return !!messageListState.focus.composeField
}

export const isFocusOnMessageList = (state: RootState, type: PageFocusRegion): boolean =>
  !isOpen(state) && state.focus.region === type

export const getCurrentFocusRegion = (state: RootState): PageFocusRegion | null =>
  isOpen(state) ? null : state.focus.region

export const isFocusOnChannel = (state: RootState): boolean =>
  !isOpen(state) && state.focus.region === PageFocusRegion.Channel
