import { MessageBody, MessageTitle, MessageUsername } from 'components/Message/atoms'
import { MessageEditField } from 'components/MessageEditField'
import { Placeholder } from 'components/Placeholder'
import { Timestamp } from 'components/Timestamp'
import { useMessageListContext } from 'contexts/MessageList'
import { useMessageContext } from 'contexts/Message'
import React from 'react'
import { styled } from 'themes'
import datetime from 'utils/datetime'
import { BaselineElement } from 'themes/typesafe-stitches'

const Quote = styled('div', {
  baselineFontSize: ['small'],
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '& strong': {
    fontWeight: '$semibold',
  },
  '& strong::after': {
    content: '·',
    margin: '0 $space1',
    fontWeight: '$semibold',
  },
  cursor: 'default',
  '@narrow': {
    marginLeft: '$space2',
  },
})

const EditedLabel = styled('span', {
  color: '$primaryAccentText',
  fontWeight: '$normal',
  fontSize: '$small',
  marginLeft: '$space1',
  lineHeight: '$base',
  '&&::after': {
    content: '(edited)',
  },
})

const Date = styled('div', {
  display: 'inline-block',
  baselineFontSize: ['small'],
  marginRight: '$space4',
})

const onlyEmoji = (body: string): boolean =>
  /\p{Extended_Pictographic}+/gu.test(body) && body.replace(/\p{Extended_Pictographic}+/gu, '').trim().length <= 1

export const MessageContent: React.FC = () => {
  const { type } = useMessageListContext()
  const { message, user, quote, isEditing, quoteUser, isThreadedReply } = useMessageContext()

  if (!message) {
    return null
  }
  return (
    <>
      <MessageTitle>
        <MessageUsername>{user?.name || ((<Placeholder width={1} />) as BaselineElement)}</MessageUsername>
        <Date>{(<Timestamp epoch={datetime.toEpoch(message.sentAt)} relative />) as BaselineElement}</Date>
        {!isThreadedReply && !(type === 'thread') && quoteUser && quote ? (
          <Quote>
            {(<strong>{quoteUser.name}</strong>) as BaselineElement}
            {quote}
          </Quote>
        ) : null}
      </MessageTitle>
      {/*
      // @ts-expect-error baseline here might be off in the isEditing case - should review this in more detail */}
      <MessageBody onlyEmoji={onlyEmoji(message.body)} isEditing={isEditing}>
        {isEditing ? <MessageEditField /> : message.body}
        {message.edited && <EditedLabel />}
      </MessageBody>
    </>
  )
}
