import indexeddbStorage from '@piotr-cz/redux-persist-idb-storage'
import { configureStore, createSerializableStateInvariantMiddleware } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistCombineReducers,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'reduxjs-toolkit-persist'
import stateReconciler from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1'
import defaultStorage from 'reduxjs-toolkit-persist/lib/storage'
import { PersistConfig } from 'reduxjs-toolkit-persist/lib/types'
import messageLists, { resetChatLoadingState } from 'slices/message-lists'
import commandPalette from 'slices/command-palette'
import discussionList from 'slices/discussion-list'
import discussionMessages from 'slices/discussion-messages'
import focus from 'slices/focus'
import reminders from 'slices/reminders'
import session from 'slices/session'
import undotree from 'slices/undo-tree'
import users from 'slices/users'

const reducers = {
  messageLists,
  commandPalette,
  discussionList,
  discussionMessages,
  focus,
  reminders,
  session,
  undotree,
  users,
}

const storage = globalThis.indexedDB
  ? indexeddbStorage({
      name: 'superhuman',
      storeName: 'act2',
    })
  : defaultStorage
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const config: PersistConfig<any> = {
  version: 1,
  key: 'superhuman',
  storage,
  stateReconciler,
  serialize: false,
  deserialize: false,
  whitelist: [
    'sidebar',
    'messages',
    'focusRegion',
    'threads',
    'users',
    'reminders',
    'session',
    'channels',
    'focus',
    'discussionList',
    'discussionMessages',
  ],
  transforms: [resetChatLoadingState],
}
const persistedReducers = persistCombineReducers(config, reducers)

const ignoredActions = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
const ignoredActionsMiddleware = createSerializableStateInvariantMiddleware({ ignoredActions })
const logger = createLogger({
  duration: true,
  collapsed: true,
})
const basicStore = configureStore({
  reducer: reducers,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(ignoredActionsMiddleware).concat(logger),
})
const persistentStore = configureStore({
  reducer: persistedReducers,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(ignoredActionsMiddleware).concat(logger),
})
export const persistor = persistStore(persistentStore)
export const store = process.env.REACT_APP_ENABLE_PERSISTENCY ? persistentStore : basicStore
export type RootState = ReturnType<typeof basicStore.getState>
export type AppDispatch = typeof basicStore.dispatch
