import { styled } from '@stitches/react'
import { DiscussionRow } from 'components/DiscussionRow'
import { useDispatch } from 'hooks/use-dispatch'
import { useSelector } from 'hooks/use-selector'
import { useEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { getDiscussionListFocus, getNextItemId, getThreadListByChannelId } from 'selectors/discussion-list'
import { setFocusOnDiscussionThreadById } from 'slices/focus'

type Props = {
  channelId: string
}

const Wrapper = styled('div', {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
})

export default function DiscussionList(props: Props) {
  const dispatch = useDispatch()

  const threadIds = useSelector(s => getThreadListByChannelId(s, props.channelId))
  const currentFocus = useSelector(getDiscussionListFocus)
  const prevItemId = useSelector(s => getNextItemId(s, props.channelId, -1))
  const nextItemId = useSelector(s => getNextItemId(s, props.channelId))
  // todo: (PB): fix this
  const enabled = true

  useEffect(() => {
    if (currentFocus.threadId === undefined && threadIds[0]) {
      dispatch(setFocusOnDiscussionThreadById({ id: threadIds[0] }))
    }
  }, [currentFocus, threadIds, dispatch])

  useHotkeys('k, up', previous, { enabled }, [prevItemId])
  useHotkeys('j, down', next, { enabled }, [nextItemId])

  function previous(e: KeyboardEvent) {
    e.preventDefault()

    if (prevItemId) {
      dispatch(setFocusOnDiscussionThreadById({ id: prevItemId }))
    }
  }

  function next(e: KeyboardEvent) {
    e.preventDefault()

    if (nextItemId) {
      dispatch(setFocusOnDiscussionThreadById({ id: nextItemId }))
    }
  }
  return (
    <Wrapper>
      {threadIds.map(id => (
        <DiscussionRow key={id} threadId={id} isSelected={enabled && currentFocus.threadId === id} />
      ))}
    </Wrapper>
  )
}
