import { deleteMessage } from 'actions/messages'
import { archiveReminder } from 'actions/reminders'
import { useCommand } from 'contexts/CommandPalette'
import { useMessageListContext } from 'contexts/MessageList'
import { useDispatch } from 'hooks/use-dispatch'
import { useReminders } from 'hooks/use-reminders'
import React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { PageFocusRegion, setFocusRegion } from 'slices/focus'
import { setFocusOnItem } from 'slices/message-lists'
import { useMessageContext } from '.'

export const MessageShortcuts: React.FC = () => {
  const { id: messageListId, type, focusNextMessage } = useMessageListContext()
  const { jumpToItem, openInThread, isEditable, focusMessage, message, reminder } = useMessageContext()
  const dispatch = useDispatch()

  useCommand(
    {
      title: 'Reply',
      shortcut: 'enter',
      target: message,
      handler: openInThread,
      enabled: !!message,
      score: 100,
    },
    [openInThread]
  )
  useHotkeys('r, right', openInThread, { enabled: !!message }, [message?.id])

  const focusMainPane = React.useCallback(() => {
    dispatch(setFocusRegion(PageFocusRegion.Channel))
  }, [dispatch])
  useHotkeys('left', focusMainPane, {}, [message?.id])

  const edit = React.useCallback(() => {
    if (!isEditable || !message?.id) {
      return
    }
    focusMessage({ editing: true })
  }, [focusMessage, message, isEditable])
  useCommand({
    title: 'Edit message',
    enabled: isEditable,
    target: message,
    handler: edit,
    shortcut: '.',
    score: 100,
  })

  const remove = React.useCallback(() => {
    if (!isEditable || !message) {
      return
    }
    focusNextMessage()
    dispatch(deleteMessage({ messageListId, message }))
  }, [dispatch, message, isEditable, focusNextMessage, messageListId])
  useCommand({
    title: 'Delete message',
    enabled: isEditable,
    target: message,
    handler: remove,
    score: 100,
  })

  useHotkeys('o', jumpToItem, { keydown: true, enabled: !!reminder }, [reminder?.id])
  useHotkeys('enter', openInThread, { keydown: true, enabled: !!reminder }, [reminder?.id])

  const { openReminderMenu } = useReminders({ messageListId, type })

  const createReminderForSelectedMessage = React.useCallback(
    (e?: KeyboardEvent) => {
      e?.preventDefault()
      openReminderMenu(message)
    },
    [openReminderMenu, message]
  )
  useCommand({
    title: 'Remind me',
    target: message,
    enabled: !!message,
    handler: createReminderForSelectedMessage,
    shortcut: 'h',
    disableShortcutFromInputs: true,
    score: 100,
  })

  const archiveSelectedReminder = React.useCallback((): void => {
    if (reminder) {
      dispatch(archiveReminder(reminder.id))
      dispatch(setFocusOnItem({ messageListId, editing: false }))
    }
  }, [dispatch, reminder, messageListId])
  useCommand({
    title: 'Archive reminder',
    enabled: !!reminder,
    target: message,
    handler: archiveSelectedReminder,
    shortcut: 'e',
    disableShortcutFromInputs: true,
    score: 100,
  })
  // todo: simplify aliases
  useCommand({
    title: 'Remove reminder',
    enabled: !!reminder,
    target: message,
    handler: archiveSelectedReminder,
    disableShortcutFromInputs: true,
    score: 90,
  })

  const createReminderForSelectedReminder = React.useCallback(() => {
    const focusedReminder = reminder
    const focusedMessage = message
    if (!focusedReminder || !focusedMessage) {
      return
    }
    openReminderMenu(message || reminder)
  }, [reminder, message, openReminderMenu])
  useCommand({
    title: 'Remind me another time',
    enabled: !!reminder,
    target: message,
    handler: createReminderForSelectedReminder,
    score: 100,
  })

  return null
}
