import * as React from 'react'

type Props = React.SVGProps<SVGSVGElement>
export const SettingsIcon: React.FC<Props> = (props: Props) => (
  <svg width='1em' height='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M7 0c-.3 0-.627.022-.916.06l-.692.09-.39 1.729c-.07.027-.141.057-.21.087L3.294 1.02l-.553.425A7.498 7.498 0 0 0 1.445 2.74l-.425.553.946 1.498c-.03.069-.06.14-.087.21l-1.729.39-.09.692A7.522 7.522 0 0 0 0 7c0 .3.022.627.06.916l.09.692 1.729.39c.027.07.057.141.087.21l-.946 1.498.425.553c.361.468.828.934 1.296 1.296l.553.425 1.498-.946c.069.03.14.06.21.087l.39 1.729.692.09c.29.038.617.06.916.06.3 0 .627-.022.916-.06l.692-.09.39-1.729c.07-.027.141-.057.21-.087l1.498.946.553-.425a7.497 7.497 0 0 0 1.296-1.296l.425-.553-.946-1.498c.03-.069.06-.14.087-.21l1.729-.39.09-.692c.038-.29.06-.617.06-.916 0-.3-.022-.627-.06-.916l-.09-.692-1.729-.39a6.34 6.34 0 0 0-.087-.21l.946-1.498-.425-.553a7.497 7.497 0 0 0-1.296-1.296l-.553-.425-1.498.946c-.069-.03-.14-.06-.21-.087L8.608.15 7.916.06A7.522 7.522 0 0 0 7 0ZM2.647 5.853c.105-.397.262-.773.465-1.12L2.238 3.35c.32-.418.694-.792 1.112-1.112l1.383.874c.347-.203.723-.36 1.12-.465l.361-1.596a6.056 6.056 0 0 1 1.572 0l.36 1.596c.398.105.774.262 1.12.465l1.384-.874c.418.32.792.694 1.112 1.112l-.874 1.383c.203.347.36.723.465 1.12l1.596.361a6.066 6.066 0 0 1 0 1.572l-1.596.36a4.473 4.473 0 0 1-.465 1.12l.874 1.384a6.03 6.03 0 0 1-1.112 1.112l-1.383-.874c-.347.203-.723.36-1.12.465l-.361 1.596a6.066 6.066 0 0 1-1.572 0l-.36-1.596a4.473 4.473 0 0 1-1.12-.465l-1.384.874a6.033 6.033 0 0 1-1.112-1.112l.874-1.383a4.473 4.473 0 0 1-.465-1.12l-1.596-.361a6.056 6.056 0 0 1 0-1.572l1.596-.36ZM7 10a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z'
      fill='inherit'
    />
  </svg>
)
