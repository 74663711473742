import { ChannelTabs } from 'components/ChannelTabs'
import { MessageList } from 'components/MessageList'
import DiscussionList from 'components/DiscussionList'
import { MessageComposeField } from 'components/MessageComposeField'
import { MessageListContext } from 'contexts/MessageList'
import { useDispatch } from 'hooks/use-dispatch'
import { useSelector } from 'hooks/use-selector'
import React, { useEffect } from 'react'
import { getSelectedChannelId } from 'selectors/channels'
import { ChannelTab } from 'slices/message-lists'
import { MessageListType } from 'services/message-lists'
import { loadInitialData } from 'actions/message-lists'
import { styled } from 'themes'

const Wrapper = styled('div', {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  overflow: 'hidden',
})

export const Channel: React.FC = () => {
  const dispatch = useDispatch()
  const channelId = useSelector(getSelectedChannelId)
  const channel = useSelector(s => s.messageLists[channelId])
  const isFocusOnChat = channel?.focus.tab === ChannelTab.Chat

  useEffect(() => {
    dispatch(loadInitialData(channelId))
  }, [dispatch, channelId])

  return (
    <Wrapper>
      <MessageListContext messageListId={channelId} type={MessageListType.Channel}>
        <ChannelTabs />
        {isFocusOnChat ? (
          <>
            <MessageList />
            <MessageComposeField placeholder={`Message #${channel.name}`} />
          </>
        ) : (
          <DiscussionList channelId={channelId} />
        )}
      </MessageListContext>
    </Wrapper>
  )
}
