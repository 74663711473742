import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const name = 'CommandPalette'

type State = {
  isOpen: boolean
}

export const initialState: State = {
  isOpen: false,
}

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    setAsOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload
    },
  },
})

export const { setAsOpen } = slice.actions
export default slice.reducer
