export const deleteDatabase = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const DBDeleteRequest = window.indexedDB.deleteDatabase('superhuman')
    DBDeleteRequest.onerror = function () {
      reject(new Error('Error deleting database'))
    }
    DBDeleteRequest.onsuccess = function (event) {
      resolve()
    }
  })
}
