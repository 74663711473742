import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Reminder } from 'services/reminders'

export const initialState: Record<string, Reminder> = {}

export const slice = createSlice({
  name: 'reminders',
  initialState,
  reducers: {
    setReminders: (state, { payload: reminders }: PayloadAction<Reminder[]>) => {
      reminders.forEach(m => {
        state[m.id] = m
      })
    },
    removeReminder: (state, { payload: reminder }: PayloadAction<Reminder>) => {
      delete state[reminder.id]
    },
    removeReminders: (state, { payload: reminders }: PayloadAction<Reminder[]>) => {
      reminders.forEach(m => {
        delete state[m.id]
      })
    },
  },
})

export const { setReminders, removeReminder, removeReminders } = slice.actions
export default slice.reducer
