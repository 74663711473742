import * as React from 'react'

type Props = React.SVGProps<SVGSVGElement>
export const MoreIcon: React.FC<Props> = (props: Props) => (
  <svg width='1em' height='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g opacity={0.65} strokeWidth={0.705} strokeLinecap='round' strokeLinejoin='round'>
      <path d='M10 6.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM6.5 6.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM3 6.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z' />
    </g>
  </svg>
)
