import { AppDispatch, RootState } from 'root-state'
import { triggerReminder } from 'actions/reminders'
import datetime, { DateTime } from 'utils/datetime'
import { store } from 'root-state'
import * as firebase from 'services/firebase'
import { MessageListType } from 'services/message-lists'

export type Reminder = {
  id: string
  messageListId: string
  messageListType: MessageListType
  chatMessageId: string
  remindAt: DateTime
  createdAt: DateTime
  archivedAt?: DateTime | null
  triggeredAt?: DateTime | null
}

const polls: Record<string, NodeJS.Timeout> = {}
// todo: (PB): add this to root component, not raw js
const beginPolling = () => {
  const state: RootState = store.getState()
  const reminders = Object.values(state.reminders)
  reminders.forEach(queueReminderNotification)
}
const queueReminderNotification = (reminder: Reminder) => {
  const dispatch: AppDispatch = store.dispatch
  if (polls[reminder.id]) {
    clearTimeout(polls[reminder.id])
  }
  if (reminder.remindAt && !reminder.triggeredAt) {
    const timeLeft = datetime.toEpoch(reminder.remindAt) - datetime.toEpoch(datetime.now())
    polls[reminder.id] = setTimeout(() => {
      dispatch(triggerReminder(reminder.id))
    }, timeLeft)
  }
}
beginPolling()

export const createReminder = async ({
  orgId,
  userId,
  reminder,
}: {
  orgId: string
  userId: string
  reminder: {
    messageId: string
    messageListId: string
    type: string
    remindAt: DateTime
  }
}) => {
  const created = await firebase.add(`orgs/${orgId}/users/${userId}/reminders`, {
    chatMessageId: reminder.messageId,
    messageListType: reminder.type,
    messageListId: reminder.messageListId,
    createdAt: datetime.toFirebaseTime(datetime.now()),
    remindAt: datetime.toFirebaseTime(reminder.remindAt),
  })
  return created
}

export const getReminder = async ({
  orgId,
  userId,
  reminderId,
}: {
  orgId: string
  userId: string
  reminderId: string
}) => {
  const result = await firebase.getSnapshot(`orgs/${orgId}/users/${userId}/reminders/${reminderId}`)
  if (!result) {
    return
  }
  // @ts-expect-error not important
  return rowToReminder(result)
}

export const updateReminder = async ({
  orgId,
  userId,
  reminderId,
  updates,
}: {
  orgId: string
  userId: string
  reminderId: string
  updates: Reminder
}) => {
  await firebase.set(`orgs/${orgId}/users/${userId}/reminders/${reminderId}`, {
    ...updates,
    createdAt: datetime.toFirebaseTime(updates.createdAt),
    remindAt: datetime.toFirebaseTime(updates.remindAt),
    archivedAt: updates.archivedAt ? datetime.toFirebaseTime(updates.archivedAt) : null,
  })
  queueReminderNotification(updates)
}

type Updates = {
  added: Reminder[]
  updated: Reminder[]
  removed: Reminder[]
}
export const listenForUpdates = async (
  { orgId, userId }: { orgId: string; userId: string },
  callback: (error: Error | null, updates: Updates) => void
) => {
  return firebase.subscribe(
    {
      path: `orgs/${orgId}/users/${userId}/reminders`,
    },
    (error?: Error, updates?: firebase.Update[]) => {
      if (error) {
        return callback(error, {
          added: [],
          updated: [],
          removed: [],
        })
      }

      if (!updates) {
        return callback(null, {
          added: [],
          updated: [],
          removed: [],
        })
      }
      const added = updates
        .filter(update => update.type === firebase.UpdateType.Added)
        .map(update => rowToReminder(update.doc))
      const updated = updates
        .filter(update => update.type === firebase.UpdateType.Modified)
        .map(update => rowToReminder(update.doc))
      const removed = updates
        .filter(update => update.type === firebase.UpdateType.Removed)
        .map(update => rowToReminder(update.doc))
      ;[...added, ...updated, ...removed].forEach(queueReminderNotification)
      return callback(null, {
        added,
        updated,
        removed,
      })
    }
  )
}

const rowToReminder = (row: firebase.QueryDocumentSnapshot<firebase.DocumentData>): Reminder => {
  const data = row.data()
  return {
    id: row.id,
    ...data,
    createdAt: datetime.fromFirebaseTime(data.createdAt),
    remindAt: datetime.fromFirebaseTime(data.remindAt),
    archivedAt: data.archivedAt ? datetime.fromFirebaseTime(data.archivedAt) : null,
  } as Reminder
}
