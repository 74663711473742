import { ArchiveIcon } from 'components/Icons/Archive'
import { CloseIcon } from 'components/Icons/Close'
import { CommandIcon } from 'components/Icons/Command'
import { CommandCircledIcon } from 'components/Icons/CommandCircled'
import { DoneIcon } from 'components/Icons/Done'
import { EmojiIcon } from 'components/Icons/Emoji'
import { HashtagIcon } from 'components/Icons/Hashtag'
import { InboxIcon } from 'components/Icons/Inbox'
import { LeftArrowHeadIcon } from 'components/Icons/LeftArrowHead'
import { MoreIcon } from 'components/Icons/More'
import { RemindIcon } from 'components/Icons/Remind'
import { ReplyIcon } from 'components/Icons/Reply'
import { RightArrowHeadIcon } from 'components/Icons/RightArrowHead'
import { SearchIcon } from 'components/Icons/Search'
import { SentIcon } from 'components/Icons/Sent'
import { SettingsIcon } from 'components/Icons/Settings'
import React from 'react'

const icons = {
  archive: ArchiveIcon,
  close: CloseIcon,
  command: CommandIcon,
  commandCircled: CommandCircledIcon,
  remind: RemindIcon,
  emoji: EmojiIcon,
  reply: ReplyIcon,
  hashtag: HashtagIcon,
  done: DoneIcon,
  more: MoreIcon,
  inbox: InboxIcon,
  sent: SentIcon,
  rightArrowHead: RightArrowHeadIcon,
  leftArrowHead: LeftArrowHeadIcon,
  settings: SettingsIcon,
  search: SearchIcon,
} as const

export type IconName = keyof typeof icons

type Props = React.SVGProps<SVGSVGElement> & {
  name: IconName
}
const style = {
  verticalAlign: 'middle',
  position: 'relative',
} as const

export const Icon: React.FC<Props> = (props: Props) => {
  const IconComponent = icons[props.name]
  if (IconComponent) {
    return <IconComponent style={style} {...props} />
  }

  return <CommandIcon style={style} {...props} />
}
