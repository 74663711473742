import { Message } from 'services/messages'
import datetime from 'utils/datetime'
import * as firebase from 'services/firebase'

export enum MessageListType {
  Channel = 'channel',
  Thread = 'thread',
  // Discussion = 'discussion',
}

const rowToMessage = (row: firebase.QueryDocumentSnapshot<firebase.DocumentData>) =>
  ({
    id: row.id,
    ...row.data(),
    sentAt: datetime.fromFirebaseTime(row.data().sentAt),
    updatedAt: row.data().updatedAt ? datetime.fromFirebaseTime(row.data().updatedAt) : undefined,
  } as Message)

type Updates = {
  added: Message[]
  updated: Message[]
  removed: Message[]
}

export type MessageList = {
  id: string
  name: string
  description: string
  workspaceId: string
  createdBy: string
  isPublic: boolean
  isDirectMessage: boolean
}

export const getAllChannels = async ({ workspaceId }: { workspaceId: string }): Promise<MessageList[]> => {
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_ORIGIN}/~backend/v1/workspaces/${workspaceId}/channels`,
    {
      method: 'GET',
      credentials: 'include',
    }
  )
  const { channels } = await response.json()
  return channels || []
}

export const listenForUpdates = async (
  {
    orgId,
    messageListId,
    type,
    newestMessageId,
  }: { orgId: string; messageListId: string; type: MessageListType; newestMessageId?: string },
  callback: (error: Error | null, updates: Updates) => void
) => {
  let endAt: firebase.DocumentData | undefined
  if (newestMessageId) {
    endAt = await firebase.getSnapshot(`orgs/${orgId}/chatMessages/${newestMessageId}`)
  }
  let key: string
  if (type === 'channel') {
    key = 'channelId'
  } else {
    key = 'threadId'
  }
  return firebase.subscribe(
    {
      path: `orgs/${orgId}/chatMessages`,
      where: [{ field: key, op: '==', value: messageListId }],
      orderBy: { field: 'sentAt', desc: true },
      endAt,
    },
    (error?: Error, updates?: firebase.Update[]) => {
      if (error) {
        console.error('subscription error', error)
        return callback(error, {
          added: [],
          updated: [],
          removed: [],
        })
      }

      if (!updates) {
        return callback(null, {
          added: [],
          updated: [],
          removed: [],
        })
      }
      const added = updates
        .filter(update => update.type === firebase.UpdateType.Added && update.id !== newestMessageId)
        .map(update => rowToMessage(update.doc))
      const updated = updates
        .filter(update => update.type === firebase.UpdateType.Modified)
        .map(update => rowToMessage(update.doc))
      const removed = updates
        .filter(update => update.type === firebase.UpdateType.Removed)
        .map(update => rowToMessage(update.doc))
      return callback(null, {
        added,
        updated,
        removed,
      })
    }
  )
}
