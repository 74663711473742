export const spaceAndSize = {
  thinBorder: 'var(--borderWidths-thin)',
  negativeThinBorder: 'calc(-1 * var(--borderWidths-thin))',
  space1: '4px',
  space2: '8px',
  space3: '12px',
  space4: '16px',
  space5: '20px',
  space6: '24px',
  space7: '28px',
  space8: '32px',
  space9: '36px',
  space10: '40px',
  space11: '44px',
  space12: '48px',
  space16: '64px',
  space18: '72px',
  commandPaletteWidth: '680px',
}

export const fontSizes = {
  small: '12px',
  base: '14px',
  large: '16px',
  xlarge: '21px',
  emoji: '24px',
}
export const lineHeights = {
  tight: '1em',
  base: '1.25em',
  normal: '1.5em',
}

type FontSizeName = keyof typeof fontSizes
type LineHeightName = keyof typeof lineHeights
// @ts-expect-error about to build the values programatically
const baselineFontOffsets: BaselineOffsets = {}
type FontMeasurement = { ascent: number; descent: number }
const ADELLE: FontMeasurement = {
  ascent: 880,
  descent: -370,
}
const getBaselineOffset = (font: FontMeasurement, fontSize: number, lineHeight: number) => {
  return (
    (Math.floor((font.ascent / 1000) * fontSize) -
      Math.ceil((lineHeight - ((font.ascent - font.descent) / 1000) * fontSize) / 2) +
      4) %
    4
  )
}
/**
 * dictionary that helps us maintain baseline across various font and line heights
 *  [fontSizeName]: {
 *    [lineHeightName]: {
 *      lineHeight,
 *      marginTop,
 *      marginBottom
 *    }
 *  }
 * see also, this ace comment from desktop:
 * https://github.com/superhuman/desktop/blob/2adcb3b29d928d5e9b6a712d90d8f53d98887340/app/style/_variables.scss#L762
 *
 * note:
 * the sum of the margins and lineheight for any given font/line height combination
 * **must sum to 0 mod 4**
 * this ensures sibling and ancestor also remain baseline
 *
 */
type BaselineOffsets = Record<
  FontSizeName,
  Record<
    LineHeightName,
    {
      lineHeight: string
      transform: string
    }
  >
>
Object.keys(fontSizes).forEach(fs => {
  const fontSizeName = fs as FontSizeName
  const fontSize = parseFloat(fontSizes[fontSizeName])
  Object.keys(lineHeights).forEach(lh => {
    const lineHeightName = lh as LineHeightName
    const lineHeight = parseFloat(lineHeights[lineHeightName])
    const roundedLineHeight = Math.ceil((lineHeight * fontSize) / 4) * 4
    const bottomOffset = getBaselineOffset(ADELLE, fontSize, roundedLineHeight)
    baselineFontOffsets[fontSizeName] = baselineFontOffsets[fontSizeName] || {}
    baselineFontOffsets[fontSizeName][lineHeightName] = {
      lineHeight: `${roundedLineHeight}px`,
      transform: `translateY(${bottomOffset}px)`,
    }
  })
})

export const baselineFontSize = ([fontSize, lineHeight = 'base']: [FontSizeName] | [FontSizeName, LineHeightName]) => {
  const adjusted = baselineFontOffsets[fontSize][lineHeight]
  return {
    fontSize: `$${fontSize}`,
    position: 'relative',
    ...adjusted,
  }
}
