import arrayUniq from 'array-uniq'
import { RootState } from 'root-state'
import { getDiscussionMessageById } from 'selectors/discussion-messages'
import { getUserById } from 'selectors/users'
import { User } from 'services/users'
import { DateTime } from 'utils/datetime'

export type DiscussionListFocus = {
  threadId?: string
}

export const getDiscussionListFocus = (state: RootState): DiscussionListFocus =>
  state.focus.channel.discussionList || {}

export const getThreadListByChannelId = (state: RootState, channelId: string): string[] =>
  state.discussionList.threadsByChannel[channelId] || []

export const getTotalThreadCountByChannelId = (state: RootState, channelId: string): number =>
  state.discussionList.totalThreadsByChannel[channelId] || 0

export const getMessagesByThreadId = (state: RootState, threadId: string): string[] =>
  [threadId].concat(state.discussionList.messagesByThread[threadId])

export const getUsersInDiscussion = (state: RootState, threadId: string): (User | undefined)[] =>
  arrayUniq(
    getMessagesByThreadId(state, threadId)
      .map(id => getDiscussionMessageById(state, id))
      .map(msg => getUserById(state, msg?.userId))
  )

export const getNextItemId = (state: RootState, channelId: string, jumpBy?: number): string | undefined => {
  const threads = getThreadListByChannelId(state, channelId)
  const currentSelectedId = getDiscussionListFocus(state).threadId
  const currentIndex = threads.indexOf(currentSelectedId || '')
  const nextIndex = currentIndex + (jumpBy === undefined ? 1 : jumpBy)

  if (nextIndex >= threads.length || nextIndex < 0) {
    return
  }

  return threads[nextIndex]
}

export const getLastMessageIdByThreadId = (state: RootState, threadId: string): string | undefined =>
  getMessagesByThreadId(state, threadId).slice(-1)[0]

export const getThreadGistById = (state: RootState, threadId: string): string | undefined => {
  const lastMessageId = getLastMessageIdByThreadId(state, threadId)
  const lastMessage = getDiscussionMessageById(state, lastMessageId || '')

  if (!lastMessage) return undefined

  return lastMessage.body.replace(/\n/, '').split(/\s+/).slice(0, 10).join(' ')
}

export const getLastUpdatedAtByThreadId = (state: RootState, threadId: string): DateTime | undefined => {
  const lastMessageId = getLastMessageIdByThreadId(state, threadId)
  const lastMessage = getDiscussionMessageById(state, lastMessageId || '')

  if (!lastMessage) return undefined

  return lastMessage.sentAt
}
