import { Channel as Content } from 'components/Channel'
import { useDispatch } from 'hooks/use-dispatch'
import { useSelector } from 'hooks/use-selector'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getSelectedChannelId } from 'selectors/channels'
import { setSelectedChannelId } from 'slices/focus'
import { Navigation } from 'components/Navigation'
import { Sidebar } from 'components/Sidebar'
import { Topbar } from 'components/Topbar'
import { styled } from 'themes'
import { State, useAuthContext } from 'contexts/Auth'

const Panes = styled('div', {
  panel: 1,
  height: '100%',
  zIndex: 1,
  overflow: 'hidden',
  flex: '1 1 auto',
  '@narrow': {
    display: 'flex',
    flexDirection: 'column',
  },
})

export const Channel: React.FC = () => {
  const { state, user } = useAuthContext()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const params = useParams()
  const channelId = useSelector(getSelectedChannelId)

  useEffect(() => {
    if (params.channelId && params.channelId !== channelId) {
      dispatch(setSelectedChannelId({ id: params.channelId }))
    }
  }, [dispatch, channelId, params.channelId])
  useEffect(() => {
    if (state === State.Loading) {
      return
    }
    if (!user) {
      navigate('/signin')
    }
  }, [navigate, user, state])

  if (state === State.Loading) {
    return null
  }
  return (
    <>
      <Topbar />
      <Panes>
        <Navigation />
        <Content />
        <Sidebar />
      </Panes>
    </>
  )
}
