import datetime from 'utils/datetime'
import * as firebase from 'services/firebase'
import * as fbauth from 'firebase/auth'
const authProvider = new fbauth.GoogleAuthProvider()

export type Profile = {
  workspaceId: string
  profileId: string
  workspaceName: string
  websocketEndpoint: string
}
export type User = {
  id: string
  name: string
  firstName?: string
  familyName?: string
  email?: string
  picture?: string
}
export type CurrentUser = User & {
  profiles: Profile[]
}

export const signIn = async (workspaceId: string, credential: string): Promise<CurrentUser | null> => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/~backend/v1/sessions`, {
    method: 'POST',
    headers: new Headers({ Authorization: `Bearer ${credential}` }),
    credentials: 'include',
  })
  const json = await response.json()
  const user: CurrentUser = {
    id: json.userId,
    name: json.name,
    firstName: json.firstName,
    familyName: json.familyName,
    picture: json.picture,
    email: json.email,
    profiles: json.profiles,
  }
  if (!user || !user?.email?.endsWith('@superhuman.com')) {
    signOut()
    return null
  }

  // legacy (firebase):
  // save the user credentials to firebase for profile looks from other devices
  // note:
  // temporarily, GET /users/me is missing oidc claims for name, email, or picture
  // this firebase data will be the only place we have them saved
  await firebase.set(`orgs/superhuman/profiles/${user.id}`, {
    id: user.id,
    name: user.name || '',
    email: user.email,
    picture: user.picture,
  })

  return user
}

export const getCurrentUser = async (): Promise<CurrentUser | null> => {
  const response = await fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/~backend/v1/users/me`, {
    method: 'GET',
    credentials: 'include',
  })
  const json = await response.json()
  const user = json as CurrentUser

  // legacy (firebase):
  // temporarily, GET /users/me is missing oidc claims for name, email, or picture
  // retrieve this data from firebase and mix them together
  // see above in signIn()
  const userData = await firebase.get(`orgs/superhuman/profiles/${json.userId}`)
  return {
    ...user,
    ...userData,
  } as CurrentUser
}

// legacy (firebase):
export const LOCAL_STORAGE_FIREBASE_KEY = 'firebase'
export const signInWithFirebase = async (): Promise<void> => {
  if (localStorage.getItem(LOCAL_STORAGE_FIREBASE_KEY)) {
    return
  }
  const auth = fbauth.getAuth()
  if (auth.currentUser !== null) {
    return
  }
  // https://firebase.google.com/docs/auth/web/google-signin
  const result = await fbauth.getRedirectResult(auth)
  if (!result) {
    fbauth.signInWithRedirect(auth, authProvider)
    return
  }
  localStorage.setItem(LOCAL_STORAGE_FIREBASE_KEY, 'success')
  return
}

export const signOut = async (): Promise<void> => {
  // legacy (firebase):
  await fbauth.getAuth().signOut()
  await fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/~backend/v1/sessions`, {
    method: 'DELETE',
    credentials: 'include',
  })
  localStorage.clear()
}

export const fetchUsers = async ({ orgId, userIds }: { orgId: string; userIds: string[] }) => {
  if (userIds.length === 0) {
    return []
  }
  const pageSize = 10
  const users: User[] = []
  for (let i = 0; i < userIds.length; i += pageSize) {
    const subset = userIds.slice(i, i + pageSize)
    const result = await firebase.fetch({
      path: `/orgs/${orgId}/profiles`,
      where: [{ field: 'id', op: 'in', value: subset }],
    })
    users.push(...result.docs.map(firebaseDocumentToUser))
  }
  return users
}

const firebaseDocumentToUser = (row: firebase.QueryDocumentSnapshot<firebase.DocumentData>) =>
  ({
    id: row.id,
    name: row.data().name,
    email: 'missing_from_firebase@superhuman.com',
    firstName: row.data().name.split(' ')[0] || '',
    familyName: row.data().name.split(' ')[1] || '',
    picture: row.data().photoUrl || row.data().picture,
    lastSyncedAt: datetime.now(),
    profiles: [],
  } as User)
