import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DiscussionListFocus } from 'selectors/discussion-list'
import { Message } from 'services/messages'
import { MessageListType } from 'services/message-lists'

export enum PageFocusRegion {
  Channel = 'channel',
  Thread = 'thread',
}
export type PaneFocus = {
  messageListId?: string
  listType: MessageListType
  discussionList: DiscussionListFocus
}
type ChannelFocusHistory = Omit<PaneFocus, 'channelId'>
export type PageFocus = {
  region: PageFocusRegion
  channel: PaneFocus
  focusHistory: Record<string, ChannelFocusHistory | undefined>
  sidebar: PaneFocus
}
export const initialState: PageFocus = {
  region: PageFocusRegion.Channel,
  channel: {
    messageListId: 'welcome',
    listType: MessageListType.Channel,
    discussionList: {},
  },
  sidebar: {
    messageListId: undefined,
    listType: MessageListType.Thread,
    discussionList: {},
  },
  focusHistory: {},
}

export const slice = createSlice({
  name: 'focus',
  initialState,
  reducers: {
    setPageFocus: (state, action: PayloadAction<Partial<PageFocus>>) => {
      Object.assign(state, action.payload)
    },
    setFocusRegion: (state, action: PayloadAction<PageFocusRegion>) => {
      state.region = action.payload
    },
    setFocusRegionToChannel: state => {
      state.region = PageFocusRegion.Channel
    },
    setFocusRegionToThread: state => {
      if (state.sidebar.messageListId) {
        state.region = PageFocusRegion.Thread
      }
    },
    togglePageFocusRegion: (state, action: PayloadAction) => {
      state.region = state.region === PageFocusRegion.Channel ? PageFocusRegion.Thread : PageFocusRegion.Channel
    },
    openThreadForMessage: (state, { payload: message }: PayloadAction<Message | null>) => {
      const threadId = message?.threadId || message?.id
      if (threadId) {
        state.region = PageFocusRegion.Thread
        state.sidebar.messageListId = threadId
      }
    },
    setSelectedChannelId: (state, { payload: { id } }: PayloadAction<{ id: string }>) => {
      state.channel.messageListId = id
      const priorChannelState = state.focusHistory[id]
      Object.assign(
        state.channel,
        {
          chat: {
            composeField: true,
            editing: false,
          },
          discussionList: {},
        },
        priorChannelState,
        {
          channelId: id,
        }
      )
    },

    setFocusOnDiscussionThreadById: (state, { payload: { id } }: PayloadAction<{ id: string }>) => {
      // todo: (PB): merge this in with other message lists
      // state.channel.tab = ChannelTab.Discussion
      // state.channel.discussionList = {
      //   threadId: id,
      // }
      // saveListStateForLater(state, state.channel.messageListId || '')
    },
  },
})

export const {
  setPageFocus,
  setFocusRegion,
  setFocusRegionToChannel,
  setFocusRegionToThread,
  togglePageFocusRegion,
  setSelectedChannelId,
  openThreadForMessage,
  setFocusOnDiscussionThreadById,
} = slice.actions
export default slice.reducer
