import * as React from 'react'

type Props = React.SVGProps<SVGSVGElement>
export const HashtagIcon: React.FC<Props> = (props: Props) => (
  <svg width='1em' height='1em' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.842 8.127a.35.35 0 1 0 .683.152l.47-2.107H5.34l-.435 1.955a.35.35 0 0 0 .683.152l.47-2.107h2.164a.35.35 0 1 0 0-.7H6.213l.458-2.054h1.98a.35.35 0 1 0 0-.7H6.827l.51-2.292a.35.35 0 1 0-.683-.152L6.11 2.718H3.764l.51-2.292a.35.35 0 1 0-.683-.152l-.544 2.444H.78a.35.35 0 1 0 0 .7H2.89l-.457 2.054H.35a.35.35 0 1 0 0 .7h1.928l-.436 1.955Zm1.309-2.655h2.345l.458-2.054H3.608l-.457 2.054Z'
      // eslint-disable-next-line react/prop-types
      fill={props.fill}
    />
  </svg>
)
