import * as React from 'react'

type Props = React.SVGProps<SVGSVGElement>
export const SentIcon: React.FC<Props> = (props: Props) => (
  <svg width='1em' height='1em' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <g clipPath='url(#a)' stroke='inherit' strokeWidth={0.705} strokeLinecap='round' strokeLinejoin='round'>
      <path d='M9.25 3.333V8.75h-7.5V3.333M4.667 5h1.667M10.084 1.25H.918v2.083h9.166V1.25Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='white' transform='translate(.5)' d='M0 0h10v10H0z' />
      </clipPath>
    </defs>
  </svg>
)
