import { createStitches } from '@stitches/react'
import { RemoveIndex } from '@stitches/react/types/stitches'
import { default as Native } from '@stitches/react/types/css'
import { default as CSSUtil } from '@stitches/react/types/css-util'
import { default as Util } from '@stitches/react/types/util'
import { default as StyledComponent } from '@stitches/react/types/styled-component'
import * as React from 'react'

export interface BaselineElement<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  P = any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends string | React.JSXElementConstructor<any> = string | React.JSXElementConstructor<any>
> extends React.ReactElement<P, T> {
  type: T
  props: P
  key: React.Key | null
  __brand: 'baseline'
}
/**
 * overload the original type definition of `stitches.styled`:
 * throw a type error if we try to use non-theme spacing or color values
 *
 * n.b. this function does not change anything at runtime - just a big type safety helper
 * const styled = (type, ...composers) = stitches.styled.call(null, type, ...composers)
 */
export const createTypeSafeStitches = <Config extends Exclude<Parameters<typeof createStitches>[0], undefined>>(
  config: Config
) => {
  type Media = Config['media']
  type Theme = Config['theme']
  type ThemeMap = Config['themeMap']
  type Utils = Config['utils']

  type ColorNames = Theme extends Record<string, unknown> ? Extract<keyof Theme['colors'], string> : never
  type SpaceNames = Theme extends Record<string, unknown> ? Extract<keyof Theme['space'], string> : never
  type BorderWidthNames = Theme extends Record<string, unknown> ? Extract<keyof Theme['borderWidths'], string> : never
  type RadiusNames = Theme extends Record<string, unknown> ? Extract<keyof Theme['radii'], string> : never
  type ShadowNames = Theme extends Record<string, unknown> ? Extract<keyof Theme['shadows'], string> : never
  type ZIndexNames = Theme extends Record<string, unknown> ? Extract<keyof Theme['zIndices'], string> : never

  type ValidColorValue = Native.Globals | 'transparent' | `$${ColorNames}`
  type ValidSpaceValue = 0 | Native.Globals | `$${SpaceNames}` | `calc(${string}`
  type ValidLayoutValue = ValidSpaceValue | `${number}%` | `${number}vh` | `${number}vw` | 'max-content' | 'min-content'
  type ValidMarginValue = ValidSpaceValue | 'none' | `calc(${string}`
  type ValidPaddingValue = ValidSpaceValue | 'none' | `calc(${string}`
  // be careful here - this union is nearing the limit of ts's ability to handle string template types
  // if we mistakenly a few more types here, and we'll run into an error:
  // TS2590: Expression produces a union that is to complex to represent.
  type ValidSideValue = '0' | `$${SpaceNames}`
  type ValidSidesValue<T extends string> = 0 | T | `${T} ${T}` | `${T} ${T} ${T} ${T}` | `calc(${string}`
  type ValidBorderWidthValue = 0 | Native.Globals | `$${BorderWidthNames}`
  type ValidBorderRadiusValue = 0 | Native.Globals | `$${RadiusNames}`
  type ValidBorderValue = 0 | 'none' | `${ValidBorderWidthValue} solid ${ValidColorValue}`
  type ValidBoxShadowValue = 0 | 'none' | `$${ShadowNames}`
  type ValidZIndexValue = 0 | 1 | 2 | 3 | `$${ZIndexNames}` | `calc(${string}`

  type ValidCSSProperties = {
    height?: ValidLayoutValue
    width?: ValidLayoutValue
    padding?: ValidSidesValue<ValidSideValue>
    paddingLeft?: ValidPaddingValue
    paddingRight?: ValidPaddingValue
    paddingTop?: ValidPaddingValue
    paddingBottom?: ValidPaddingValue
    margin?: ValidSidesValue<ValidSideValue>
    marginLeft?: ValidMarginValue
    marginRight?: ValidMarginValue
    marginTop?: ValidMarginValue
    marginBottom?: ValidMarginValue

    color?: ValidColorValue
    backgroundColor?: ValidColorValue

    border?: ValidBorderValue
    borderWidth?: ValidBorderWidthValue
    borderLeftWidth?: ValidBorderWidthValue
    borderRightWidth?: ValidBorderWidthValue
    borderTopWidth?: ValidBorderWidthValue
    borderBottomWidth?: ValidBorderWidthValue
    borderRadius?: ValidBorderRadiusValue

    boxShadow?: ValidBoxShadowValue

    zIndex?: ValidZIndexValue
  }

  type ValidCSS = Omit<CSSUtil.CSS<Media, Theme, ThemeMap, Utils>, keyof ValidCSSProperties> & ValidCSSProperties
  type BaselineSafeChild = React.ReactText | undefined | null | false | BaselineElement
  type BaselineSafeChildren = BaselineSafeChild | BaselineSafeChild[]

  const stitches = createStitches(config)
  /**
   * the type definition of `styled` here is copied wholesale from:
   * - file       @stitches/react/types/stitches.d.ts
   * - interface  Stitches
   * - method     styled
   *
   * with one specific change to add type safety:
   * - before: `CSS = CSSUtil.CSS<Media, Theme, ThemeMap, Utils>`
   * - after: `CSS = ValidCSS`
   *
   * in an ideal world, CSSUtil.CSS would be exported as interface that we could extend, not a type
   * but alas, this is what we have to work with
   */
  const styled = <
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Type extends keyof JSX.IntrinsicElements | React.ComponentType<any> | Util.Function,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Composers extends (string | React.ComponentType<any> | Util.Function | { [name: string]: unknown })[],
    CSS = ValidCSS
  >(
    type: Type,
    ...composers: {
      [K in keyof Composers]: string extends Composers[K] // Strings, React Components, and Functions can be skipped over
        ? Composers[K]
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Composers[K] extends string | React.ComponentType<any> | Util.Function
        ? Composers[K]
        : RemoveIndex<CSS> & {
            /** The **variants** property lets you set a subclass of styles based on a key-value pair.
             *
             * [Read Documentation](https://stitches.dev/docs/variants)
             */
            variants?: {
              [Name in string]: {
                [Pair in number | string]: CSS
              }
            }
            /** The **compoundVariants** property lets you to set a subclass of styles based on a combination of active variants.
             *
             * [Read Documentation](https://stitches.dev/docs/variants#compound-variants)
             */
            compoundVariants?: (('variants' extends keyof Composers[K]
              ? {
                  [Name in keyof Composers[K]['variants']]?:
                    | Util.Widen<keyof Composers[K]['variants'][Name]>
                    | Util.String
                }
              : Util.WideObject) & {
              css: CSS
            })[]
            /** The **defaultVariants** property allows you to predefine the active key-value pairs of variants.
             *
             * [Read Documentation](https://stitches.dev/docs/variants#default-variants)
             */
            defaultVariants?: 'variants' extends keyof Composers[K]
              ? {
                  [Name in keyof Composers[K]['variants']]?:
                    | Util.Widen<keyof Composers[K]['variants'][Name]>
                    | Util.String
                }
              : Util.WideObject
          } & CSS & {
              [K2 in keyof Composers[K]]: K2 extends 'compoundVariants' | 'defaultVariants' | 'variants'
                ? unknown
                : K2 extends keyof CSS
                ? CSS[K2]
                : unknown
            }
    }
  ): /**
   * the return type of styled here originates from the return type
   * - file       @stitches/react/types/stitches.d.ts
   * - interface  Stitches
   * - method     styled
   *
   * It has been edited to include the baselineFontSize stuff.
   * i.e. if the styledCompoent's CSS contains the `baselineFontSize` util
   * then force that it's a leaf node
   * i.e. it's children are all primitives, not Elements
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Composers[0] extends { baselineFontSize: any }
    ? StyledComponent.StyledComponent<
        Type,
        StyledComponent.StyledComponentProps<Composers> & { children?: BaselineSafeChildren },
        Media,
        CSSUtil.CSS<Media, Theme, ThemeMap, Utils>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      >
    : StyledComponent.StyledComponent<
        Type,
        StyledComponent.StyledComponentProps<Composers>,
        Media,
        CSSUtil.CSS<Media, Theme, ThemeMap, Utils>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      > => (stitches.styled as any).call(null, type, ...composers)

  return {
    ...stitches,
    styled,
  }
}
