import { Hint } from 'components/Hint'
import React from 'react'
import { styled } from 'themes'

// todo: #accessibility: use a real button, not an anchor.
const Button = styled('a', {
  display: 'block',
  color: '$linkColor',
  fontWeight: '$semibold',
  cursor: 'pointer',
  textTransform: 'uppercase',
})

type Props = {
  hint?: string
  shortcut?: string[]
  onClick: () => void
}

export const ToastActionButton: React.FC<Props> = props => {
  if (props.hint) {
    return (
      <Hint text={props.hint} shortcut={props.shortcut}>
        <Button {...props}>{props.children}</Button>
      </Hint>
    )
  }

  return <Button {...props}>{props.children}</Button>
}
