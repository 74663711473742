import { styled } from 'themes'

// todo: (PB): these can be a background gradient instead
const blurSize = 122
export const BlurEffect = styled('div', {
  position: 'absolute',
  borderRadius: '$full',
  // @ts-expect-error intentionally non-baseline size
  width: blurSize,
  // @ts-expect-error intentionally non-baseline size
  height: blurSize,
  filter: 'blur(125px)',
  zIndex: '$aboveNavigation',
  pointerEvents: 'none',
})
export const NavBlurEffect = styled(BlurEffect, {
  // @ts-expect-error intentionally non-theme value
  backgroundColor: '#FA9DA9',
  left: `calc(100% - ${blurSize}px/2)`,
  bottom: '40%',
})
export const HeaderBlurEffect = styled(NavBlurEffect, {
  // @ts-expect-error intentionally non-theme value
  backgroundColor: '#72c0ea',
  top: 0,
  left: 0,
})
