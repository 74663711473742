import { useCommand } from 'contexts/CommandPalette'
import { useDispatch } from 'hooks/use-dispatch'
import { useReminders } from 'hooks/use-reminders'
import { useSelector } from 'hooks/use-selector'
import React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { getDraftByChannelId } from 'selectors/channels'
import { isFocusOnChannel, isFocusOnMessageComposeField } from 'selectors/focus'
import { setFocusRegionToChannel } from 'slices/focus'
import { ChannelTab, toggleChannelTab } from 'slices/message-lists'
import { useMessageListContext } from '.'

export const MessageListShortcuts: React.FC = () => {
  const dispatch = useDispatch()
  const {
    id: messageListId,
    type,
    focus,
    nextMessage,
    lastMessage,
    focusedItem,
    focusNextMessage,
    focusPrevMessage,
    focusMessageComposeField,
  } = useMessageListContext()
  const focusIsOnChat = focus.tab === ChannelTab.Chat
  const focusIsOnComposeField = useSelector(s => isFocusOnMessageComposeField(s, messageListId))

  const onNext = React.useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault()
      if (nextMessage) {
        focusNextMessage()
      } else {
        focusMessageComposeField()
      }
    },
    [nextMessage, focusNextMessage, focusMessageComposeField]
  )
  useHotkeys('j, down', onNext, {}, [focusedItem])

  const onPrevious = React.useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault()
      focusPrevMessage()
    },
    [focusPrevMessage]
  )
  useHotkeys('k, up', onPrevious, {}, [focusedItem])

  const onPressEscape = React.useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault()
      e.stopPropagation()

      if (focusIsOnComposeField) {
        dispatch(setFocusRegionToChannel)
      } else {
        focusMessageComposeField()
      }
    },
    [dispatch, focusIsOnComposeField, focusMessageComposeField]
  )
  useHotkeys('esc', onPressEscape, { enabled: focusIsOnChat, enableOnTags: ['TEXTAREA'] }, [focusedItem])

  const onPressTab = React.useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault()
      focusMessageComposeField()
    },
    [focusMessageComposeField]
  )
  useHotkeys(
    'tab',
    onPressTab,
    {
      enabled: focusIsOnChat,
      enableOnTags: ['TEXTAREA'],
    },
    [focusedItem]
  )

  const toggleBetweenTabs = React.useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault()
      dispatch(toggleChannelTab({ messageListId }))
    },
    [dispatch, messageListId]
  )
  useHotkeys(
    'option+tab',
    toggleBetweenTabs,
    {
      enableOnTags: ['TEXTAREA'],
    },
    [isFocusOnChannel]
  )

  const inputEnabled = focusIsOnChat && focusIsOnComposeField
  const draft = useSelector(s => getDraftByChannelId(s, messageListId))

  const { openReminderMenu } = useReminders({ messageListId, type })

  const createReminderForLastMessage = React.useCallback(
    (e?: KeyboardEvent) => {
      e?.preventDefault()
      if (!lastMessage) {
        return
      }
      openReminderMenu(lastMessage)
    },
    [openReminderMenu, lastMessage]
  )
  useHotkeys('cmd+h', createReminderForLastMessage, {
    enableOnTags: ['TEXTAREA'],
    keydown: true,
    enabled: inputEnabled,
  })

  const createReminderForDraft = React.useCallback(
    (e?: KeyboardEvent) => {
      e?.preventDefault()
      if (!draft) {
        return
      }
      openReminderMenu(draft)
    },
    [openReminderMenu, draft]
  )
  useCommand({
    title: 'Set reminder for draft',
    enabled: focusIsOnChat && focusIsOnComposeField && !!draft?.body?.length,
    target: draft,
    shortcut: 'cmd+shift+h',
    handler: createReminderForDraft,
    score: 100,
  })
  return null
}
