import { DiscussionMessage } from 'services/messages'
import datetime from 'utils/datetime'
import * as firebase from 'services/firebase'

export const fetchDiscussions = async ({
  orgId,
  channelId,
  limit = 50,
}: {
  orgId: string
  channelId: string
  limit?: number
}) => {
  const results = await firebase.fetch({
    path: `orgs/${orgId}/discussionMessages`,
    where: [{ field: 'channelId', op: '==', value: channelId }],
    orderBy: [{ field: 'sentAt', desc: true }],
    limit,
  })
  const discussions = results.docs.map(firebaseDocumentToDiscussionMessage)

  for (const discussion of discussions) {
    const results = await firebase.fetch({
      path: `orgs/${orgId}/discussionMessages`,
      where: [{ field: 'threadId', op: '==', value: discussion.id }],
      orderBy: [{ field: 'sentAt' }],
      limit,
    })
    discussions.push(...results.docs.map(firebaseDocumentToDiscussionMessage))
  }
  return discussions
}

const firebaseDocumentToDiscussionMessage = (
  row: firebase.QueryDocumentSnapshot<firebase.DocumentData>
): DiscussionMessage => {
  return {
    ...row.data(),
    sentAt: datetime.fromFirebaseTime(row.data().sentAt),
    id: row.id,
  } as DiscussionMessage
}
