import { useDispatch } from 'hooks/use-dispatch'
import { useSelector } from 'hooks/use-selector'
import React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { isOpen } from 'selectors/command-palette'
import { setAsOpen } from 'slices/command-palette'
import { useCommandPalette } from '.'

export const CommandPaletteShortcuts: React.FC = () => {
  const isModalOpen = useSelector(isOpen)
  const { openCommandPalette } = useCommandPalette()
  const dispatch = useDispatch()
  const onPressCmdK = React.useCallback(() => {
    if (isModalOpen) {
      dispatch(setAsOpen(false))
      return
    }

    openCommandPalette()
  }, [dispatch, openCommandPalette, isModalOpen])

  useHotkeys('cmd+k', onPressCmdK, { enableOnTags: ['INPUT', 'TEXTAREA'] })
  return null
}
