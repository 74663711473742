import { Photo } from 'components/Photo'
import { styled } from 'themes'

export const MessageTitle = styled('header', {
  cursor: 'default',
  color: '$primaryAccentText',
})

export const MessageUsername = styled('div', {
  baselineFontSize: ['base'],
  color: '$primaryText',
  fontWeight: '$semibold',
  marginRight: '$space2',
  display: 'inline-block',
})

export const MessageBody = styled('section', {
  color: '$primaryText',
  fontWeight: '$normal',
  baselineFontSize: ['base'],
  paddingTop: '$space1',
  whiteSpace: 'pre-wrap',
  variants: {
    onlyEmoji: {
      true: {
        fontSize: '$emoji',
        marginTop: '$space1',
      },
    },
    isEditing: {
      true: {
        paddingBottom: '$space2',
      },
    },
  },
})

//todo: #accessibility: use a real button or aria props, not a div.
export const MessageWrapper = styled('div', {
  paddingLeft: 'calc($space4 + $thinBorder)',
  borderLeftWidth: '$thick',
  borderLeftColor: 'transparent',
  borderLeftStyle: 'solid',
  position: 'relative',
  variants: {
    active: {
      true: {
        background: '$focusOrActiveBackground',
      },
    },
    border: {
      true: {
        borderColor: '$focusOrActive',
      },
    },
  },
})

export const MessageInner = styled('div', {
  padding: '$space2 $space5 $space2 $space4',
  position: 'relative',
  display: 'flex',
  gap: '$space2',
  borderLeftWidth: '$thick',
  borderLeftColor: 'transparent',
  borderLeftStyle: 'solid',
  '& > div': {
    width: '100%',
  },
  variants: {
    border: {
      active: {
        borderColor: '$focusOrActive',
      },
      reply: {
        borderColor: '$borderColor',
      },
      reminder: {
        borderColor: '$reminderBackground',
      },
    },
  },
})

export const MessageProfilePhoto = styled(Photo, {
  width: '$space6',
  height: '$space6',
  marginTop: '$space1',
  background: '$primaryBackground',
})
