import { HeaderBlurEffect } from 'components/BlurEffects'
import { Icon } from 'components/Icons'
import { Photo } from 'components/Photo'
import { useCommand } from 'contexts/CommandPalette'
import React from 'react'
import { styled } from 'themes'
import { deleteDatabase } from 'utils/delete-database'
import { signOut } from 'services/users'
import { useAuthContext } from 'contexts/Auth'
import { useNavigate } from 'react-router-dom'

const Wrapper = styled('header', {
  overflow: 'visible',
  padding: 'calc($space1 * 2.5) 0',
  background: '$navigationBg',
  boxSizing: 'content-box',
  borderBottomStyle: 'solid',
  borderBottomWidth: '$thin',
  borderBottomColor: '$borderColor',
  marginBottom: '$negativeThinBorder',
  position: 'relative',
  zIndex: 2,
  flex: '0',
  panel: 1,
  minHeight: '$space8',
  '@narrow': {
    gridTemplateColumns: 'minmax(75px, 10%) auto minmax(110px, 10%)',
  },
})

const Navigation = styled('section', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  height: '100%',
  padding: '0 $space2',
})

const Button = styled('button', {
  border: 0,
  height: '100%',
  background: 'transparent',
  stroke: '$primaryAccentText',
  cursor: 'pointer',
  '&:hover': {
    stroke: '$primaryText',
  },
})

const Search = styled('section', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const searchProps = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '$space8',
  color: '$primaryAccentText',
  left: 0,
  textAlign: 'center',
  fill: '$primaryAccentText',
  fontWeight: '$normal',
  fontSize: '$base',
  fontFamily: '$sans',
  borderRadius: '$l',
  background: '$primaryAccentBackground',
  cursor: 'pointer',
  border: 0,
  outline: 'none',
} as const
const SearchLabel = styled('label', {
  ...searchProps,
  '& span': {
    baselineFontSize: ['base'],
  },
  '& svg': {
    marginRight: '$space2',
  },
})

const SearchInput = styled('input', searchProps)

const User = styled('section', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  padding: '0 $space5',
  '& svg': {
    marginTop: '$space1',
    marginRight: '$space5',
    stroke: 'none',
    fill: '$primaryAccentText',
  },
})

const ProfilePhoto = styled(Photo, {
  width: '$space8',
  height: '$space8',
  borderRadius: '$xl',
  background: '$navigationBg',
})

const OnlineIcon = styled('div', {
  content: ' ',
  position: 'absolute',
  bottom: '$space2',
  right: 'calc($space5 - 2px)',
  width: '$space2',
  height: '$space2',
  background: 'rgba(110, 189, 119, 1)',
  borderWidth: '$thin',
  borderStyle: 'solid',
  borderColor: '$primaryBackground',
  borderRadius: '$full',
  boxSizing: 'content-box',
})

export const Topbar: React.FC = () => {
  const { user } = useAuthContext()
  const isLoggedIn = !!user
  const navigate = useNavigate()

  const signout = React.useCallback(() => {
    signOut()
    navigate('/signin')
    // legacy (firebase):
    // intentionally hard refresh, not push
    // need to reset firebase auth's internal state
    setTimeout(() => {
      window.location.reload()
    }, 1)
  }, [navigate])
  useCommand({
    title: 'Sign out',
    enabled: isLoggedIn,
    handler: signout,
    score: 1,
  })

  const deleteOfflineDB = React.useCallback(() => {
    deleteDatabase()
    window.location.reload()
  }, [])
  useCommand({
    title: 'Developer: Delete offline database',
    handler: deleteOfflineDB,
    score: 0,
  })

  return (
    <Wrapper>
      <HeaderBlurEffect />
      <Navigation>
        <Button>
          <Icon name='leftArrowHead' />
        </Button>
        <Button>
          <Icon name='rightArrowHead' />
        </Button>
      </Navigation>
      <Search>
        <SearchInput disabled />
        <SearchLabel>
          <span>
            <Icon name='search' />
            Search Superhuman
          </span>
        </SearchLabel>
      </Search>
      <User>
        <Button>
          <Icon name='settings' />
        </Button>
        <ProfilePhoto src={user?.picture || ''} />
        <OnlineIcon />
      </User>
    </Wrapper>
  )
}
