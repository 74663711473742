import { PageWrapper } from 'components/PageWrapper'
import { Channel } from 'pages/Channel'
import { RedirectToChannel } from 'pages/RedirectToChannel'
import { SignIn } from 'pages/SignIn'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

export default function Routing(): JSX.Element {
  return (
    <BrowserRouter>
      <PageWrapper>
        <Routes>
          <Route path='/' element={<RedirectToChannel />} />
          <Route path='/index.html' element={<RedirectToChannel />} />
          <Route path='/channels/:channelId' element={<Channel />} />
          <Route path='/signin' element={<SignIn />} />
        </Routes>
      </PageWrapper>
    </BrowserRouter>
  )
}
