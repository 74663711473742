import { Timestamp } from 'components/Timestamp'
import React from 'react'
import { shouldSuggestCreatingReminder } from 'selectors/reminders'
import { Draft } from 'slices/message-lists'
import { styled } from 'themes'
import { BaselineElement } from 'themes/typesafe-stitches'
import datetime from 'utils/datetime'

const Hint = styled('div', {
  baselineFontSize: ['small'],
  color: '$primaryAccentText',
  padding: '$space1 $space5 $space2 $space5',
})

const Key = styled('div', {
  display: 'inline-block',
  background: '$primaryAccentBackground',
  color: '$primaryAccentText',
  borderRadius: '$s',
  fontWeight: '$semibold',
  marginTop: 'calc($space1/2)',
  padding: 'calc($space1/2) $space1 0 $space1',
  fontSize: '$small',
})

const RemindMe = styled('div', {
  color: '$reminder',
  fontWeight: '$semibold',
})

type Props = {
  draft: Draft
}

export const MessageComposeHint: React.FC<Props> = (props: Props) => {
  const suggestCreatingReminder = shouldSuggestCreatingReminder(props.draft)

  if (props.draft.remindAt) {
    return (
      <Hint>
        {
          (
            <RemindMe>
              Remind me: <Timestamp epoch={datetime.toEpoch(props.draft.remindAt)} />
            </RemindMe>
          ) as BaselineElement
        }
      </Hint>
    )
  }

  if (suggestCreatingReminder) {
    return (
      <Hint>
        To instantly set a reminder on this message, hit {(<Key>⌘</Key>) as BaselineElement}{' '}
        {(<Key>Shift</Key>) as BaselineElement} {(<Key>H</Key>) as BaselineElement}
      </Hint>
    )
  }

  return null
}
