import { useCommand } from 'contexts/CommandPalette'
import { useDispatch } from 'hooks/use-dispatch'
import { useSelector } from 'hooks/use-selector'
import React from 'react'
import { getActionDescription, getFirstRedoStep, getLastUndoStep } from 'selectors/undo-tree'
import { setPageFocus } from 'slices/focus'
import { archiveReminder, unarchiveReminder } from 'actions/reminders'
import { insertRedoStep, insertUndoStep, removeRedoStep, removeUndoStep, Step, StepAction } from 'slices/undo-tree'

export const UndoTree: React.FC = props => {
  const dispatch = useDispatch()
  const lastUndoStep = useSelector(getLastUndoStep)
  const firstRedoStep = useSelector(getFirstRedoStep)

  const archiveReminderById = React.useCallback(
    (step: Step, payload: unknown): Step => {
      dispatch(archiveReminder(payload as string))
      return {
        id: step.id,
        action: StepAction.RevertArchivingReminderById,
        focus: step.focus,
        payload,
      }
    },
    [dispatch]
  )

  const revertArchivingReminderById = React.useCallback(
    (step: Step, payload: unknown): Step => {
      dispatch(unarchiveReminder(payload as string))

      return {
        id: step.id,
        action: StepAction.DeleteReminderById,
        focus: step.focus,
        payload,
      }
    },
    [dispatch]
  )

  const actionHandlers = React.useMemo(() => {
    const actionHandlers: Record<StepAction, (s: Step, payload: unknown) => Step> = {
      [StepAction.DeleteReminderById]: archiveReminderById,
      [StepAction.RevertArchivingReminderById]: revertArchivingReminderById,
    }
    return actionHandlers
  }, [archiveReminderById, revertArchivingReminderById])

  const undo = React.useCallback(() => {
    if (!lastUndoStep) {
      return
    }
    const handler = actionHandlers[lastUndoStep.action]

    // todo: (PB): fix this
    dispatch(setPageFocus(lastUndoStep.focus))
    const redoStep = handler(lastUndoStep, lastUndoStep.payload)

    dispatch(removeUndoStep(lastUndoStep.id))
    dispatch(insertRedoStep(redoStep))
  }, [lastUndoStep, actionHandlers, dispatch])
  useCommand({
    title: 'Undo',
    hint: lastUndoStep ? getActionDescription(lastUndoStep.action) : undefined,
    enabled: !!lastUndoStep,
    handler: undo,
    shortcut: 'cmd+z',
    score: 100,
  })

  const redo = React.useCallback(() => {
    if (!firstRedoStep) return
    const handler = actionHandlers[firstRedoStep.action]

    dispatch(setPageFocus(firstRedoStep.focus))
    const undoStep = handler(firstRedoStep, firstRedoStep.payload)

    dispatch(removeRedoStep(undoStep.id))
    dispatch(insertUndoStep(undoStep))
  }, [actionHandlers, dispatch, firstRedoStep])
  useCommand({
    title: 'Redo',
    hint: firstRedoStep ? getActionDescription(firstRedoStep.action) : undefined,
    enabled: !!firstRedoStep,
    handler: redo,
    shortcut: 'cmd+shift+z',
    score: 100,
  })

  return <>{props.children}</>
}
