import { Input } from 'components/Input'
import { TextField } from 'components/TextField'
import React from 'react'
import { styled } from 'themes'

const ComposeContainer = styled('div', {
  position: 'relative',
  borderRadius: '$l',
  padding: 'calc($space1 - $thinBorder) 0',
  background: '$primaryAccentBackground',
  borderWidth: '$thin',
  borderStyle: 'solid',
  borderColor: 'transparent',
  borderLeftWidth: '$thick',
  borderLeftColor: 'transparent',
  borderLeftStyle: 'solid',
  flex: '1 1 auto',
  [`& ${Input}`]: {
    color: 'rgba(0, 0, 0, 0.9)',
    background: 'transparent',
    fontWeight: '$normal',
    borderRadius: '$l',
    lineHeight: 'inherit',
    caretColor: '$focusOrActive',
    padding: '$space2 $space5',
  },
  variants: {
    focused: {
      true: {
        borderColor: '$borderColor',
        borderLeftColor: '$focusOrActive',
        borderRadius: '$m',
        boxShadow: '$light',
        background: '$primaryBackground',
        [`& ${Input}`]: {
          color: '$primaryText',
          borderRadius: '$m',
        },
      },
    },
  },
})

type Props = {
  focused: boolean
  body: string
  placeholder: string
  inputRef: React.RefObject<HTMLTextAreaElement>
  onInput: (b: string) => void
  onFocus?: () => void
  onBlur?: () => void
}
export const ChatCompose: React.FC<Props> = ({ focused, body, ...props }: Props) => {
  return (
    <ComposeContainer focused={focused}>
      <TextField value={body} {...props} />
    </ComposeContainer>
  )
}
