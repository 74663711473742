import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PageFocus } from 'slices/focus'

export const name = 'undo-tree'

export enum StepAction {
  DeleteReminderById = 'delete-reminder-by-id',
  RevertArchivingReminderById = 'revert-archiving-reminder-by-id',
}

export type Step = {
  id: string
  action: StepAction
  focus: PageFocus
  payload: unknown
}

type State = {
  undo: Step[]
  redo: Step[]
}

export const initialState: State = {
  undo: [],
  redo: [],
}

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    insertUndoStep: (
      state,
      rxAction: PayloadAction<{
        action: StepAction
        focus: PageFocus
        payload: unknown
      }>
    ) => {
      state.undo.push({
        id: randomId(),
        focus: rxAction.payload.focus,
        action: rxAction.payload.action,
        payload: rxAction.payload.payload,
      })
    },
    insertRedoStep: (
      state,
      rxAction: PayloadAction<{
        action: StepAction
        focus: PageFocus
        payload: unknown
      }>
    ) => {
      state.redo = [
        {
          id: randomId(),
          action: rxAction.payload.action,
          payload: rxAction.payload.payload,
          focus: rxAction.payload.focus,
        },
      ].concat(state.redo)
    },
    removeUndoStep: (state, action: PayloadAction<string>) => {
      state.undo = state.undo.filter(s => s.id !== action.payload)
    },
    removeRedoStep: (state, action: PayloadAction<string>) => {
      state.redo = state.redo.filter(s => s.id !== action.payload)
    },
  },
})

export const { insertUndoStep, insertRedoStep, removeRedoStep, removeUndoStep } = slice.actions
export default slice.reducer

function randomId(): string {
  return [Date.now(), Math.floor(Math.random() * 9999999)].join('')
}
