import * as React from 'react'

type Props = React.SVGProps<SVGSVGElement>
export const CloseIcon: React.FC<Props> = (props: Props) => (
  <svg width='1em' height='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.146 9.854a.5.5 0 0 0 .707-.708L6.706 5 10.853.854a.5.5 0 0 0-.707-.707L6 4.293 1.851.146a.5.5 0 0 0-.707.708L5.292 5 1.145 9.147a.5.5 0 1 0 .707.707l4.147-4.147 4.147 4.147Z'
      // eslint-disable-next-line react/prop-types
      fill={props.fill}
    />
  </svg>
)
