import { styled } from 'themes'

const Content = styled('div', {
  display: 'inline-block',
  background: '$primaryAccentBackground',
  borderRadius: '$xs',
  variants: {
    block: {
      true: {
        display: 'block',
        margin: '0 0 $space1 0',
      },
    },
  },
})

type Props = {
  width: number
  height?: number
  block?: boolean
}

export const Placeholder: React.FC<Props> = (props: Props) => (
  <Content
    block={props.block}
    css={{
      width: `${props.width * 50}px`,
      height: `${Math.ceil(((props.height || 0.225) * 50) / 4) * 4}px`,
    }}
  />
)
