import * as React from 'react'

type Props = React.SVGProps<SVGSVGElement>
export const CommandIcon: React.FC<Props> = (props: Props) => (
  <svg width='1em' height='1em' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12.48 14.63' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.848.205a1 1 0 00-1.214 0l-5.24 4a1 1 0 000 1.59l1.99 1.518-1.99 1.52a1 1 0 000 1.59l5.24 3.999a1 1 0 001.214 0l5.24-4a1 1 0 000-1.59l-1.99-1.519 1.99-1.519a1 1 0 000-1.59L6.849.205zm2.426 7.737L7.72 9.128h3.107L9.274 7.942zm1.553 2.186H6.072l-.134-.103-2.73-2.083L1 9.628l5.241 3.999 4.586-3.5zM1 4.999l5.241 4 5.241-4L6.241 1 1 5z'
      // eslint-disable-next-line react/prop-types
      fill={props.fill}
    />
  </svg>
)
