import { styled } from 'themes'

export const Input = styled('textarea', {
  display: 'block',
  width: '100%',
  color: '$primaryText',
  backgroundColor: 'inherit',
  font: 'inherit',
  border: 0,
  outline: 0,
  resize: 'none',
  overflow: 'hidden',
  gridArea: '1 / 1 / 2 / 2',
  baselineFontSize: ['base', 'normal'],
  '&::placeholder': {
    color: '$primaryAccentText',
  },
})
