import { MessageComposeField } from 'components/MessageComposeField'
import { MessageList } from 'components/MessageList'
import { MessageListContext } from 'contexts/MessageList'
import { useSelector } from 'hooks/use-selector'
import { getChannelNameForMessageList } from 'selectors/channels'
import { getUsersForMessageList } from 'selectors/users'
import { MessageListType } from 'services/message-lists'
import { styled } from 'themes'

const Wrapper = styled('div', {
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
})

const Header = styled('h1', {
  display: 'flex',
  alignItems: 'center',
  fontWeight: '$fontNormal',
  padding: '$space5 $space5 $space7 $space5',
  margin: 0,
  '@narrow': {
    display: 'none',
  },
})

const ChannelName = styled('span', {
  baselineFontSize: ['large'],
})

type Props = {
  threadId: string
}
export const Thread: React.FC<Props> = ({ threadId }: Props) => {
  const channelName = useSelector(s => getChannelNameForMessageList(s, threadId))
  const users = useSelector(s => getUsersForMessageList(s, threadId))
  return (
    <Wrapper>
      <MessageListContext messageListId={threadId} type={MessageListType.Thread}>
        <Header>
          <ChannelName>
            Thread
            {channelName && ` in #${channelName}`}
            {users.length > 0 && ` with ${users.map(u => u.name).join(', ')}`}
          </ChannelName>
        </Header>
        <MessageList />
        <MessageComposeField placeholder='Reply to thread' />
      </MessageListContext>
    </Wrapper>
  )
}
