import { Message } from 'components/Message'
import { MessagePlaceholder } from 'components/MessagePlaceholder'
import { LoadMore } from 'components/LoadMore'
import { useMessageListContext } from 'contexts/MessageList'
import { MessageContext } from 'contexts/Message'
import React, { useEffect, useRef } from 'react'
import { styled } from 'themes'
import { scrollIntoView } from 'utils/scroll-into-view'
import { ChatItem } from 'selectors/messages'

const MemoizedMessage = React.memo(
  ({ item, prevItem, nextItem }: { item: ChatItem; prevItem?: ChatItem; nextItem?: ChatItem }) => {
    return (
      <MessageContext item={item} prevItem={prevItem} nextItem={nextItem}>
        <Message />
      </MessageContext>
    )
  }
)
MemoizedMessage.displayName = 'MemoizedMessage'

const Wrapper = styled('section', {
  display: 'flex',
  flexDirection: 'column-reverse',
  overflow: 'hidden',
  overflowY: 'scroll',
  height: '100%',
  maxHeight: '100%',
  variants: {
    thread: {
      true: {
        flexDirection: 'column',
        height: 'max-content',
      },
    },
  },
})
const BeginningOfChannel = styled('div', {
  baselineFontSize: ['base'],
  padding: '$space5',
  color: '$primaryAccentText',
  fontStyle: 'italic',
})
export const MessageList: React.FC = ({ children }) => {
  const { type, items, focusedItem, totalMessages, loadMore } = useMessageListContext()
  const selectedMessageId = focusedItem?.id

  // Reset scroll position when user loads more messages from channel history.
  const scrollEl = useRef<HTMLDivElement>(null)
  const scrollY = React.useRef<number>(0)
  const firstMessage = items[0]
  useEffect(() => {
    if (scrollEl.current) {
      scrollEl.current.scrollTop = scrollY.current
    }
  }, [firstMessage, scrollEl, scrollY])
  const onScroll = React.useCallback(() => {
    if (scrollEl.current) {
      scrollY.current = scrollEl.current.scrollTop
    }
  }, [scrollEl])

  // Ensure selected message is visible when selected message changes.
  useEffect(() => {
    if (!selectedMessageId || !scrollEl.current) {
      return
    }
    const selectedItemId = selectedMessageId || items[items.length - 1]
    const children = Array.from((scrollEl.current.firstChild as HTMLElement)?.children)
    const selectedEl = children.find(a => a.getAttribute('data-id') === selectedItemId)
    scrollIntoView(scrollEl.current, selectedEl)
  }, [selectedMessageId, items])

  return (
    <Wrapper ref={scrollEl} onScroll={onScroll} thread={type === 'thread'}>
      <div>
        {items.length < totalMessages && (
          <LoadMore loadMore={loadMore}>{items.length > 0 && <MessagePlaceholder />}</LoadMore>
        )}
        {items.length === totalMessages && !(type === 'thread') && (
          <BeginningOfChannel>This is the beginning of this channel</BeginningOfChannel>
        )}
        {items.map((item, ind) => (
          <MemoizedMessage key={item.id} item={item} prevItem={items[ind - 1]} nextItem={items[ind + 1]} />
        ))}
        {children}
      </div>
    </Wrapper>
  )
}
