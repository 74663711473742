import { Command, useCommandPalette } from 'contexts/CommandPalette'
import { useToast } from 'contexts/Toast'
import {
  format,
  isBefore,
  isFriday,
  isSameMinute,
  isThisWeek,
  isThisYear,
  isThursday,
  isToday,
  nextFriday,
  nextMonday,
  setHours,
  startOfHour,
  startOfTomorrow,
} from 'date-fns'
import { useDispatch } from 'hooks/use-dispatch'
import { useSelector } from 'hooks/use-selector'
import { ChatItem, chatItemIsMessage } from 'selectors/messages'
import { Draft, createReminderForDraft } from 'slices/message-lists'
import { MessageListType } from 'services/message-lists'
import { archiveReminder, createReminder, unarchiveReminder } from 'actions/reminders'
import { insertUndoStep, StepAction } from 'slices/undo-tree'
import datetime, { DateTime } from 'utils/datetime'

function hint(dt: DateTime): string {
  const date = datetime.toDate(dt)
  if (isSameMinute(date, new Date())) {
    return ''
  }

  if (isToday(date)) {
    return format(date, 'p').toUpperCase()
  }

  return formatReminderSchedule(date).toUpperCase()
}

function formatReminderSchedule(date: Date): string {
  if (isThisWeek(date)) {
    return format(date, 'iii, p')
  }

  if (isThisYear(date)) {
    return format(date, 'LLL do, p')
  }

  return format(date, 'PP')
}

type RemindersHookValue = {
  openReminderMenu: (item: ChatItem | Draft | null) => void
  archiveReminder: (reminderId: string) => void
}
export const useReminders = ({
  messageListId,
  type,
}: {
  messageListId: string
  type: MessageListType
}): RemindersHookValue => {
  const dispatch = useDispatch()
  const { openCommandPalette } = useCommandPalette()
  const toast = useToast()
  const currentFocus = useSelector(s => s.focus)

  function openReminderMenu(item: ChatItem | Draft | null) {
    if (!item) {
      return
    }
    const commands: Command[] = []
    const nowDt = datetime.now()
    const now = Date.now()

    const inAFewSeconds = datetime.add(nowDt, datetime.minutes(0.25))
    commands.push({
      title: 'in a few seconds',
      hint: hint(inAFewSeconds),
      handler: () => onCreateReminder(item, inAFewSeconds),
      score: 100,
    })
    const inOneHour = datetime.add(nowDt, datetime.hours(1))
    commands.push({
      title: 'in 1 hour',
      hint: hint(inOneHour),
      handler: () => onCreateReminder(item, inOneHour),
      score: 100,
    })
    if (isBefore(Date.now(), setHours(Date.now(), 16))) {
      const atEndOfDay = datetime.fromNativeTime(startOfHour(setHours(Date.now(), 17)))
      commands.push({
        title: 'by end of day',
        hint: hint(atEndOfDay),
        handler: () => onCreateReminder(item, atEndOfDay),
        score: 100,
      })
    }
    if (isBefore(Date.now(), setHours(Date.now(), 18))) {
      const tonight = datetime.fromNativeTime(startOfHour(setHours(Date.now(), 21)))
      commands.push({
        title: 'tonight',
        hint: hint(tonight),
        handler: () => onCreateReminder(item, tonight),
        score: 100,
      })
    }
    const tomorrow = datetime.fromNativeTime(startOfHour(setHours(startOfTomorrow(), 10)))
    commands.push({
      title: 'tomorrow',
      hint: hint(tomorrow),
      handler: () => onCreateReminder(item, tomorrow),
      score: 100,
    })
    if (!isFriday(now) && !isThursday(now)) {
      const endOfWeek = datetime.fromNativeTime(startOfHour(nextFriday(Date.now())))
      commands.push({
        title: 'end of week',
        hint: hint(endOfWeek),
        handler: () => onCreateReminder(item, endOfWeek),
        score: 100,
      })
    }
    if (isFriday(now) || isThursday(now)) {
      const earlyNextWeek = datetime.fromNativeTime(startOfHour(nextMonday(Date.now())))
      commands.push({
        title: 'early next week',
        hint: hint(earlyNextWeek),
        handler: () => onCreateReminder(item, earlyNextWeek),
        score: 100,
      })
    }

    if ('remindAt' in item && 'id' in item) {
      commands.push({
        title: 'Remove reminder',
        handler: () => {
          if (item?.id) {
            onArchiveReminder(item?.id)
          }
        },
        score: 100,
      })
    }
    openCommandPalette({
      title: 'Remind me',
      icon: 'remind',
      placeholder: 'Try: 8am, 3 days, aug 7',
      commands,
    })
  }

  async function onCreateReminder(item: ChatItem | Draft, remindAt: DateTime) {
    if ('body' in item) {
      await dispatch(
        createReminderForDraft({
          messageListId,
          // type,
          remindAt,
        })
      )
    } else if (chatItemIsMessage(item)) {
      const reminder = await dispatch(
        createReminder({
          messageListId,
          type,
          messageId: item.id,
          remindAt,
        })
      )
      toast.show({
        body: `Reminder set: ${formatReminderSchedule(datetime.toDate(remindAt))}`,
        actionText: 'Undo',
        actionHint: 'Undo',
        actionShortcut: ['cmd', 'z'],
        onClickAction: async () => {
          dispatch(archiveReminder(reminder.id))
          toast.hide()
        },
      })
      dispatch(
        insertUndoStep({
          action: StepAction.DeleteReminderById,
          payload: reminder.id,
          focus: currentFocus,
        })
      )
    }
  }

  function onArchiveReminder(reminderId: string) {
    dispatch(archiveReminder(reminderId))

    toast.show({
      body: `Reminder archived`,
      actionText: 'Undo',
      actionHint: 'Undo',
      actionShortcut: ['cmd', 'z'],
      onClickAction: async () => {
        dispatch(unarchiveReminder(reminderId))
        toast.hide()
      },
    })

    dispatch(
      insertUndoStep({
        action: StepAction.RevertArchivingReminderById,
        payload: reminderId,
        focus: currentFocus,
      })
    )
  }
  // todo: add useCallbacks here
  return {
    openReminderMenu,
    archiveReminder: onArchiveReminder,
  }
}
