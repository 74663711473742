import { loadChannels } from 'actions/message-lists'
import { subscribeToChanges } from 'actions/reminders'
import { CommandPaletteModal } from 'components/CommandPaletteModal'
import { ThemeWrapper } from 'components/ThemeWrapper'
import { UndoTree } from 'components/UndoTree'
import { AuthContext, useAuthContext } from 'contexts/Auth'
import { CommandPaletteContext } from 'contexts/CommandPalette'
import { ToastProvider } from 'contexts/Toast'
import { useDispatch } from 'hooks/use-dispatch'
import React, { useEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { togglePageFocusRegion } from 'slices/focus'
import { globalStyles } from 'themes/global'

// todo: (PB): fix this
const PageLoadSubscriber: React.FC = () => {
  const dispatch = useDispatch()
  const { user } = useAuthContext()
  useEffect(() => {
    dispatch(subscribeToChanges())
  }, [dispatch])

  useEffect(() => {
    if (user) {
      dispatch(loadChannels(user?.profiles[0]))
    }
  }, [dispatch, user])
  return null
}

export const PageWrapper: React.FC = props => {
  const dispatch = useDispatch()
  const toggleBetweenPanes = React.useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault()
      dispatch(togglePageFocusRegion())
    },
    [dispatch]
  )
  useHotkeys('cmd+d', toggleBetweenPanes, {
    enableOnTags: ['TEXTAREA'],
    keydown: true,
  })

  globalStyles()

  return (
    <AuthContext>
      <CommandPaletteContext>
        <ThemeWrapper>
          <CommandPaletteModal />
          <ToastProvider>
            <UndoTree>
              {props.children}
              <PageLoadSubscriber />
            </UndoTree>
          </ToastProvider>
        </ThemeWrapper>
      </CommandPaletteContext>
    </AuthContext>
  )
}
