import { useDispatch } from 'hooks/use-dispatch'
import React from 'react'
import { measure } from 'services/metrics'
import { getCurrentUser, CurrentUser } from 'services/users'
import { setSession } from 'slices/session'
import { setUsers } from 'slices/users'

type AuthContextValue = {
  signIn: (user: CurrentUser | null) => Promise<void>
  signOut: () => Promise<void>
  user: CurrentUser | null
  state: State
}
export enum State {
  Loading = 'Loading',
  Ready = 'Ready',
  Error = 'Error',
}
const initialValue = {
  signIn: async () => undefined,
  signOut: async () => undefined,
  user: null,
  state: State.Loading,
}
const context = React.createContext<AuthContextValue>(initialValue)

export const AuthContext: React.FC = ({ children }) => {
  const dispatch = useDispatch()
  const [user, setUser] = React.useState<CurrentUser | null>(null)
  const [state, setState] = React.useState<State>(State.Loading)

  const signIn = React.useCallback(
    async (user: CurrentUser | null) => {
      setState(State.Loading)
      if (!user) {
        setState(State.Error)
        return
      }
      // temporary: in any case, hard code to the first workspace id returned from the backend
      const workspaceId = user.profiles[0].workspaceId
      measure(workspaceId, 'ui.profile.session_start')
      setUser(user)
      dispatch(setSession({ id: user.id }))
      dispatch(setUsers([user]))
      setState(State.Ready)
    },
    [dispatch]
  )

  const signOut = React.useCallback(async () => {
    setUser(null)
  }, [])

  React.useEffect(() => {
    setState(State.Loading)
    getCurrentUser().then(user => {
      if (!user || !user?.email?.endsWith('@superhuman.com')) {
        signOut()
      } else {
        setUser(user)
        dispatch(setSession({ id: user.id }))
      }
      setState(State.Ready)
    })
  }, [signOut, setUser, dispatch])

  const value = React.useMemo(() => ({ signIn, signOut, user, state }), [signIn, signOut, user, state])
  return <context.Provider value={value}>{children}</context.Provider>
}
export const useAuthContext = () => React.useContext(context)
