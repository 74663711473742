import { useMessageListContext } from 'contexts/MessageList'
import { useDispatch } from 'hooks/use-dispatch'
import { useSelector } from 'hooks/use-selector'
import { getTotalThreadCountByChannelId } from 'selectors/discussion-list'
import { MessageListType } from 'services/message-lists'
import { ChannelTab, openChat, openDiscussion } from 'slices/message-lists'
import { styled } from 'themes'

const PaneHeader = styled('div', {
  position: 'relative',
  padding: `calc($space4 + ($space1 / 2))
    $space9
    calc($space4 + ($space1 / 2) - $thinBorder)
    $space9
  `,
  display: 'flex',
  alignItems: 'flex-end',
  fontWeight: '$normal',
  borderBottomWidth: '$thin',
  borderBottomStyle: 'solid',
  borderBottomColor: '$borderColor',
  '@narrow': {
    padding: `calc($space1 + ($space1 / 2))
      $space4
      calc($space1 + ($space1 / 2) - $thinBorder)
      $space5
    `,
  },
})

const ChannelName = styled('div', {
  display: 'flex',
  color: '$primaryText',
  fontSize: '$large',
  marginRight: '$space5',
  padding: '$space1 0',
})

const Tab = styled('div', {
  display: 'block',
  marginRight: '$space2',
  cursor: 'default',
  // @ts-expect-error horizontal padding intentionally not baseline
  padding: '$space2 10px $space1 10px',
  color: '$primaryAccentText',
  fontSize: '$large',
  borderRadius: '$l',
  '& label': {
    fontSize: '$base',
    marginLeft: '$space2',
    marginTop: '$space1',
    color: '$primaryAccentText',
    opacity: 0.5,
  },
  '&:last-child': {
    marginRight: 0,
  },
  variants: {
    selected: {
      true: {
        background: '$focusOrActiveBackground',
        color: '$focusOrActive',
      },
    },
  },
})

export const ChannelTabs: React.FC = () => {
  const dispatch = useDispatch()
  const { id, name, focus } = useMessageListContext()
  const focusIsOnChat = focus.tab === ChannelTab.Chat
  const discussionCount = useSelector(s => getTotalThreadCountByChannelId(s, id))
  const onClickChatTab = () => dispatch(openChat({ messageListId: id, type: MessageListType.Channel }))
  const onClickDiscussionTab = () => dispatch(openDiscussion({ messageListId: id }))

  return (
    <PaneHeader>
      <ChannelName>#{name}</ChannelName>
      {/* todo: #accessibility use an aria tab, not a div. */}
      <Tab onClick={onClickChatTab} selected={focusIsOnChat}>
        Chat
      </Tab>
      {/* todo: #accessibility use an aria tab, not a div. */}
      <Tab selected={!focusIsOnChat} onClick={onClickDiscussionTab}>
        Discussions
        {discussionCount > 0 ? <label>{discussionCount}</label> : null}
      </Tab>
    </PaneHeader>
  )
}
