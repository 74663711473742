import * as fbapp from 'firebase/app'
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  endAt,
  FieldPath,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  Query,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  setDoc,
  startAfter,
  where,
  WhereFilterOp,
} from 'firebase/firestore'
export { documentId } from 'firebase/firestore'
export type { DocumentData, QueryDocumentSnapshot, Unsubscribe } from 'firebase/firestore'

const app = fbapp.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSENGER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
})

const firestore = getFirestore(app)

export enum UpdateType {
  Added = 'added',
  Removed = 'removed',
  Modified = 'modified',
}

export type QueryOptions = {
  path: string
  orderBy?: { field: string; desc?: boolean } | { field: string; desc?: boolean }[]
  limit?: number
  startAfter?: unknown
  endAt?: unknown
  where?: { field: string | FieldPath; op: WhereFilterOp; value: unknown }[]
}

function firebaseQuery(options: QueryOptions): Query<DocumentData> {
  const params = [...(options.where || []).map(opt => where(opt.field, opt.op, opt.value))]

  const orderByOpts = options.orderBy && !Array.isArray(options.orderBy) ? [options.orderBy] : options.orderBy

  if (orderByOpts) {
    for (const rule of orderByOpts) {
      params.push(orderBy(rule.field, rule.desc ? 'desc' : 'asc'))
    }
  }

  if (options.limit) {
    params.push(limit(options.limit))
  }

  if (options.startAfter) {
    params.push(startAfter(options.startAfter))
  }

  if (options.endAt) {
    params.push(endAt(options.endAt))
  }

  return query(collection(firestore, options.path), ...params)
}

export const fetch = (options: QueryOptions): Promise<QuerySnapshot<DocumentData>> => getDocs(firebaseQuery(options))

export type Update = {
  id: string
  type: UpdateType
  doc: QueryDocumentSnapshot<DocumentData>
}

export const subscribe = (options: QueryOptions, callback: (error?: Error, updates?: Update[]) => void) =>
  onSnapshot(
    firebaseQuery(options),
    snapshot => {
      callback(
        undefined,
        snapshot.docChanges().map(change => {
          return {
            id: change.doc.id,
            doc: change.doc,
            // casting safe cause these are the only options
            type: change.type as UpdateType,
          }
        })
      )
    },
    error => {
      callback(error)
    }
  )

export const get = async (path: string): Promise<DocumentData | undefined> => {
  const snapshot = await getSnapshot(path)
  if (!snapshot || !snapshot.exists()) {
    return
  }
  return snapshot.data()
}

export const getSnapshot = async (path: string): Promise<DocumentSnapshot<DocumentData> | undefined> => {
  const snapshot = await getDoc(doc(firestore, path))
  if (snapshot.exists()) {
    return snapshot
  }
  return undefined
}

export const set = (path: string, value: Partial<unknown>): Promise<void> =>
  setDoc(doc(firestore, path), value, {
    merge: true,
  })

export const add = (path: string, value: Partial<unknown>): Promise<DocumentReference> =>
  addDoc(collection(firestore, path), value)

export const remove = (path: string): Promise<void> => deleteDoc(doc(firestore, path))
