import * as React from 'react'

type Props = React.SVGProps<SVGSVGElement>
export const ReplyIcon: React.FC<Props> = (props: Props) => (
  <svg width='1em' height='1em' xmlns='http://www.w3.org/2000/svg' viewBox='0 -1 15 15' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M5.413.854a.5.5 0 10-.707-.708l-4.06 4.06a.5.5 0 000 .707l4.06 4.06a.5.5 0 00.707-.707L2.207 5.059h7.196a2.5 2.5 0 012.5 2.5v4.443h1V7.56a3.5 3.5 0 00-3.5-3.5H2.207L5.413.854z'
      // eslint-disable-next-line react/prop-types
      fill={props.fill}
    />
  </svg>
)
