import { Timestamp } from 'components/Timestamp'
import { useMessageListContext } from 'contexts/MessageList'
import { useMessageContext } from 'contexts/Message'
import React from 'react'
import { styled } from 'themes'
import datetime from 'utils/datetime'
import { BaselineElement } from 'themes/typesafe-stitches'

// todo: #accessibility: use a real button, not a div.
const MessageFooterButton = styled('div', {
  position: 'relative',
  display: 'block',
  width: 'calc(100% + $space2)',
  marginTop: '$space2',
  padding: '$space1 $space2',
  marginLeft: 'calc(-1 * $space2)',
  borderRadius: '$m',
  cursor: 'pointer',
  baselineFontSize: ['small'],
  fontWeight: '$semibold',
  variants: {
    color: {
      link: {
        '&:hover': {
          background: '$linkBackground',
        },
        color: '$linkColor',
      },
      reminder: {
        '&:hover': {
          background: '$reminderBackground',
        },
        color: '$reminder',
      },
    },
  },
})

export const MessageFooter: React.FC = () => {
  const { type } = useMessageListContext()
  const {
    reminder,
    pendingReminder,
    message,
    replyCount,
    jumpToItem,
    openInThread,
    openReminders,
    isNextMessageFromSameThread,
  } = useMessageContext()

  if (reminder) {
    return (
      <MessageFooterButton onClick={jumpToItem} color='link'>
        Go to message
      </MessageFooterButton>
    )
  }
  if (pendingReminder?.remindAt) {
    return (
      <MessageFooterButton onClick={openReminders} color='reminder'>
        Remind me: {(<Timestamp epoch={datetime.toEpoch(pendingReminder.remindAt)} />) as BaselineElement}
      </MessageFooterButton>
    )
  }

  if (message?.threadId && !(type === 'thread') && !isNextMessageFromSameThread) {
    return (
      <MessageFooterButton onClick={openInThread} color='link'>
        View Thread
      </MessageFooterButton>
    )
  }

  if (replyCount > 0 && !(type === 'thread')) {
    return (
      <MessageFooterButton onClick={openInThread} color='link'>
        {replyCount === 1 ? `1 Reply` : `${replyCount} Replies`}
      </MessageFooterButton>
    )
  }
  return null
}
