import * as React from 'react'

type Props = React.SVGProps<SVGSVGElement>
export const ArchiveIcon: React.FC<Props> = (props: Props) => (
  <svg width='1em' height='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M6.36 12.684a.5.5 0 1 0-.72.696l.72-.696ZM10 17.162l-.36.348.36.371.36-.37-.36-.349Zm8.36-7.91a.5.5 0 1 0-.72-.696l.72.696Zm-12.72 4.13 4 4.128.72-.695-4-4.13-.72.696Zm4.72 4.128 8-8.258-.72-.696-8 8.259.72.695Z'
      // eslint-disable-next-line react/prop-types
      fill={props.fill}
    />
  </svg>
)
