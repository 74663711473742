import { Timestamp as FirebaseTimestamp } from 'firebase/firestore'

const MIN_SECS = 60
const HOUR_SECS = 3600
const MILLI_NANOSECS = 1000000

export type DateTime = {
  seconds: number
  nanoseconds: number
}

export function toEpoch(d: DateTime): number {
  return d.seconds * 1000 + Math.floor(d.nanoseconds / 1000000)
}

export function toDate(d: DateTime): Date {
  return new Date(toEpoch(d))
}

export function toFirebaseTime(d: DateTime): FirebaseTimestamp {
  return new FirebaseTimestamp(d.seconds, d.nanoseconds)
}

export function fromFirebaseTime(ts: FirebaseTimestamp): DateTime {
  return { seconds: ts.seconds, nanoseconds: ts.nanoseconds }
}

export function fromNativeTime(d: Date): DateTime {
  return {
    seconds: Math.floor(Number(d) / 1000),
    nanoseconds: (Number(d) % 1000) * MILLI_NANOSECS,
  }
}

export function now(): DateTime {
  const fbNow = FirebaseTimestamp.now()
  return { seconds: fbNow.seconds, nanoseconds: fbNow.nanoseconds }
}

export function isBefore(a: DateTime, b: DateTime): boolean {
  if (a.seconds < b.seconds) return true
  if (a.seconds > b.seconds) return false
  return a.nanoseconds < b.nanoseconds
}

export function isAfter(a: DateTime, b: DateTime): boolean {
  if (a.seconds > b.seconds) return true
  if (a.seconds < b.seconds) return false
  return a.nanoseconds > b.nanoseconds
}

export function add(a: DateTime, b: DateTime): DateTime {
  return {
    seconds: a.seconds + b.seconds,
    nanoseconds: 0,
  }
}

export function subtract(a: DateTime, b: DateTime): DateTime {
  return {
    seconds: Math.abs(a.seconds - b.seconds),
    nanoseconds: 0,
  }
}

export function minutes(n: number): DateTime {
  return {
    seconds: n * MIN_SECS,
    nanoseconds: 0,
  }
}

export function hours(n: number): DateTime {
  return {
    seconds: n * HOUR_SECS,
    nanoseconds: 0,
  }
}

export function days(n: number): DateTime {
  return {
    seconds: n * 24 * HOUR_SECS,
    nanoseconds: 0,
  }
}

const datetime = {
  toEpoch,
  toDate,
  toFirebaseTime,
  fromFirebaseTime,
  fromNativeTime,
  now,
  isAfter,
  isBefore,
  add,
  subtract,
  minutes,
  hours,
  days,
}

export default datetime
