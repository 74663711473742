import { globalCss } from '@stitches/react'

export const globalStyles = globalCss({
  '*': {
    '-webkit-box-sizing': 'border-box',
    '-moz-box-sizing': 'border-box',
    'box-sizing': 'border-box',
    outline: 'none',
    fontSmoothing: 'antialiased !important',
    '-webkit-font-smoothing': 'antialiased !important',
    '-moz-font-smoothing': 'antialiased !important',
    '-moz-osx-font-smoothing': 'grayscale',
    textRendering: 'optimizeLegibility',
  },
  'html, body, #root': {
    overflow: 'hidden',
    height: '100vh',
    position: 'relative',
  },
  body: {
    margin: 0,
  },
})
