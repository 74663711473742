import { Toast } from 'components/Toast'
import React, { createContext, useContext, useState } from 'react'
import datetime, { DateTime } from 'utils/datetime'

const TIMEOUT_SECS = 3.5

type ToastItem = {
  body: string
  actionText?: string
  onClickAction?: () => void
  actionHint?: string
  actionShortcut?: string[]
  addedAt: DateTime
  displayedAt: DateTime | undefined
}
type ToastItemOptions = Omit<ToastItem, 'addedAt' | 'displayedAt'>
type ToastContext = {
  currentIndex: number | undefined
  lastItemIndex: number | undefined
  items: ToastItem[]
  show: (options: ToastItemOptions) => void
  hide: () => void
}

const isItemFadingAway = (item: ToastItem): boolean => {
  if (!item.displayedAt) return false

  return datetime.toEpoch(item.displayedAt) > datetime.toEpoch(datetime.now()) - (TIMEOUT_SECS + 0.25) * 1000
}
const initialOptions: ToastContext = {
  currentIndex: undefined,
  lastItemIndex: undefined,
  items: [],
  show: (_: ToastItemOptions) => {
    /* */
  },
  hide: () => undefined,
}
export const context = createContext<ToastContext>(initialOptions)
export const ToastProvider: React.FC = props => {
  // todo: (PB): this is not really usable as a state - should use a ref instead
  const [toastState, setToastState] = useState<ToastContext>({
    ...initialOptions,
    show: insertAndShow,
    hide: () => {
      if (toastState.currentIndex) hide(toastState.currentIndex)
    },
  })

  let currentItem: ToastItem | undefined =
    toastState.currentIndex !== undefined ? toastState.items[toastState.currentIndex] : undefined

  let slideout = false

  if (
    toastState.lastItemIndex !== undefined &&
    !currentItem &&
    isItemFadingAway(toastState.items[toastState.lastItemIndex])
  ) {
    currentItem = toastState.items[toastState.lastItemIndex]
    slideout = true
  }

  function insertAndShow(itemOptions: ToastItemOptions) {
    setToastState(prevState => {
      const nextState = { ...prevState }

      const newItem: ToastItem = {
        ...itemOptions,
        addedAt: datetime.now(),
        displayedAt: undefined,
      }

      nextState.items = [...prevState.items, newItem]

      if (nextState.currentIndex === undefined) {
        showItem(nextState, nextState.items.length - 1)
      }

      return nextState
    })
  }

  function showItem(nextState: ToastContext, itemIndex: number) {
    const item = nextState.items[itemIndex]
    item.displayedAt = datetime.now()

    nextState.lastItemIndex = nextState.currentIndex
    nextState.currentIndex = itemIndex

    setTimeout(hide, TIMEOUT_SECS * 1000, itemIndex)
  }

  function hide(itemIndex: number) {
    setToastState(prevState => {
      const nextState = { ...prevState }

      if (nextState.currentIndex === undefined || nextState.currentIndex !== itemIndex) return nextState

      let nextIndex: number | undefined = nextState.currentIndex !== undefined ? nextState.currentIndex + 1 : undefined

      let nextItem = nextIndex ? nextState.items[nextIndex] : undefined

      while (!!nextItem) {
        if (nextItem.displayedAt === undefined) {
          break
        }

        nextIndex = (nextIndex as number) + 1
        nextItem = nextState.items[nextIndex]
      }

      if (nextItem && nextIndex !== undefined) {
        showItem(nextState, nextIndex)
      } else {
        nextState.lastItemIndex = nextState.currentIndex
        nextState.currentIndex = undefined
      }

      return nextState
    })
  }

  return (
    <context.Provider value={toastState}>
      {props.children}
      {currentItem ? <Toast {...currentItem} close={toastState.hide} slideOut={slideout} /> : null}
    </context.Provider>
  )
}
export const useToast = () => useContext(context)
