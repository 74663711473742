import * as uuid from 'uuid'

/**
 * @module metrics
 * Used for tracking client events and sending them to the backend
 *
 * Events fired within 10 seconds are batched together into a single request (per workspace)
 *
 * Matches the Profiler from "act1" desktop
 */

const localDeviceId = localStorage.getItem('deviceId')
export const deviceId = localDeviceId || uuid.v4()
if (!localDeviceId) {
  localStorage.setItem('deviceId', deviceId)
}

export type Metric = {
  name: string
  clientTimestamp: string // utc
  fields: Record<string, string | number | boolean>

  // not currently used by backend
  now: number
  relativeTimestamp?: number
}

const queues: Record<string, Metric[] | undefined> = {}
const timeouts: Record<string, number | NodeJS.Timeout | undefined> = {}

const FLUSH_INTERVAL = 10 * 1000
const flush = async (workspaceId: string) => {
  const now = performance.now()
  const metrics = queues[workspaceId] || []
  queues[workspaceId] = []
  metrics.forEach(m => {
    m.relativeTimestamp = -Math.round((now - m.now) * 1000000)
  })
  await fetch(`${process.env.REACT_APP_BACKEND_ORIGIN}/~backend/v1/workspaces/${workspaceId}/metrics`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      // these custom headers would match desktop,
      // but aren't currently allowed from backend CORS headers
      // 'x-superhuman-device-id': deviceId,
      // 'x-superhuman-request-id': uuid.v4(),
    },
    body: JSON.stringify({
      clientTimestamp: new Date(),
      metrics,
    }),
  })
}

/**
 * @method measure
 * @param {string} workspaceId id of the workspace the user belongs to
 * @param {string} name the name of the field you want measured
 * @param {Metric['fields']} fields additional data for this event
 *
 * stores a metric in a queue, to be sent after 10 seconds
 * after 10 seconds pass with no additional metrics sent,
 * fires the queued metrics of in a single batch request
 */
export const measure = async (workspaceId: string, name: string, fields: Metric['fields'] = {}): Promise<void> => {
  const now = performance.now()
  queues[workspaceId] = queues[workspaceId] || []
  // safe cause we just defined it ^^
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  queues[workspaceId]!.push({
    name,
    now,
    clientTimestamp: new Date().toUTCString(),
    fields: {
      ...fields,
      environment: process.env.NODE_ENV,
      version: process.env.REACT_APP_VERSION || '',
    },
  })
  clearTimeout(timeouts[workspaceId])
  timeouts[workspaceId] = setTimeout(() => flush(workspaceId), FLUSH_INTERVAL)
}
