import { useDispatch } from 'hooks/use-dispatch'
import { useSelector } from 'hooks/use-selector'
import React from 'react'
import { isLoadingMessages, isMessageListInitialized } from 'selectors/channels'
import { isFocusOnMessageList } from 'selectors/focus'
import {
  getFocusedItem,
  ChatItem,
  getItemsByListId,
  getLastItem,
  getMessageListById,
  getNextItem,
} from 'selectors/messages'
import { PageFocusRegion, setFocusRegion } from 'slices/focus'
import {
  Draft,
  initialMessageListState,
  MessageListState,
  setFocusOnComposeField,
  setFocusOnItem,
} from 'slices/message-lists'
import { MessageListType } from 'services/message-lists'
import { loadMessagesForList, subscribeToChanges } from 'actions/message-lists'
import { MessageListShortcuts } from './shortcuts'

type Props = {
  messageListId: string
  type: MessageListType
}
type MessageListContextValue = MessageListState & {
  id: string
  type: MessageListType
  draft: Draft
  items: ChatItem[]
  totalMessages: number
  loadMore: () => void

  isFocused: boolean
  focusedItem: ChatItem | undefined
  nextMessage: ChatItem | undefined
  prevMessage: ChatItem | undefined
  lastMessage: ChatItem | undefined
  focusMessageComposeField: () => void
  focusMessage: (a: { item: ChatItem | null; editing?: boolean }) => void
  focusNextMessage: () => void
  focusPrevMessage: () => void
  focusLastMessage: () => void
}
const noop = () => {
  /* */
}
const initialValue: MessageListContextValue = {
  ...initialMessageListState('', MessageListType.Channel),
  items: [],
  loadMore: noop,

  isFocused: false,
  focusedItem: undefined,
  nextMessage: undefined,
  prevMessage: undefined,
  lastMessage: undefined,
  focusMessageComposeField: noop,
  focusMessage: noop,
  focusNextMessage: noop,
  focusPrevMessage: noop,
  focusLastMessage: noop,
}
export const context = React.createContext<MessageListContextValue>(initialValue)
export const MessageListContext: React.FC<Props> = ({ messageListId, type, children }) => {
  const dispatch = useDispatch()
  const messageList = useSelector(
    s => getMessageListById(s, messageListId) || initialMessageListState(messageListId, type)
  )
  const items = useSelector(s => getItemsByListId(s, messageListId))

  const focusRegion = type === MessageListType.Channel ? PageFocusRegion.Channel : PageFocusRegion.Thread
  const isFocused = useSelector(s => isFocusOnMessageList(s, focusRegion))
  const focusedItem = useSelector(s => getFocusedItem(s, messageListId))
  const nextMessage = useSelector(s => getNextItem(s, messageListId))
  const prevMessage = useSelector(s => getNextItem(s, messageListId, -1))
  const lastMessage = useSelector(s => getLastItem(s, messageListId))

  const listIsInitialized = useSelector(s => isMessageListInitialized(s, messageListId))
  React.useEffect(() => {
    if (!listIsInitialized) {
      dispatch(loadMessagesForList({ messageListId, type }))
    }
  }, [dispatch, messageListId, type, listIsInitialized])

  React.useEffect(() => {
    if (listIsInitialized) {
      const newestMessageId = items[items.length - 1]?.id
      dispatch(subscribeToChanges({ messageListId, type, newestMessageId }))
    }
    // intentionally incomplete deps array here -
    // only want to start a subscription after first load for this list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, messageListId, type, listIsInitialized])

  const isLoading = useSelector(s => isLoadingMessages(s, messageListId))
  const loadMore = React.useCallback(() => {
    if (listIsInitialized && !isLoading) {
      dispatch(loadMessagesForList({ messageListId, type }))
    }
  }, [listIsInitialized, dispatch, messageListId, type, isLoading])

  const focusMessage = React.useCallback(
    ({ item, editing }: { item: ChatItem | null; editing?: boolean }) => {
      dispatch(setFocusRegion(focusRegion))
      dispatch(setFocusOnItem({ itemId: item?.id, messageListId, editing }))
    },
    [dispatch, focusRegion, messageListId]
  )

  const focusMessageComposeField = React.useCallback(() => {
    dispatch(setFocusRegion(focusRegion))
    dispatch(setFocusOnComposeField({ messageListId }))
  }, [dispatch, focusRegion, messageListId])

  const focusLastMessage = React.useCallback(() => {
    if (lastMessage) {
      focusMessage({ item: lastMessage })
    }
  }, [focusMessage, lastMessage])

  const focusNextMessage = React.useCallback(() => {
    if (lastMessage?.id === focusedItem?.id) {
      focusMessageComposeField()
    } else if (nextMessage) {
      focusMessage({ item: nextMessage })
    }
  }, [focusMessage, focusMessageComposeField, focusedItem?.id, lastMessage?.id, nextMessage])

  const focusPrevMessage = React.useCallback(() => {
    if (prevMessage) {
      focusMessage({ item: prevMessage })
    }
  }, [focusMessage, prevMessage])

  const value = React.useMemo(() => {
    const value: MessageListContextValue = {
      ...messageList,
      items,
      isFocused,
      focusedItem,
      nextMessage,
      prevMessage,
      lastMessage,

      loadMore,
      focusMessageComposeField,
      focusMessage,
      focusNextMessage,
      focusPrevMessage,
      focusLastMessage,
    }
    return value
  }, [
    focusLastMessage,
    focusMessage,
    focusMessageComposeField,
    focusNextMessage,
    focusPrevMessage,
    focusedItem,
    isFocused,
    items,
    lastMessage,
    loadMore,
    messageList,
    nextMessage,
    prevMessage,
  ])

  return (
    <context.Provider value={value}>
      {isFocused && <MessageListShortcuts />}
      {children}
    </context.Provider>
  )
}
export const useMessageListContext = () => React.useContext(context)
