import { RootState } from 'root-state'
import { Message } from 'services/messages'
import { Draft, LoadingStatus, MessageListState } from 'slices/message-lists'
import datetime from 'utils/datetime'

// todo: make this return a channel, not just an id
export const getSelectedChannelId = (state: RootState): string => {
  return state.focus.channel?.messageListId || Object.keys(state.focus.channel)[0]
}

export const getAllChannels = (state: RootState): MessageListState[] => {
  return Object.values(state.messageLists).filter(a => a.type === 'channel')
}

export const getChannelNameForMessageList = (state: RootState, messageListId: string): string | undefined => {
  const messageList = state.messageLists[messageListId]
  if (messageList) {
    const channelId = messageList.messages.find(m => !!m.channelId)?.channelId
    return state.messageLists[channelId || '']?.name || channelId
  }
}

export const isLoadingMessages = (state: RootState, messageListId: string) => {
  return state.messageLists[messageListId]?.loading === LoadingStatus.Pending
}

export const getDraftByChannelId = (state: RootState, messageListId?: string): Draft => {
  return (
    state.messageLists[messageListId || '']?.draft || {
      body: '',
      updatedAt: datetime.now(),
      messageListId,
    }
  )
}

export const isMessageListInitialized = (state: RootState, messageListId: string): boolean => {
  return (
    (state.messageLists[messageListId]?.loading && state.messageLists[messageListId].loading !== LoadingStatus.Idle) ||
    false
  )
}

export const isMessageShownInChannelSidebar = (state: RootState, message?: Message | null): boolean =>
  (message?.threadId || message?.id) === state.focus.sidebar.messageListId
