import { keyframes } from '@stitches/react'
import { Icon } from 'components/Icons'
import { ToastActionButton } from 'components/ToastActionButton'
import React from 'react'
import { styled } from 'themes'

const offscreen = -30
const onScreen = 8
const slideIn = keyframes({
  from: { top: offscreen, opacity: 0 },
  to: { top: onScreen, opacity: 1 },
})

const slideOut = keyframes({
  from: { top: onScreen, opacity: 1 },
  to: { top: offscreen, opacity: 0 },
})

const Wrapper = styled('div', {
  position: 'fixed',
  top: '$space1',
  right: '$space16',
  height: '$space9',
  borderRadius: '$m',
  background: '$primaryBackground',
  boxShadow: '$strong',
  fontSize: '$small',
  fontWeight: '$normal',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$space5',
  zIndex: '$window',
  padding: '0 $space4',
  borderLeftStyle: 'solid',
  borderLeftColor: '$linkColor',
  borderLeftWidth: '$thick',
  animation: `${slideIn} 0.2s forwards;`,
  variants: {
    slideOut: {
      true: {
        animation: `${slideOut} 0.2s forwards`,
      },
    },
  },
  '& div': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  '& div [data-hint]': {
    top: '$space8',
    transform: 'translate(0, 0)',
  },
})

const Content = styled('div', {
  color: '$primaryText',
})

const CloseButton = styled('div', {
  cursor: 'pointer',
  fill: '$darkGrey',
})

type Props = {
  body: string
  actionText?: string
  onClickAction?: () => void
  actionHint?: string
  actionShortcut?: string[]
  close: () => void
  slideOut?: boolean
}

export const Toast: React.FC<Props> = (props: Props) => {
  return (
    <Wrapper slideOut={props.slideOut}>
      <Content>{props.body}</Content>
      {props.actionText && props.onClickAction ? (
        <ToastActionButton
          hint={props.actionHint}
          shortcut={props.actionShortcut}
          // todo: #accessibility: use a real button, not a div.
          onClick={props.onClickAction}
        >
          {props.actionText}
        </ToastActionButton>
      ) : null}
      <CloseButton onClick={props.close}>
        <Icon name='close' />
      </CloseButton>
    </Wrapper>
  )
}
