import * as React from 'react'

type Props = React.SVGProps<SVGSVGElement>
export const EmojiIcon: React.FC<Props> = (props: Props) => (
  <svg width='1em' height='1em' fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8 15.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15zM14.5 8a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0zM6.25 7a.75.75 0 100-1.5.75.75 0 000 1.5zm4.25-.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM6 9.5a.5.5 0 00-1 0c0 .864.29 1.623.836 2.167.547.544 1.31.833 2.179.833.857 0 1.612-.29 2.154-.835.54-.543.83-1.301.83-2.165a.5.5 0 00-1 0c0 .636-.21 1.128-.54 1.46-.329.33-.817.54-1.444.54-.645 0-1.14-.21-1.473-.542C6.21 10.628 6 10.136 6 9.5z'
      // eslint-disable-next-line react/prop-types
      fill={props.fill}
    />
  </svg>
)
