import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export const name = 'discussion-list'

type State = {
  messagesByThread: Record<string, string[]>
  threadsByChannel: Record<string, string[]>
  totalThreadsByChannel: Record<string, number>
}

export const initialState: State = {
  messagesByThread: {},
  threadsByChannel: {},
  totalThreadsByChannel: {},
}

export const slice = createSlice({
  name,
  initialState,
  reducers: {
    appendThreadsByChannel: (state, action: PayloadAction<{ channelId: string; threadIds: string[] }>) => {
      const existing = state.threadsByChannel[action.payload.channelId] || []
      for (const id of action.payload.threadIds) {
        if (!existing.includes(id)) {
          existing.push(id)
        }
      }

      state.threadsByChannel[action.payload.channelId] = existing.slice()
    },
    setThreadMessageList: (state, action: PayloadAction<Record<string, string[]>>) => {
      state.messagesByThread = {
        ...state.messagesByThread,
        ...action.payload,
      }
    },
    setTotalThreadCountByChannel: (state, action: PayloadAction<{ channelId: string; total: number }>) => {
      state.totalThreadsByChannel[action.payload.channelId] = action.payload.total
    },
  },
})

export const { appendThreadsByChannel, setTotalThreadCountByChannel, setThreadMessageList } = slice.actions
export default slice.reducer
