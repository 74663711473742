import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import uniqBy from 'lodash/uniqBy'
import { createTransform } from 'reduxjs-toolkit-persist'
import { MessageList, MessageListType } from 'services/message-lists'
import { Message } from 'services/messages'
import datetime, { DateTime } from 'utils/datetime'

// todo: make userid part of a draft
// so we don't have to pass the current user around
export type Draft = {
  body: string
  messageListId: string
  updatedAt: DateTime
  remindAt?: DateTime
}

export enum LoadingStatus {
  Idle = 'idle',
  Pending = 'pending',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export const sortMessagesByDate = (a: Message, b: Message): number => {
  if (datetime.isBefore(a.sentAt, b.sentAt)) return -1
  if (datetime.isAfter(a.sentAt, b.sentAt)) return 1
  return 0
}
const emptyDraft = (messageListId: string): Draft => ({
  messageListId,
  body: '',
  updatedAt: datetime.now(),
})
export enum ChannelTab {
  Chat = 'chat',
  Discussion = 'discussion',
}
export type MessageListFocus = {
  tab: ChannelTab
  itemId?: string
  editing?: boolean
  composeField?: boolean
}
export type MessageListState = {
  id: string
  name?: string
  draft: Draft
  messages: Message[]
  totalMessages: number
  loading: LoadingStatus
  type: MessageListType

  focus: MessageListFocus
  priorFocus: MessageListFocus
}
export const initialMessageListState = (id: string, type: MessageListType, name?: string): MessageListState => ({
  id: id,
  name: name || id,
  messages: [],
  totalMessages: 0,
  draft: emptyDraft(id),
  loading: LoadingStatus.Idle,
  type,
  focus: {
    tab: ChannelTab.Chat,
    composeField: true,
  },
  priorFocus: {
    tab: ChannelTab.Chat,
    composeField: true,
  },
})
const saveListStateForLater = (state: MessageListsState, listId: string) => {
  const priorFocus: MessageListFocus = state[listId].priorFocus || {}
  priorFocus.tab = state[listId].focus.tab
  priorFocus.composeField = state[listId].focus.composeField
  priorFocus.itemId = state[listId].focus.itemId
  priorFocus.editing = state[listId].focus.editing
  state[listId].priorFocus = priorFocus
}

type MessageListsState = Record<string, MessageListState>
export const initialState: MessageListsState = {}
export const slice = createSlice({
  name: 'messageLists',
  initialState,
  reducers: {
    initializeMessageLists: (state, { payload: { channels = [] } }: PayloadAction<{ channels: MessageList[] }>) => {
      channels.forEach(c => {
        state[c.id] = state[c.id] || initialMessageListState(c.id, MessageListType.Channel, c.name)
      })
    },
    setLoadingStatus: (
      state,
      {
        payload: { messageListId, status, type },
      }: PayloadAction<{
        messageListId: string
        status: LoadingStatus
        type: MessageListType
      }>
    ) => {
      state[messageListId] = state[messageListId] || initialMessageListState(messageListId, type)
      state[messageListId].loading = status
    },
    cleanDraft: (state, { payload: { messageListId } }: PayloadAction<{ messageListId: string }>) => {
      state[messageListId].draft = emptyDraft(messageListId)
    },
    setTotalMessages: (
      state,
      { payload: { totalMessages, messageListId } }: PayloadAction<{ messageListId: string; totalMessages?: number }>
    ) => {
      if (totalMessages) {
        state[messageListId].totalMessages = totalMessages
      }
    },
    prependMessagesToList: (
      state,
      {
        payload: { messageListId, messages, type },
      }: PayloadAction<{
        messageListId: string
        messages: Message[]
        type: MessageListType
      }>
    ) => {
      state[messageListId] = state[messageListId] || initialMessageListState(messageListId, type)
      const oldLength = state[messageListId].messages.length
      state[messageListId].messages = uniqBy(messages.concat(state[messageListId].messages), 'id').sort(
        sortMessagesByDate
      )
      const newLength = state[messageListId].messages.length
      if (newLength > 1) {
        state[messageListId].totalMessages += newLength - oldLength
      }
    },
    appendMessagesToList: (
      state,
      { payload: { messageListId, messages } }: PayloadAction<{ messageListId: string; messages: Message[] }>
    ) => {
      const oldLength = state[messageListId].messages.length
      state[messageListId].messages = uniqBy(state[messageListId].messages.concat(messages), 'id').sort(
        sortMessagesByDate
      )
      const newLength = state[messageListId].messages.length
      if (newLength > 1) {
        state[messageListId].totalMessages += newLength - oldLength
      }
    },
    removeMessagesFromList: (
      state,
      { payload: { messageListId, messages } }: PayloadAction<{ messageListId: string; messages: Message[] }>
    ) => {
      const oldLength = state[messageListId].messages.length
      state[messageListId].messages = uniqBy(
        state[messageListId].messages.filter(m => !messages.find(n => n.id === m.id)),
        'id'
      ).sort(sortMessagesByDate)
      const newLength = state[messageListId].messages.length
      state[messageListId].totalMessages += newLength - oldLength
    },
    updateDraft: (
      state,
      { payload: { messageListId, body } }: PayloadAction<{ messageListId: string; body: string }>
    ) => {
      state[messageListId].draft.body = body
      state[messageListId].draft.updatedAt = datetime.now()
    },
    updateMessage: (
      state,
      {
        payload: { messageListId, messageId, updates },
      }: PayloadAction<{ messageId: string; messageListId: string; updates: Partial<Message> }>
    ) => {
      const message = state[messageListId].messages.find(m => m.id === messageId)
      Object.assign(message || {}, updates)
    },
    createReminderForDraft: (
      state,
      { payload: { messageListId, remindAt } }: PayloadAction<{ messageListId: string; remindAt: DateTime }>
    ) => {
      state[messageListId].draft.remindAt = remindAt
    },
    cleanupDraftReminder: (state, { payload: { messageListId } }: PayloadAction<{ messageListId: string }>) => {
      state[messageListId].draft.remindAt = undefined
    },

    openChat: (
      state,
      { payload: { messageListId, type } }: PayloadAction<{ messageListId: string; type: MessageListType }>
    ) => {
      state[messageListId] = state[messageListId] || initialMessageListState(messageListId, type)
      state[messageListId].focus.tab = ChannelTab.Chat
      saveListStateForLater(state, messageListId || '')
    },
    openDiscussion: (state, { payload: { messageListId } }: PayloadAction<{ messageListId: string }>) => {
      state[messageListId].focus.tab = ChannelTab.Discussion
      saveListStateForLater(state, messageListId || '')
    },
    toggleChannelTab: (state, { payload: { messageListId } }: PayloadAction<{ messageListId: string }>) => {
      const currentTab = state[messageListId].focus.tab
      const nextTab = currentTab === ChannelTab.Chat ? ChannelTab.Discussion : ChannelTab.Chat
      state[messageListId].focus.tab = nextTab
      saveListStateForLater(state, messageListId || '')
    },
    setFocusOnItem: (
      state,
      {
        payload: { itemId, editing, messageListId },
      }: PayloadAction<{ itemId?: string; messageListId: string; editing?: boolean }>
    ) => {
      if (!itemId) {
        return
      }
      state[messageListId].focus.tab = ChannelTab.Chat
      state[messageListId].focus.composeField = false
      state[messageListId].focus.itemId = itemId
      state[messageListId].focus.editing = editing || false
      saveListStateForLater(state, messageListId || '')
    },
    setFocusOnComposeField: (state, { payload: { messageListId } }: PayloadAction<{ messageListId: string }>) => {
      state[messageListId].focus.composeField = true
      state[messageListId].focus.editing = false
      state[messageListId].focus.itemId = undefined
      saveListStateForLater(state, messageListId)
    },
  },
})

export const {
  initializeMessageLists,
  setLoadingStatus,
  cleanDraft,
  updateDraft,
  updateMessage,
  setTotalMessages,
  prependMessagesToList,
  appendMessagesToList,
  removeMessagesFromList,
  createReminderForDraft,
  cleanupDraftReminder,
  openChat,
  openDiscussion,
  setFocusOnComposeField,
  setFocusOnItem,
  toggleChannelTab,
} = slice.actions
export default slice.reducer

export const resetChatLoadingState = createTransform(
  (state: MessageListsState, key: string) => {
    Object.keys(state).forEach(channelName => {
      state[channelName].loading = LoadingStatus.Idle
    })
    return state
  },
  (state: MessageListsState, key: string) => state,
  { whitelist: ['chat'] }
)
