import { Placeholder } from 'components/Placeholder'
import { Timestamp } from 'components/Timestamp'
import { useSelector } from 'hooks/use-selector'
import { getLastUpdatedAtByThreadId, getThreadGistById, getUsersInDiscussion } from 'selectors/discussion-list'
import { getDiscussionMessageById } from 'selectors/discussion-messages'
import { User } from 'services/users'
import { styled } from 'themes'
import datetime from 'utils/datetime'

const Wrapper = styled('div', {
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: '28% auto 17%',
  padding: '$space3 $space7 $space3 $space10',
  alignItems: 'center',
  fontSize: '$small',
  fontWeight: '$normal',
  borderLeftWidth: '$thick',
  borderLeftStyle: 'solid',
  borderLeftColor: 'transparent',
  variants: {
    selected: {
      true: {
        background: '$focusOrActiveBackground',
        borderLeftColor: '$focusOrActive',
      },
    },
  },
  '& *': {
    fontSize: 'inherit',
    lineHeight: 'inherit',
  },
})

const Users = styled('header', {
  fontSize: '$small',
  fontWeight: '$semibold',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

const Subject = styled('div', {
  fontWeight: '$normal',
  color: '$primaryText',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
})

const Date = styled('div', {
  color: '$primaryAccentText',
  textAlign: 'right',
  textTransform: 'uppercase',
})

const Gist = styled('div', {
  display: 'inline',
  color: '$primaryAccentText',
  marginLeft: '$space4',
})

const Border = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  borderWidth: '$thick',
  height: '100%',
  background: 'transparent',
  variants: {
    active: {
      true: {
        background: '$focusOrActive',
      },
    },
  },
})

// todo: (PB): remove this; rely on the first/last name from the provider oidc
const naiveFirstName = (user?: User) => user?.name?.split(/\s/).filter(p => p.length >= 2)[0]

type Props = {
  threadId: string
  isSelected?: boolean
}

export const DiscussionRow: React.FC<Props> = ({ threadId, isSelected }: Props) => {
  const message = useSelector(s => getDiscussionMessageById(s, threadId))
  const users = useSelector(s => getUsersInDiscussion(s, threadId))
  const gist = useSelector(s => getThreadGistById(s, threadId))
  const lastUpdatedAt = useSelector(s => getLastUpdatedAtByThreadId(s, threadId))

  // todo: (PB): make a component for this
  function separatorChar(ind: number): string | undefined {
    if (users.length === 1 || ind === users.length - 1) return undefined

    if (ind === users.length - 2) {
      return ' & '
    }

    return ', '
  }

  return (
    <Wrapper data-discussion-id={threadId} selected={isSelected}>
      <Users>
        {users.map((user, ind) => {
          // if there's exactly one user, show their full name
          // otherwise show the first name only
          const name = users.length === 1 ? user?.name : naiveFirstName(user)
          return (
            <>
              {name ?? <Placeholder width={0.75} height={0.2} />}
              {separatorChar(ind)}
            </>
          )
        })}
      </Users>
      <Subject>
        {message?.subject ?? <Placeholder width={2} height={0.2} />}
        <Gist>{gist ?? <Placeholder width={2} height={0.2} />}</Gist>
      </Subject>
      <Date>
        {lastUpdatedAt ? (
          <Timestamp epoch={datetime.toEpoch(lastUpdatedAt)} minimalist />
        ) : (
          <Placeholder width={0.75} height={0.2} />
        )}
      </Date>
      <Border active={isSelected} />
    </Wrapper>
  )
}
