import { Hint } from 'components/Hint'
import { Icon } from 'components/Icons'
import { useMessageListContext } from 'contexts/MessageList'
import { useMessageContext } from 'contexts/Message'
import React from 'react'
import { styled } from 'themes'
import { useSelector } from 'hooks/use-selector'
import { isMessageShownInChannelSidebar } from 'selectors/channels'

const Wrapper = styled('div', {
  position: 'absolute',
  fontSize: '$base',
  bottom: 'calc(100% - $space5)',
  right: '$space5',
  display: 'flex',
  flexDirection: 'row',
  gap: '$space2',
  zIndex: '$aboveBase',
  borderStyle: 'solid',
  borderWidth: '$thin',
  borderColor: '$borderColor',
  borderRadius: '$xxxl',
  padding: '$space1',
  background: '$primaryBackground',
  boxShadow: '$light',
  '& div [data-hint]': {
    top: 'calc(-$space3)',
  },
})
const Button = styled('div', {
  height: '$space6',
  width: '$space6',
  padding: '$space1',
  borderRadius: '$full',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& svg path': {
    fill: '$primaryAccentText',
  },
  '&:hover': {
    background: '$primaryAccentBackground',
  },

  variants: {
    active: {
      true: {
        '& svg path': {
          fill: '$reminder',
        },
      },
    },
  },
})
const noop = () => {
  /* */
}

export const MessageButtons: React.FC = () => {
  const { type } = useMessageListContext()
  const { message, reminder, openInThread, openReminders, archiveReminder, openCommandMenu } = useMessageContext()
  const isShownInThread = useSelector(s => isMessageShownInChannelSidebar(s, message))

  return (
    <Wrapper>
      {reminder && (
        <Button onClick={archiveReminder}>
          <Icon name='archive' />
        </Button>
      )}
      <Button onClick={noop}>
        <Icon name='emoji' />
      </Button>
      {type === 'channel' && (
        <Hint text='Reply' shortcut={['enter']}>
          <Button onClick={openInThread} active={isShownInThread}>
            <Icon name='reply' />
          </Button>
        </Hint>
      )}
      {reminder && (
        <Hint text='Remind' shortcut={['H']}>
          <Button onClick={openReminders} active={!!reminder}>
            <Icon name='remind' />
          </Button>
        </Hint>
      )}
      <Button onClick={openCommandMenu}>
        <Icon name='command' />
      </Button>
    </Wrapper>
  )
}
