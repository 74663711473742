import { MessageBody, MessageInner, MessageProfilePhoto, MessageTitle, MessageWrapper } from 'components/Message/atoms'
import { Placeholder } from 'components/Placeholder'
import React from 'react'
import { BaselineElement } from 'themes/typesafe-stitches'

export const MessagePlaceholder: React.FC = () => {
  const rows = React.useMemo(
    () => [
      Number((4 + Math.random() * 3).toFixed(2)),
      Number((4 + Math.random() * 3).toFixed(2)),
      Number((4 + Math.random() * 3).toFixed(2)),
    ],
    []
  )
  return (
    <MessageWrapper>
      <MessageInner>
        <MessageProfilePhoto src='placeholder' referrerPolicy='no-referrer' />
        <div>
          <MessageTitle>
            <Placeholder width={2.5} />
            <span>&nbsp;&nbsp;</span>
            <Placeholder width={1} />
          </MessageTitle>
          <MessageBody>{rows.map((w, i) => (<Placeholder key={i} width={w} block />) as BaselineElement)}</MessageBody>
        </div>
      </MessageInner>
    </MessageWrapper>
  )
}
