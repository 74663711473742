import * as React from 'react'

type Props = React.SVGProps<SVGSVGElement>
export const SearchIcon: React.FC<Props> = (props: Props) => (
  <svg width='1em' height='1em' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.228 9.457a5.098 5.098 0 0 0 1.496-3.618c0-2.778-2.212-5.065-4.983-5.065C2.971.774.76 3.061.76 5.84c0 2.777 2.211 5.064 4.982 5.064 1.002 0 1.931-.299 2.709-.812l2.82 2.879a.488.488 0 0 0 .7 0 .513.513 0 0 0 0-.714l-2.742-2.8Zm.496-3.618c0 2.244-1.783 4.064-3.983 4.064S1.76 8.083 1.76 5.84c0-2.245 1.783-4.065 3.982-4.065 2.2 0 3.983 1.82 3.983 4.065Z'
      fill='inherit'
    />
  </svg>
)
