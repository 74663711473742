import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DiscussionMessage } from 'services/messages'

type MessagesState = Record<string, DiscussionMessage>

export const initialState: MessagesState = {}

export const slice = createSlice({
  name: 'discussionMessages',
  initialState,
  reducers: {
    setDiscussionMessage: (state, { payload: message }: PayloadAction<DiscussionMessage>) => {
      state[message.id] = message
    },
    setDiscussionMessages: (state, { payload: messages }: PayloadAction<DiscussionMessage[]>) => {
      messages.forEach(m => {
        state[m.id] = m
      })
    },
    removeDiscussionMessage: (state, { payload: message }: PayloadAction<DiscussionMessage>) => {
      delete state[message.id]
    },
    removeDiscussionMessages: (state, { payload: messages }: PayloadAction<DiscussionMessage[]>) => {
      messages.forEach(m => {
        delete state[m.id]
      })
    },
  },
})

export const { setDiscussionMessage, setDiscussionMessages, removeDiscussionMessage, removeDiscussionMessages } =
  slice.actions
export default slice.reducer
