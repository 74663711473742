import { RootState } from 'root-state'
import { Draft } from 'slices/message-lists'
import { Reminder } from 'services/reminders'
import datetime from 'utils/datetime'
import { wordsToRegex } from 'utils/regex'
import { MessageListType } from 'services/message-lists'

export const getReminderById = (state: RootState, id = ''): Reminder | undefined => state.reminders[id]

const getActiveReminders = (state: RootState): Reminder[] => Object.values(state.reminders).filter(r => !r.archivedAt)

export const getPendingRemindersByMessageId = (state: RootState, messageId?: string): Reminder | undefined => {
  if (!messageId) {
    return
  }
  return getActiveReminders(state)
    .filter(r => r.chatMessageId === messageId)
    .filter(r => datetime.isAfter(r.remindAt, datetime.now()))
    .sort((a, b) => a.remindAt.seconds - b.remindAt.seconds)[0]
}

/**
 * backwards compatibility:
 * previously, reminders only worked on channels, not threads or discussions
 * as such they only had a message id, not a message list id
 * for backwards compatibility, when possible, look up the matching message
 * this can be dropped in very short order
 */
const backwardsCompatibilityReminderLookup = (
  state: RootState,
  original: Reminder,
  messageListId: string
): Reminder => {
  const clone = { ...original }
  if (!clone.messageListId) {
    const messageList = state.messageLists[messageListId]
    const message = messageList?.messages.find(m => m.id === clone.chatMessageId)
    if (message) {
      clone.messageListId = message.channelId
      clone.messageListType = MessageListType.Channel
    }
    return clone
  }
  return original
}

export const getRemindersByMessageListId = (state: RootState, messageListId: string): Reminder[] =>
  getActiveReminders(state)
    .map(r => backwardsCompatibilityReminderLookup(state, r, messageListId))
    .filter(r => r.messageListId === messageListId)

export const getReturnedRemindersByMessageListId = (state: RootState, messageListId: string): Reminder[] =>
  getRemindersByMessageListId(state, messageListId).filter(r => datetime.isBefore(r.remindAt, datetime.now()))

const RE_REMINDER_SUGGESTION: RegExp[] = [
  'lmk',
  'let me know',
  'will check',
  'later',
  'next week',
  'eod',
  'end of week',
  'in an hour',
  'tomorrow',
  'will get back',
].map(wordsToRegex)

export const shouldSuggestCreatingReminder = (draft: Draft): boolean => {
  if (draft.body.trim() === '' && draft.remindAt) return false

  const words = draft.body.toLowerCase()
  return RE_REMINDER_SUGGESTION.some(pattern => words.match(pattern))
}
