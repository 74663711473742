import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from 'root-state'
import { getOrganizationId } from 'selectors/session'
import { User, fetchUsers } from 'services/users'

export const initialState: Record<string, User> = {}

export const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, { payload: users }: PayloadAction<User[]>) => {
      users.forEach(user => {
        state[user.id] = user
      })
    },
  },
})

export const { setUsers } = slice.actions
export default slice.reducer

export const syncUserProfiles = function (userIds: string[]) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const orgId = getOrganizationId(state)
    const missingIds = userIds.filter(id => id && !state.users[id])
    const users = await fetchUsers({ orgId, userIds: missingIds })
    dispatch(setUsers(users))
  }
}
