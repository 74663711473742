import { updateMessageBody } from 'actions/messages'
import { ChatCompose } from 'components/ChatCompose'
import { useMessageContext } from 'contexts/Message'
import { useMessageListContext } from 'contexts/MessageList'
import { useDispatch } from 'hooks/use-dispatch'
import { useFocus } from 'hooks/use-focus'
import React, { useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { setFocusOnItem } from 'slices/message-lists'

export const MessageEditField: React.FC = () => {
  const dispatch = useDispatch()
  const { id: messageListId, focusNextMessage, focusPrevMessage, focusMessageComposeField } = useMessageListContext()
  const { message } = useMessageContext()

  const [draft, setDraft] = useState(message?.body || '')
  const [inputRef, setInputFocused] = useFocus<HTMLTextAreaElement>()

  const onEnter = React.useCallback(() => {
    dispatch(setFocusOnItem({ messageListId, editing: false }))
    if (!message || message.body === draft || draft === '') {
      return
    }
    dispatch(
      updateMessageBody({
        message,
        messageListId,
        body: draft,
      })
    )
  }, [dispatch, messageListId, message, draft])
  useHotkeys('enter', onEnter, { enableOnTags: ['TEXTAREA'], keydown: true })

  const onDown = React.useCallback(() => {
    if (inputRef.current?.selectionStart === inputRef.current?.textContent?.length) {
      focusNextMessage()
    }
  }, [focusNextMessage, inputRef])
  useHotkeys('down', onDown, { enableOnTags: ['TEXTAREA'], keydown: true })

  const onUp = React.useCallback(() => {
    if (inputRef.current?.selectionStart === 0) {
      focusPrevMessage()
    }
  }, [focusPrevMessage, inputRef])
  useHotkeys('up', onUp, { enableOnTags: ['TEXTAREA'], keydown: true })

  const onEscape = React.useCallback(() => focusMessageComposeField(), [focusMessageComposeField])
  useHotkeys('esc', onEscape, { enableOnTags: ['TEXTAREA'], keydown: true })

  useEffect(() => {
    setInputFocused()
  }, [setInputFocused])

  return (
    <ChatCompose
      inputRef={inputRef}
      focused={true}
      body={draft}
      placeholder=''
      onInput={(value: string) => setDraft(value)}
    />
  )
}
